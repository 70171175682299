import React from "react";
import Loading from "../../../../components/loading/LoadingComponent";
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";
import NewIOT from "../../../../IOT/NewIOT";
import SensorWorkType from './settings/SensorWorkType';
import ScheduleAnalog from "./settings/ScheduleAnalog";

export class ManageChainFeedingConfiguration extends React.Component {

    state = {
        selectedDevices: this.props.location.state ? this.props.location.state.selectedDevices : [],
        loading: true
    };

    constructor(props) {
        super(props);
        if (this.state.selectedDevices.length === 0) {
            this.props.history.push(`/${this.props.farm}/settings/chainFeeding/manage`)
        }
        NewIOT.startSendingDeviceState(this.state.selectedDevices, this.setLoading, this.setLoading);
    }

    setLoading = () => {
        this.setState({
            loading: false
        })
    };

    render() {
        const {selectedDevices, loading} = this.state;
        if (selectedDevices.length === 0) return null;
        return (
            <div className={"overflow-hidden"} style={{position: "relative"}}>
                <Loading isLoading={loading}/>
                <Row>
                    <Col xl={6}>
                        <SensorWorkType devices={selectedDevices}/>
                    </Col>
                    <Col xl={6}>
                        <ScheduleAnalog devices={selectedDevices}/>
                    </Col>
                </Row>
            </div>
        );
    }

}

ManageChainFeedingConfiguration = connect(state => ({
    farm: state.location.farm
}))(ManageChainFeedingConfiguration);

export default ManageChainFeedingConfiguration;