import React from "react";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../components/basics/button/Button";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Card from "../../../../../components/basics/card/Card";
import List from "../../../../../components/basics/list/List";
import ClimateCurveItem from "./ClimateCurveItem";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import withRoles from "../../../../../components/withRoles";
import {Roles} from "../../../../../constans/roles";
import {withTranslation} from "react-i18next";
import {getClimateCurves} from "../../../../../selectors/device-rows/climateSelector";

export class ClimateCurvesView extends React.Component {

    onAddClick = () => {
        this.props.history.push(`/${this.props.farm}/settings/climate-sk3/curves/add`)
    };

    render() {
        const {climateCurves} = this.props;
        return (
            <Card type={"container"}>
                <List useFlexLayout={false}>
                    {
                        climateCurves.map((curve, index) =>
                            <ClimateCurveItem curve={curve} key={index}/>
                        )
                    }
                    {
                        climateCurves.length === 0 &&
                        <InfoBox boxColor="error">
                            {this.props.t("newSettings.climate.curve.noCurves")}
                        </InfoBox>
                    }
                </List>
                <ButtonGroup fixed renderInPortal={false}>
                    <Button buttonColor={"success"} buttonStyle={"round"} icon={<i className="fas fa-plus"/>}
                            onClick={this.onAddClick}/>
                </ButtonGroup>
            </Card>
        );
    }

}

export let _ClimateCurvesView = connect(state => ({
    climateCurves: getClimateCurves(state),
    farm: state.location.farm
}))(ClimateCurvesView);
ClimateCurvesView = withRouter(_ClimateCurvesView);
ClimateCurvesView = withRoles({roles: [Roles._DEVICE_CLIMATE, Roles._DEVICE_CONFIG], showComponent: true})(ClimateCurvesView);
ClimateCurvesView = withTranslation()(ClimateCurvesView);
export default ClimateCurvesView;
