import {get, isNil, set} from "lodash";
import {convertPressureUnitTo, convertTemperatureUnitTo, convertVoltageUnitTo} from "../../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../../constans/unitTypes";

export const initializeForm = ({initialize, shadow, model}) => {
    const dataShadow = (item) => get(shadow, item.shadowKey, isNil(item.defaultValue) ? item.min : item.defaultValue)
    const data = {};
    model.forEach(d => {
            set(data, d.key, dataShadow(d));
        }
    )
    initialize(data);
}

export const temperatureFormatter = (value) => convertTemperatureUnitTo(value, {
    showUnit: true,
    unit: UnitTypes.SMALL,
    fixed: 1
})

export const secondsFormatter = (value) => `${((value || 0) / 1000).toFixed(1)}s`

export const minutesFormatter = (value) => `${((value || 0) / 60000).toFixed(1)}min`

export const percentageFormatter = (value) => `${value}%`;

export const pressureFormatter = (value) => convertPressureUnitTo(value, {showUnit: true, fixed: 2})

export const voltageFormatter = (value) => convertVoltageUnitTo(value, {
    showUnit: true,
    fixed: 1,
    unit: UnitTypes.MEDIUM
})
