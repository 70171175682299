import React from "react";
import {connect} from "react-redux";
import NewIOT from "../../../../IOT/NewIOT";
import {get} from "lodash";
import AlarmsMenu from "./settings/AlarmsMenu";
import HeaterMenu from "./settings/HeaterMenu";
import CoolerMenu from "./settings/CoolerMenu";
import VentilationMenu from "./settings/VentilationMenu";
import FlapsMenu from "./settings/FlapsMenu";
import ThrottleMenu from "./settings/ThrottleMenu";
import SensorsMenu from "./settings/SensorsMenu";
import MatsMenu from "./settings/MatsMenu";

export class ManageClimatesConfiguration extends React.Component {

    constructor(props) {
        super(props);
        const {farm} = this.props;
        if (this.getClimates().length === 0) {
            this.props.history.push(`/${farm}/settings/climate-sk3/manage`)
        }
        NewIOT.startSendingDeviceState(this.getClimates());
    }

    getClimates = () => {
        const {location} = this.props;
        return get(location, "state.selectedClimates", []);
    };

    render() {
        const climates = this.getClimates();
        if (climates.length === 0) return null;
        return (
            <div style={{position: "relative"}}>
                <AlarmsMenu climates={climates}/>
                <HeaterMenu climates={climates}/>
                <CoolerMenu climates={climates}/>
                <VentilationMenu climates={climates}/>
                <FlapsMenu climates={climates}/>
                <ThrottleMenu climates={climates}/>
                <SensorsMenu climates={climates}/>
                <MatsMenu climates={climates}/>
            </div>
        );
    }

}

ManageClimatesConfiguration = connect(state => ({
    farm: state.location.farm
}))(ManageClimatesConfiguration);

export default ManageClimatesConfiguration;
