import React, {Component} from 'react';
import {connect} from 'react-redux';
import {formValueSelector, reduxForm} from "redux-form";
import {withTranslation} from "react-i18next";
import {getBuildingsMap} from "../../../../selectors/buildingsSelector";
import {Level} from "../../../../constans/levelTypes";
import {isFinite} from "lodash";
import {
    setFeedingEfficiency,
    setFeedingEfficiencyNotificationFailure,
    setFeedingEfficiencyNotificationSuccess
} from "../../../../api/devices/efficiency";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../components/basics/button/Button";
import {Col, Row} from "react-bootstrap";
import {getLocationNameByBuildingMapSelector} from "../../../../utils/BuildingUtils";
import Card from "../../../../components/basics/card/Card";
import {withRouter} from "react-router-dom";

const ModalName = 'settings-feeding-calibration-destination';

const selector = formValueSelector(ModalName);

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        devices: state.farmDevices.devices,
        type: selector(state, "type"),
        actions: selector(state, "actions"),
        buildingsMap: getBuildingsMap(state),
        siloses: [] //todo: dodac state.siloses.siloses jak bedzie zmergowane z branchem od silosow
    };
}

class ChooseDestination extends Component {

    constructor(props) {
        super(props);
        const {initialize} = this.props;
        initialize({
            type: "device",
            actions: ChooseDestination.allowedActions(this.props)
        })
    }

    static allowedActions = (props) => {
        const actions = {device: false, chamber: false, silo: false};
        const {devices, buildingsMap, device} = props;

        //urzadzenie
        if (!!devices.find(d => d.DevID === device.DevID)) {
            actions.device = isFinite(device.Adr) ? `${device.DevID}_${device.Adr}` : `${device.DevID}`;
        }

        //silos
        actions.silo = false; // todo: dodac po mergu z silosami

        //komory
        let devicePlcmnt = buildingsMap.get(device.placementId);
        if (devicePlcmnt) {
            if (devicePlcmnt.level === Level.CHAMBER) {
                actions.chamber = device.placementId;
            } else if (devicePlcmnt.level === Level.BOX) {
                const chamberId = devicePlcmnt.parentId
                devicePlcmnt = buildingsMap.get(chamberId);
                if (devicePlcmnt) {
                    actions.chamber = chamberId;
                }
            }
        }
        return actions;
    }

    onChange = (type = "device") => {
        const {change} = this.props;
        change("type", type);
    }

    render() {
        const {device, type, actions = {}, handleSubmit, submitting, buildingsMap, t} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                {t("newSettings.feeding.forages.calibration.chooseDestination")}
                <Row>
                    {
                        !!actions.device &&
                        <Col className={"pointer"} md={6} onClick={() => this.onChange("device")}>
                            <Card shadow={false} className={"h-100"} selected={type === "device"}>
                                <h5> {t("newSettings.feeding.forages.calibration.setOnDevice")}</h5>
                                <div className={"opacity-50 text-wrap"}>{device.name}</div>
                            </Card>
                        </Col>
                    }
                    {
                        !!actions.chamber && <Col className={"pointer"} md={6} onClick={() => this.onChange("chamber")}>
                            <Card shadow={false} className={"h-100"} selected={type === "chamber"}>
                                <h5> {t("newSettings.feeding.forages.calibration.setOnChamber")}</h5>
                                <div
                                    className={"opacity-50 text-wrap"}>{getLocationNameByBuildingMapSelector(buildingsMap, actions.chamber)}</div>
                            </Card>

                        </Col>
                    }
                    {
                        // tutaj dorobic silos jak bedzie na froncie
                    }
                </Row>

                <ButtonGroup fixed renderInPortal={false}>
                    <Button
                        isLoading={submitting}
                        buttonStyle="round"
                        buttonColor="success"
                        icon={<i className="fas fa-plus"/>}
                    />
                </ButtonGroup>
            </form>
        );
    }
}

const submit = (values, dispatch, props) => {
    const {data, history, farm} = props;
    const {type, actions} = values;
    const params = {Efficiency: +data.efficiency};
    const key = {
        device: "DevIDs",
        silo: "SiloIDs",
        chamber: "PlcmntIDs"
    }
    params[key[type]] = [actions[type]].filter(o => !!o);
    return setFeedingEfficiency(params).then((res) => {
        setFeedingEfficiencyNotificationSuccess(res)
        history.push(`/${farm}/settings`);
    }).catch((err) => {
        setFeedingEfficiencyNotificationFailure(err)
    })
}


ChooseDestination = reduxForm({
    form: ModalName,
    onSubmit: submit,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
})(ChooseDestination);

ChooseDestination = connect(
    mapStateToProps,
)(ChooseDestination);

ChooseDestination = withRouter(ChooseDestination);
export default withTranslation()(ChooseDestination);
