import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import ReduxLabeledSelect from "../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import {connect} from "react-redux";
import {DevType} from "../../../../constans/devices";
import {getFlatDevices} from "../../../../utils/DevicesUtils";
import {get, isNil} from "lodash";
import TestDispenserConnection
    from "../../../../components/calibration-view-components/test-dispenser-connection/TestDispenserConnection";
import Card from "../../../../components/basics/card/Card";
import Stepper from "../../../../components/basics/stepper/Stepper";
import Step from "../../../../components/basics/stepper/Step";
import {ReactSVG} from "react-svg";
import StartCalibration from "../../../../resources/images/calibration/calibration_start.svg";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../components/basics/button/Button";
import DispenserBag from "../../../../resources/images/calibration/dispenser_bag.svg";
import "./_calibration.scss";
import DispenserCalibration from "./DispenserCalibration";
import {Col, Row} from "react-bootstrap";
import {Route, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {getBuildingsMap} from "../../../../selectors/buildingsSelector";
import {getLocationNameByBuildingMapSelector, getPlcmntIDsByDevice} from "../../../../utils/BuildingUtils";
import ChooseDestination from "./ChooseDestination";

const FormName = 'settings-feeding-calibration-manage';

export class ManageCalibration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeStep: 1,
            locationOptions: [],
            efficiencyData: undefined
        };
    }

    componentDidMount() {
        this.setState({
            locationOptions: this.getLocationSelect(this.props)
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const devicesChanged = prevProps.devices.length !== this.props.devices.length;
        const buildingsChanged = prevProps.buildingsMap.size !== this.props.buildingsMap.size;
        if (devicesChanged || buildingsChanged) {
            this.setState({
                locationOptions: this.getLocationSelect(this.props)
            })
        }
    }

    getLocationID(location) {
        return location.CID || location.SID || location.BID || location.BgID || location.FarmID
    }

    getLocationSelect = (props) => {
        const {devices, buildingsMap} = props;
        const flatDevices = [];
        for (let dev of getFlatDevices(devices || [])) {
            if ([DevType.DISPENSER, DevType.DISPENSER_NRF].includes(dev.device.DevType)) {
                const index = get(dev, "index");
                const location = getPlcmntIDsByDevice({device: dev.device, index: index});
                if (location.length !== 1 || !getLocationNameByBuildingMapSelector(buildingsMap, location[0])) continue;
                const address = get(dev, "device.Address");
                const deviceAddress = !isNil(index) ? `${address.toString(16)} {${index}}` : address.toString(16);
                const name = `${getLocationNameByBuildingMapSelector(buildingsMap, location[0])} | 0x${deviceAddress}`;
                const tmp = {
                    name: name,
                    value: {
                        DevID: dev.device.DevID,
                        Adr: dev.index,
                        placementId: location[0],
                        name: name
                    }
                };
                flatDevices.push(tmp);
            }
        }
        return flatDevices;
    };

    onStepClick = (index) => {
        if (this.state.activeStep !== 6) {
            this.setState({
                activeStep: index
            });
        }
    };

    onNext = () => {
        const {activeStep} = this.state;
        this.setState({
            activeStep: activeStep + 1
        });
    };

    onBack = () => {
        const {activeStep} = this.state;
        this.setState({
            activeStep: activeStep - 1
        });
    };

    onNextEfficiencyData = (data) => {
        this.setState(state => ({
            activeStep: state.activeStep + 1,
            efficiencyData: data
        }), () => {
            this.props.history.push(`/${this.props.farm}/settings/feeding/calibration/summary`)
        })
    };

    render() {
        const {dispenser, gateways, t} = this.props;
        const {locationOptions, activeStep} = this.state;

        return (
            <div>
                <Card>
                    <Stepper activeStep={activeStep} onClickStep={this.onStepClick}>
                        <Step label={t("newSettings.feeding.forages.calibration.firstStep")}/>
                        <Step label={t("newSettings.feeding.forages.calibration.secondStep")}/>
                        <Step label={t('newSettings.feeding.forages.calibration.thirdStep')}/>
                        <Step label={t("calibration")}/>
                        <Step label={t("summary")}/>
                    </Stepper>
                    {
                        activeStep === 1 &&
                        <>
                            <div className={'calibration'}>
                                <div className={'calibration-container'}>
                                    <ReactSVG src={StartCalibration}/>
                                </div>
                                <p>{t("newSettings.feeding.forages.calibration.firstStepMessage")}</p>
                                <ButtonGroup fixed>
                                    <Button
                                        buttonColor={'success'}
                                        buttonStyle={'round'}
                                        icon={<i className={'fas fa-arrow-right'}/>}
                                        onClick={this.onNext}
                                    />
                                </ButtonGroup>
                            </div>
                        </>
                    }
                    {
                        activeStep === 2 &&
                        <>
                            <Row className="justify-content-center">
                                <Col md={6}>
                                    <TestDispenserConnection device={dispenser}
                                                             onNextClick={this.onNext}>
                                        <Field
                                            name="dispenser"
                                            id={"dispenser"}
                                            label={t("location")}
                                            options={locationOptions}
                                            component={ReduxLabeledSelect}
                                        />
                                    </TestDispenserConnection>
                                    <p>{t("newSettings.feeding.forages.calibration.fiveSeconds")}</p>
                                </Col>
                            </Row>
                            <ButtonGroup fixed>
                                <Button
                                    buttonColor={'success'}
                                    buttonStyle={'round'}
                                    icon={<i className={'fas fa-arrow-left'}/>}
                                    onClick={this.onBack}
                                />
                                <Button
                                    disabled={!dispenser}
                                    buttonColor={'success'}
                                    buttonStyle={'round'}
                                    icon={<i className={'fas fa-arrow-right'}/>}
                                    onClick={this.onNext}
                                />
                            </ButtonGroup>
                        </>
                    }
                    {
                        activeStep === 3 &&
                        <>
                            <div className={'calibration'}>
                                <div className={'calibration-container'}>
                                    <ReactSVG src={DispenserBag}/>
                                </div>
                                <p>{t("newSettings.feeding.forages.calibration.thirdStepMessage")}</p>
                                <ButtonGroup fixed>
                                    <Button
                                        buttonColor={'success'}
                                        buttonStyle={'round'}
                                        icon={<i className={'fas fa-arrow-left'}/>}
                                        onClick={this.onBack}
                                    />
                                    <Button
                                        buttonColor={'success'}
                                        buttonStyle={'round'}
                                        icon={<i className={'fas fa-arrow-right'}/>}
                                        onClick={this.onNext}
                                    />
                                </ButtonGroup>
                            </div>
                        </>
                    }
                    {
                        activeStep === 4 &&
                        <>
                            <Row className="justify-content-center">
                                <Col md={6}>
                                    <DispenserCalibration device={dispenser}
                                                          onNextClick={this.onNextEfficiencyData}/>
                                    <p>{t("newSettings.feeding.forages.calibration.sixtySeconds")}</p>
                                </Col>
                            </Row>
                        </>
                    }
                    {
                        this.state.efficiencyData && activeStep === 5 &&
                        <React.Fragment>
                            <Route
                                path={`/${this.props.farm}/settings/feeding/calibration/summary`}
                                render={() => <ChooseDestination device={dispenser} gateways={gateways}
                                                                 data={this.state.efficiencyData}/>}
                            />
                        </React.Fragment>
                    }
                </Card>
            </div>
        )
    }
}

ManageCalibration = reduxForm({
    form: FormName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
})(ManageCalibration);

const selector = formValueSelector(FormName);

ManageCalibration = connect((state) => ({
    devices: state.farmDevices.devices,
    dispenser: selector(state, "dispenser"),
    forages: state.settings.forage,
    buildingsMap: getBuildingsMap(state),
    farm: state.location.farm,
}))(ManageCalibration);

ManageCalibration = withRouter(ManageCalibration);
export default withTranslation()(ManageCalibration);
