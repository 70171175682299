import React from "react";
import Card from "../../../../../components/basics/card/Card";
import {connect} from "react-redux";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../components/basics/button/Button";
import {withTagReader} from "../../../../mobile-rfid-scanner/tag-reader-hoc/withTagReader";
import {withTranslation} from "react-i18next";
import {getIn, withFormik} from "formik";
import buildingsDB from "../../../../../database/buildingsDB";
import LabeledInput from "../../../../../components/basics/input/labeled-input/LabeledInput";
import {Col, Row} from "react-bootstrap";
import {submit, validate} from "./AssignRFIDToStandSubmit"
import "./assign-rfid-to-stand.scss"
import {debounce} from "lodash"
import SearchInput from "../../../../../components/basics/search-input/SearchInput";

export class AssignRFIDToStand extends React.Component {

    state = {
        search: ""
    }

    searchbox = React.createRef();

    search = debounce(() => {
        const {search} = this.state;
        if (search) {
            let element = null
            const {values: {chambers}} = this.props;
            let chamber = chambers.find(item => item.CName.includes(search) || (item.RFID && (item.RFID + "").includes(search)));
            if (chamber) {
                element = document.getElementById(chamber.CID);
            }
            for (let ch of chambers) {
                if (ch.Boxes) {
                    let box = ch.Boxes.find(item => item.BoxesName.includes(search) || (item.RFID && (item.RFID + "").includes(search)));
                    if (box) {
                        element = document.getElementById(box.BID);
                        break;
                    }
                }
            }
            if (element) {
                let rect = element.getBoundingClientRect();
                window.scrollTo({
                    top: rect.top - this.searchbox.current.clientHeight + window.scrollY,
                    behavior: "smooth"
                })
            }
        } else {
            window.scrollTo({top: 0, behavior: "smooth"});
        }
    }, 300)

    findNextEmpty(array = this.props.values.chambers, path = "chambers") {
        for (let i = 0; i < array.length; i++) {
            let item = array[i];
            let p = `${path}[${i}]`;
            if (item.Boxes) {
                let foundDeep = this.findNextEmpty(item.Boxes, `${p}.Boxes`);
                if (foundDeep) return foundDeep;
            } else {
                if (!item.RFID) {
                    return `${p}.RFID`;
                }
            }
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tagReader.tag !== prevProps.tagReader.tag) {
            let focused = document.activeElement;
            if (focused.tagName !== "INPUT") {
                let name = this.findNextEmpty();
                this.props.setFieldValue(name, this.props.tagReader.tag);
                if (name) {
                    focused = document.getElementById(name);
                }
            }
            if (focused) {
                let inputs = document.getElementsByTagName("input");
                let position = [...inputs].indexOf(focused);
                for (let i = position + 1; i < inputs.length; i++) {
                    if (!inputs[i].value) {
                        inputs[i].focus();
                        break;
                    }
                }
            }
        }
    }

    renderBoxes(boxes, chamberIndex) {
        const {handleChange, handleBlur, touched, errors} = this.props;
        let array = [];
        for (let i = 0; i < boxes.length; i += 2) {
            let value1 = boxes[i];
            let value2 = boxes[i + 1];
            let name1 = `chambers[${chamberIndex}].Boxes[${i}].RFID`;
            let name2 = `chambers[${chamberIndex}].Boxes[${i + 1}].RFID`;
            array.push(
                <Row key={i}>
                    <Col sm={6} id={value1.BID}>
                        <LabeledInput label={value1.BoxesName} type={"number"}
                                      name={name1} onChange={handleChange}
                                      value={value1.RFID} onBlur={handleBlur} onChangeWithEvent
                                      error={getIn(touched, name1) && getIn(errors, name1)} id={name1}/>
                    </Col>
                    <Col sm={6} id={value2 ? value2.BID : value1.BID}>
                        {
                            value2 &&
                            <LabeledInput label={value2.BoxesName} type={"number"}
                                          name={name2}
                                          onChange={handleChange}
                                          value={value2.RFID} onBlur={handleBlur} onChangeWithEvent
                                          error={getIn(touched, name2) && getIn(errors, name2)} id={name2}/>
                        }
                    </Col>
                </Row>
            )
        }
        return array;
    }

    onSearchChange = value => {
        this.setState({
            search: value
        }, () => {
            this.search();
        })
    }

    render() {
        const {search} = this.state;
        const {handleSubmit, handleChange, values, handleBlur, errors, touched} = this.props;
        return (
            <Card className="assign-rfid-to-stand">
                <div className="searchbox" ref={this.searchbox}>
                    <SearchInput type={"text"} onChange={this.onSearchChange} value={search}/>
                </div>
                <form onSubmit={handleSubmit}>
                    {
                        values && values.chambers.map((chamber, index) =>
                            <div key={index} id={chamber.CID}>
                                <h4>{chamber.CName}</h4>
                                {
                                    chamber.Boxes && this.renderBoxes(chamber.Boxes, index)

                                }
                                {
                                    !chamber.Boxes &&
                                    <LabeledInput label={"RFID"} type={"number"} onChange={handleChange}
                                                  value={chamber.RFID} name={`chambers[${index}].RFID`}
                                                  onBlur={handleBlur} onChangeWithEvent
                                                  error={getIn(touched, `chambers[${index}].RFID`) && getIn(errors, `chambers[${index}].RFID`)}
                                                  id={`chambers[${index}].RFID`}/>
                                }
                            </div>
                        )
                    }
                    <ButtonGroup fixed renderInPortal={false}>
                        <Button icon={<i className="fas fa-save"/>} buttonStyle={"round"} buttonColor={"success"}/>
                    </ButtonGroup>
                </form>
            </Card>
        );
    }

}

AssignRFIDToStand = connect(state => ({
    farm: state.location.farm
}))(AssignRFIDToStand);

AssignRFIDToStand = withFormik({
    mapPropsToValues: props => ({
        chambers: buildingsDB.getAllChambers(props.farm)
    }),
    displayName: "assignRFID",
    validate,
    handleSubmit: submit
})(AssignRFIDToStand)
AssignRFIDToStand = withTagReader()(AssignRFIDToStand);
export default withTranslation()(AssignRFIDToStand);