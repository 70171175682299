import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import LabeledInput from "../../../../../components/basics/input/labeled-input/LabeledInput";
import PropTypes from "prop-types";
import {setExitTime} from "../../../../../IOT/device-functions/SeparationCageFunctions";
import _ from "lodash";
import {connect} from "react-redux";
import {SeparationCageCommandTypes} from "../../../../../constans/mqttMessages";
import {LoadedDataTypes} from "../../../../../constans/devices";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class ExitTime extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.getExitTime(),
            changed: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.changed) {
            this.setState(this.getExitTime(nextProps))
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    getExitTime(props = this.props) {
        let device = props.cages[0];
        try {
            let shadow = props.shadows.get(device.DevID);
            return {
                exitTime: shadow.configuration.exitTime,
                loadedDataFrom: LoadedDataTypes.SHADOW,
                SetTime: shadow.metadata.configuration,
                device
            };
        } catch (e) {
            try {
                return {
                    exitTime: _.get(device, `Settings.Configuration.${SeparationCageCommandTypes.SET_EXIT_TIME}.exitTime`, 6),
                    SetTime: _.get(device, `Settings.Configuration.${SeparationCageCommandTypes.SET_EXIT_TIME}.SetTime`, 0),
                    loadedDataFrom: device && device.Settings && device.Settings.Configuration && device.Settings.Configuration[SeparationCageCommandTypes.SET_EXIT_TIME] ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA,
                    device
                };
            } catch (e) {
                return 6;
            }
        }
    }

    onExitTimeChange = value => {
        this.setState({
            exitTime: value,
            changed: true
        })
    };

    onClick = () => {
        if (this.state.exitTime) {
            setExitTime(this.props.cages, +this.state.exitTime);
        }
    };

    render() {
        const {t} = this.props;
        const {exitTime, loadedDataFrom, SetTime, device} = this.state;
        return (
            <DeviceSettingsCard title={this.props.t("newSettings.cage.manage.exitTime.title")}
                                onButtonClick={this.onClick}>
                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[SeparationCageCommandTypes.SET_EXIT_TIME].isSet &&
                            <InfoBox
                                boxColor="warning">{i18next.t("newSettings.devices.settingsInfo.dynamo", {date: moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                <LabeledInput label={this.props.t("exitTime")} type="number" unit="min"
                              value={exitTime}
                              onChange={this.onExitTimeChange}/>
            </DeviceSettingsCard>
        );
    }

}

ExitTime.propTypes = {
    cages: PropTypes.array.isRequired
};

ExitTime = connect(state => ({
    shadows: state.shadows.shadows,
}))(ExitTime);

export default withTranslation()(ExitTime);