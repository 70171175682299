import React, {Component} from 'react';
import Template from "./Template";
import {
    setClimateCooling,
    setCoolingBreakTime,
    setCoolingEnableTemperature,
    setCoolingMaximumHumidity,
    setCoolingMaximumPressure,
    setCoolingMinimumPressure,
    setCoolingWorkTime
} from "../../../../../IOT/device-functions/ClimateFunctions";
import {percentageFormatter, pressureFormatter, secondsFormatter, temperatureFormatter} from "./utils";
import {withTranslation} from "react-i18next";
import {groupDevicesByGatewayID} from "../../../../../utils/DevicesUtils";
import {DevType} from "../../../../../constans/devices";

class CoolerMenu extends Component {


    getModel = () => {
        const {t} = this.props;
        return [
            {
                name: t("settings.switchOnTemp"),
                key: 'coolingEnableTemperature',
                shadowKey: 'configuration.coolingConfiguration.coolingEnableTemperature',
                min: 2,
                max: 50.6,
                step: 0.2,
                defaultValue: 26,
                formatter: temperatureFormatter,
                submit: setCoolingEnableTemperature
            },
            {
                name: t("workTime"),
                key: 'coolingWorkTime',
                shadowKey: 'configuration.coolingConfiguration.coolingWorkTime',
                min: 10 * 1000,
                max: 600 * 1000,
                step: 10 * 1000,
                defaultValue: 120 * 1000,
                formatter: secondsFormatter,
                submit: setCoolingWorkTime
            },
            {
                name: t("stopTime"),
                key: 'coolingBreakTime',
                shadowKey: 'configuration.coolingConfiguration.coolingBreakTime',
                min: 10 * 1000,
                max: 600 * 1000,
                step: 10 * 1000,
                defaultValue: 120 * 1000,
                formatter: secondsFormatter,
                submit: setCoolingBreakTime
            },
            {
                name: t("settings.maxHumidity"),
                key: 'coolingMaximumHumidity',
                shadowKey: 'configuration.coolingConfiguration.coolingMaximumHumidity',
                min: 20,
                max: 100,
                step: 1,
                defaultValue: 90,
                formatter: percentageFormatter,
                submit: setCoolingMaximumHumidity
            },
            {
                name: t("settings.minPressure"),
                key: 'coolingMinimumPressure',
                shadowKey: 'configuration.coolingConfiguration.coolingMinimumPressure',
                min: 0,
                max: 60,
                step: 1,
                defaultValue: 20,
                formatter: pressureFormatter,
                submit: setCoolingMinimumPressure
            },
            {
                name: t("settings.maxPressuer"),
                key: 'coolingMaximumPressure',
                shadowKey: 'configuration.coolingConfiguration.coolingMaximumPressure',
                min: 30,
                max: 121,
                step: 1,
                defaultValue: 60,
                formatter: pressureFormatter,
                submit: setCoolingMaximumPressure
            }
        ]
    }

    submit = (values) => {
        const {climates} = this.props;
        console.log(values, "CoolerMenu submit");
        const devices = groupDevicesByGatewayID(climates);
        if (devices) {
            for (let deviceList of devices.values()) {
                setClimateCooling(deviceList[DevType.CLIMATE_SK3], values);
            }
        } else {
            console.error("devices not found")
        }
    }

    render() {
        const model = this.getModel();
        const {climates, t} = this.props;
        return (
            <>
                <Template
                    climates={climates}
                    name={t("settings.coolingSettings")}
                    model={model}
                    onSubmit={this.submit}
                    form={"climate-sk3-cooler-menu"}
                />
            </>
        );
    }
}

export default withTranslation()(CoolerMenu);
