import _ from "lodash";
import {updateLocation} from "../../../../../actions/farmsActions";

export function submit({chamberName, individualFeeding, chamberSize, standsInRow, standsOrder}, dispatch, props) {
    console.log({chamberName, individualFeeding, chamberSize, standsInRow, standsOrder}, props);
    const chamber = _.cloneDeep(props.chamber);
    chamber.CName = chamberName;
    //jesli mamy karmienie indywidualne to usuwamy atrybuty z karmienia grupowego takie jak CSize
    if (individualFeeding) {
        delete chamber.CSize;
        chamber.IndividualFeeding = true;
        chamber.StandsInRow = +standsInRow;
        chamber.StandsOrder = _.isFinite(standsOrder) ? standsOrder : 1;
        chamber.Boxes = _.get(chamber, "Boxes", []);
    } else {
        //usuwamy boksy gdy nie ma karmienia indiwudalnego
        delete chamber.Boxes;
        delete chamber.IndividualFeeding;
        delete chamber.StandsInRow;
        delete chamber.StandsOrder;
    }
    return dispatch(updateLocation(chamber));
}

export function validate(values, props) {
    const errors = {};
    const {chamberName, chamberSize, standsInRow} = values;
    const {t} = props;

    if (!chamberName) {
        errors.chamberName = t("required");
    }
    if (!standsInRow || +standsInRow <= 0) {
        errors.standsInRow = t("required");
    }
    if (!chamberSize || +chamberSize <= 0) {
        errors.chamberSize = t("required");
    }
    return errors;
}

export function warning(values, props) {
    const warnings = {};
    const {individualFeeding} = values;
    const {IndividualFeeding} = props;
    if (!!IndividualFeeding && (!!IndividualFeeding !== !!individualFeeding)) {
        warnings.individualFeeding = 'Wyłącznie karmienia indiwidualnego usuwa stanowiska z komory'
    }
    return warnings;
}