import settingsDB from "../../../../../database/settingsDB";
import _ from "lodash";
import {createSettingDynamoDB, updateSettingDynamoDB} from "../../../../../actions/settingsAction";
import Setting from "../../../../../beans/Setting";
import {SettingTypes} from "../../../../../constans/settingTypes";

export function submit(values, bag) {
    const {ClientID, dispatch} = bag.props;
    let general = settingsDB.getSettingByID(ClientID);
    console.log(general);

    if (general) {
        let clone = _.cloneDeep(general);
        if (!clone.SetData) {
            clone.SetData = {Settings: {}};
        } else {
            if (!clone.SetData.Settings) {
                clone.SetData.Settings = {};
            }
        }
        clone.SetData.Settings.FeedingLevels = {
            ...values
        };
        clone.DtaModTime = +new Date();
        dispatch(updateSettingDynamoDB(clone));
    } else {
        let setting = Setting.createSetting(SettingTypes.GENERAL, {Settings: {FeedingLevels: values}});
        dispatch(createSettingDynamoDB(setting));
    }
}