import React from "react";
import Card from "../../../../components/basics/card/Card";
import {Field, reduxForm} from "redux-form";
import {Col, Row} from "react-bootstrap";
import FormForSettings from "../../FormForSettings";
import ReduxLabeledSelect from "../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import {submit} from "./DisplayingCageSubmit";
import {connect} from "react-redux";
import {getPassageAccuracy} from "../../../../utils/SettingsUtils";

export class DisplayingCage extends React.Component {

    constructor(props) {
        super(props);

        this.props.initialize({
            passage: getPassageAccuracy()
        })
    }


    render() {
        const {handleSubmit} = this.props;
        return (
            <Card>
                <FormForSettings handleSubmit={handleSubmit}>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <Field
                                id="passage"
                                name="passage"
                                component={ReduxLabeledSelect}
                                label={"Dokładność wykresu przejść w wadzę"}
                                options={[
                                    {
                                        name: "0.5 kg",
                                        value: 0.5
                                    },
                                    {
                                        name: "1 kg",
                                        value: 1
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                </FormForSettings>
            </Card>
        );
    }

}

DisplayingCage = reduxForm({
    form: "displaying-cage",
    onSubmit: submit
})(DisplayingCage);

DisplayingCage = connect(state => ({
    user: state.user.user
}))(DisplayingCage);

export default DisplayingCage;