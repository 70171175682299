import _ from "lodash";
import {updateLocation} from "../../../../../actions/farmsActions";

export function submit({buildingName}, dispatch, props) {
    console.log("UpdateBuilding", props);
    const building = _.cloneDeep(props.building);
    building.BName = buildingName;
    return dispatch(updateLocation(building));
}

export function validate(values, props) {
    const errors = {};
    const {buildingName} = values;
    const {t} = props;
    if (!buildingName) {
        errors.buildingName = t("required");
    }
    return errors;
}