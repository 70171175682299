import React, {Component} from 'react'
import {DeviceSettingsCard} from '../../../../../components/device-settings-card/DeviceSettingsCard';
import {Field, FieldArray, formValueSelector, isDirty, reduxForm} from 'redux-form';
import ReduxSwitch from './../../../../../components/basics/switch/ReduxSwitch';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import ReduxButtonPicker from './../../../../../components/basics/redux-button-picker/ReduxButtonPicker';
import Button from "../../../../../components/basics/button/Button";
import {convertCurrentUnitTo, convertVoltageUnitTo} from '../../../../../utils/UnitUtils';
import {UnitTypes} from '../../../../../constans/unitTypes';
import ReduxCheckbox from './../../../../../components/basics/checkbox/ReduxCheckbox';
import {ChainFeedingCommandTypes} from '../../../../../constans/mqttMessages';
import {LoadedDataTypes} from "../../../../../constans/devices";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import {stringBuilder} from "../../../../../utils/TextUtils";
import moment from "moment";
import {setSensorWorkType} from '../../../../../IOT/device-functions/ChainFeedingFunctions';
import ReduxLabeledSelect from "../../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import {get, isArray, isEqual, isNil} from "lodash";
import ReduxLabeledSlider from "../../../../../components/basics/slider/labeled-slider/ReduxLabeledSlider";

class SensorWorkType extends Component {

    constructor(props) {
        super(props);
        this.voltageOptions = [{
            name: this.voltageFormatter([0, 10 * 1000]),
            value: [0, 10 * 1000],
        }, {
            name: this.voltageFormatter([2 * 1000, 10 * 1000]),
            value: [2 * 1000, 10 * 1000],
        }];
        this.currentOptions = [{
            name: this.currentFormatter([4, 20]),
            value: [4, 20],
        }, {
            name: this.currentFormatter([5, 20]),
            value: [5, 20],
        }];
        props.initialize(this.getState());
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {devices, shadows} = nextProps;
        const device = devices[0];
        const shadow = device ? shadows.get(device.DevID) : null;
        if (shadow && !nextProps.dirty) {
            nextProps.initialize(this.getState(nextProps))
        }
    }

    getState = (props = this.props) => {
        const result = {sensors: [], outputs: [], inputs: []};
        const {devices, shadows} = props;
        const device = devices[0];
        const useVoltage = !!get(device, "Settings.UseVoltage");
        const shadow = device ? shadows.get(device.DevID) : null;
        for (let i = 0; i < 8; i++) {
            result.inputs[i] = !!get(shadow, `workType.inputNegation[${i}]`, get(device, `Settings.Configuration[${ChainFeedingCommandTypes.SET_SENSOR_WORK_TYPE}].inputNegation[${i}]`, 0))
            result.outputs[i] = !!get(shadow, `workType.outputNegation[${i}]`, get(device, `Settings.Configuration[${ChainFeedingCommandTypes.SET_SENSOR_WORK_TYPE}].outputNegation[${i}]`, 0))
            // result.sensors[i] = !!get(shadow, `workType.sensorNegation[${i}]`, get(device, `Settings.Configuration[${ChainFeedingCommandTypes.SET_SENSOR_WORK_TYPE}].sensorNegation[${i}]`, 0))
        }
        result.sensorID = get(shadow, "workType.sensorID", get(device, `Settings.Configuration[${ChainFeedingCommandTypes.SET_SENSOR_WORK_TYPE}].sensorID`, 0))
        result.actuatorPositioningTimeMax = get(shadow, "workType.actuatorPositioningTimeMax", get(device, `Settings.Configuration[${ChainFeedingCommandTypes.SET_SENSOR_WORK_TYPE}].actuatorPositioningTimeMax`, 0))
        result.actuatorPositioningTime = get(shadow, "workType.actuatorPositioningTime", get(device, `Settings.Configuration[${ChainFeedingCommandTypes.SET_SENSOR_WORK_TYPE}].actuatorPositioningTime`, 0))
        result.outputCurrentRangeReversal = !!get(shadow, "workType.outputCurrentRangeReversal", get(device, `Settings.Configuration[${ChainFeedingCommandTypes.SET_SENSOR_WORK_TYPE}].outputCurrentRangeReversal`, 0))
        result.outputCurrent = [
            get(shadow, "workType.outputMinCurrent", get(device, `Settings.Configuration[${ChainFeedingCommandTypes.SET_SENSOR_WORK_TYPE}].outputMinCurrent`, 0)),
            get(shadow, "workType.outputMaxCurrent", get(device, `Settings.Configuration[${ChainFeedingCommandTypes.SET_SENSOR_WORK_TYPE}].outputMaxCurrent`, 0))
        ];
        result.inputCurrent = [
            get(shadow, "workType.inputMinCurrent", get(device, `Settings.Configuration[${ChainFeedingCommandTypes.SET_SENSOR_WORK_TYPE}].inputMinCurrent`, 0)),
            get(shadow, "workType.inputMaxCurrent", get(device, `Settings.Configuration[${ChainFeedingCommandTypes.SET_SENSOR_WORK_TYPE}].inputMaxCurrent`, 0))
        ];
        const options = useVoltage ? this.voltageOptions : this.currentOptions;
        if (!options.find(opt => isEqual(opt.value, result.inputCurrent))) {
            result.inputCurrent = null
        }
        if (!options.find(opt => isEqual(opt.value, result.outputCurrent))) {
            result.outputCurrent = null
        }
        result.SetTime = get(shadow, "metaData.workType", get(device, `Settings.Configuration[${ChainFeedingCommandTypes.SET_SENSOR_WORK_TYPE}].SetTime`, 0))
        result.loadedDataFrom =
            isNil(get(shadow, "workType.outputMinCurrent")) ?
                !!get(device, `Settings.Configuration[${ChainFeedingCommandTypes.SET_SENSOR_WORK_TYPE}]`) ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA : LoadedDataTypes.SHADOW;

        return result;
    }

    currentFormatter = (value) => {
        if (Array.isArray(value)) {
            return `${convertCurrentUnitTo(value[0], {
                showUnit: false,
                unit: UnitTypes.SMALL,
                fixed: 0
            })}-${convertCurrentUnitTo(value[1], {showUnit: true, unit: UnitTypes.SMALL, fixed: 0})}`
        }
        return convertCurrentUnitTo(value, {showUnit: true, unit: UnitTypes.SMALL, fixed: 0});
    }

    voltageFormatter = (value) => {
        if (Array.isArray(value)) {
            return `${convertVoltageUnitTo(value[0], {
                showUnit: false,
                unit: UnitTypes.SMALL,
                fixed: 0
            })}-${convertVoltageUnitTo(value[1], {showUnit: true, unit: UnitTypes.SMALL, fixed: 0})}`
        }
        return convertVoltageUnitTo(value, {showUnit: true, unit: UnitTypes.SMALL, fixed: 0});
    }

    renderInputs = ({fields}) => {
        const {t} = this.props;
        return (<>
            <label>{t("newSettings.chainFeeding.sensorWorkType.inputNegation")}</label>
            {
                fields.map((member, i) => (
                    <div>
                        <Field
                            name={`${member}`}
                            id={`${member}`}
                            component={ReduxCheckbox}
                            label={t("newSettings.chainFeeding.sensorWorkType.input", {number: i + 1})}
                        />
                    </div>

                ))
            }

        </>)
    }


    renderOutputs = ({fields}) => {
        const {t} = this.props;
        return (<>
            <label>{t("newSettings.chainFeeding.sensorWorkType.outputNegation")}</label>
            {
                fields.map((member, i) => (
                    <div>
                        <Field
                            name={`${member}`}
                            id={`${member}`}
                            component={ReduxCheckbox}
                            label={t("newSettings.chainFeeding.sensorWorkType.output", {number: i + 1})}
                        />
                    </div>

                ))
            }

        </>)
    }


    render() {
        const {t, submit, loadedDataFrom, SetTime, devices} = this.props;
        const device = devices[0];
        const useVoltage = !!get(device, "Settings.UseVoltage");
        return (
            <DeviceSettingsCard title={t("newSettings.chainFeeding.sensorWorkType.title")} onButtonClick={submit}>

                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[ChainFeedingCommandTypes.SET_SENSOR_WORK_TYPE].isSet &&
                            <InfoBox
                                boxColor="warning">{stringBuilder(t("newSettings.devices.settingsInfo.dynamo"), moment(SetTime).format("DD.MM.YYYY HH:mm"))}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                <FieldArray name={"inputs"} component={this.renderInputs}/>
                <FieldArray name={"outputs"} component={this.renderOutputs}/>
                <label>{t("newSettings.chainFeeding.sensorWorkType.chooseSensor")}</label>
                <Field
                    name="sensorID"
                    component={ReduxButtonPicker}
                >
                    {
                        new Array(4).fill(1).map((o, i) => (
                            <Button buttonStyle={"text"}
                                    value={i}>{t("newSettings.chainFeeding.sensorWorkType.sensor", {number: i + 1})}</Button>
                        ))
                    }
                </Field>
                <Field
                    label={t(`newSettings.chainFeeding.sensorWorkType.${useVoltage ? "inputVoltage" : "inputCurrent"}`)}
                    component={ReduxLabeledSelect}
                    options={useVoltage ? this.voltageOptions : this.currentOptions}
                    name={'inputCurrent'}
                    id={'inputCurrent'}
                />
                <Field
                    label={t(`newSettings.chainFeeding.sensorWorkType.${useVoltage ? "outputVoltage" : "outputCurrent"}`)}
                    component={ReduxLabeledSelect}
                    options={useVoltage ? this.voltageOptions : this.currentOptions}
                    name={'outputCurrent'}
                    id={'outputCurrent'}
                />
                <Field
                    label={t("newSettings.chainFeeding.sensorWorkType.outputCurrentRangeReversal")}
                    component={ReduxSwitch}
                    name={"outputCurrentRangeReversal"}
                    id={"outputCurrentRangeReversal"}
                />
                <Field
                    label={t("newSettings.chainFeeding.sensorWorkType.actuatorPositioningTime")}
                    component={ReduxLabeledSlider}
                    name={"actuatorPositioningTime"}
                    id={"actuatorPositioningTime"}
                    max={5 * 1000 * 60}
                    step={1000}
                    min={0}
                    valueFormatter={(value) => `${value}ms`}
                />
                <Field
                    label={t("newSettings.chainFeeding.sensorWorkType.actuatorPositioningTimeMax")}
                    component={ReduxLabeledSlider}
                    name={"actuatorPositioningTimeMax"}
                    id={"actuatorPositioningTimeMax"}
                    max={5 * 1000 * 60}
                    step={1000}
                    min={0}
                    valueFormatter={(value) => `${value}ms`}
                />
            </DeviceSettingsCard>
        )
    }
}

const submit = (values, dispatch, props) => {
    const {devices} = props;
    const data = {};
    data.inputNegation = [];
    // data.sensorNegation = [];
    data.outputNegation = [];
    data.sensorID = values.sensorID;
    data.outputCurrentRangeReversal = !!values.outputCurrentRangeReversal;
    data.actuatorPositioningTime = values.actuatorPositioningTime;
    data.actuatorPositioningTimeMax = values.actuatorPositioningTimeMax;
    data.outputMinCurrent = values.outputCurrent[0];
    data.outputMaxCurrent = values.outputCurrent[1];
    data.inputMinCurrent = values.inputCurrent[0];
    data.inputMaxCurrent = values.inputCurrent[1];
    for (let i = 0; i < 8; i++) {
        data.inputNegation[i] = !!values.inputs[i];
        // data.sensorNegation[i] = !!values.sensors[i];
        data.outputNegation[i] = !!values.outputs[i];
    }
    console.log(data, devices)
    devices.forEach(device => {
        setSensorWorkType(device, data);
    })
};

const validate = (values, props) => {
    const errors = {};
    const {t} = props;
    const {inputCurrent, outputCurrent} = values;
    if (!isArray(inputCurrent)) {
        errors.inputCurrent = t("required");
    }
    if (!isArray(outputCurrent)) {
        errors.outputCurrent = t("required");
    }
    return errors;
};

SensorWorkType = reduxForm({
    form: "sensorWorkType",
    onSubmit: submit,
    validate,
    initialValues: {
        sensors: [false, false, false, false]
    }
})(SensorWorkType);

const selector = formValueSelector("sensorWorkType");
SensorWorkType = connect(state => ({
    climateCurves: state.settings.climateCurves,
    SetTime: selector(state, "SetTime"),
    loadedDataFrom: selector(state, "loadedDataFrom"),
    dirty: isDirty("sensorWorkType")(state),
    shadows: state.shadows.shadows
}))(SensorWorkType);
export default withTranslation()(SensorWorkType)
