import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import LabeledSelect from "../../../../../components/basics/select/labeled-select/LabeledSelect";
import PropTypes from "prop-types";
import {setDefaultExit} from "../../../../../IOT/device-functions/SeparationCageFunctions";
import _ from "lodash";
import {connect} from "react-redux";
import {SeparationCageCommandTypes} from "../../../../../constans/mqttMessages";
import {LoadedDataTypes} from "../../../../../constans/devices";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class DefaultExit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.getDefaultExit(),
            changed: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.changed) {
            this.setState(this.getDefaultExit(nextProps))
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    getDefaultExit(props = this.props) {
        let device = props.cages[0];
        try {
            let shadow = props.shadows.get(device.DevID);
            return {
                defaultExit: shadow.configuration.defaultExit,
                loadedDataFrom: LoadedDataTypes.SHADOW,
                SetTime: shadow.metadata.configuration,
                device
            };
        } catch (e) {
            return {
                defaultExit: _.get(device, `Settings.Configuration.${SeparationCageCommandTypes.SET_DEFAULT_EXIT}.defaultExit`, 1),
                SetTime: _.get(device, `Settings.Configuration.${SeparationCageCommandTypes.SET_DEFAULT_EXIT}.SetTime`, 0),
                loadedDataFrom: device && device.Settings && device.Settings.Configuration && device.Settings.Configuration[SeparationCageCommandTypes.SET_DEFAULT_EXIT] ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA,
                device
            };
        }
    }

    onDefaultExitChange = value => {
        this.setState({
            defaultExit: value,
            changed: true
        })
    };

    onClick = () => {
        setDefaultExit(this.props.cages, this.state.defaultExit);
    };

    render() {
        let options = [
            {
                name: this.props.t("left"),
                value: 1
            },
            {
                name: this.props.t("right"),
                value: 3
            }
        ];
        const {t} = this.props;
        const {defaultExit, loadedDataFrom, SetTime, device} = this.state;
        return (
            <DeviceSettingsCard title={this.props.t("defaultExit")}
                                onButtonClick={this.onClick}>
                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[SeparationCageCommandTypes.SET_DEFAULT_EXIT].isSet &&
                            <InfoBox
                                boxColor="warning">{i18next.t("newSettings.devices.settingsInfo.dynamo", {date: moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                <LabeledSelect label={this.props.t("newSettings.cage.manage.defaultExit.exit")} options={options}
                               value={defaultExit}
                               onChange={this.onDefaultExitChange}/>
            </DeviceSettingsCard>
        );
    }

}

DefaultExit.propTypes = {
    cages: PropTypes.array.isRequired
};

DefaultExit = connect(state => ({
    shadows: state.shadows.shadows,
}))(DefaultExit);

export default withTranslation()(DefaultExit);