import React from "react";
import Card from "../../../../../components/basics/card/Card";
import {Field, FieldArray, FormSection, formValueSelector, reduxForm} from "redux-form";
import {Col, Collapse, Row} from "react-bootstrap";
import ReduxLabeledInput from "../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import ReduxLabeledSelect from "../../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import {AnimalTypes} from "../../../../../constans/animalTypes";
import {connect} from "react-redux";
import Button from "../../../../../components/basics/button/Button";
import ReduxLabeledSlider from "../../../../../components/basics/slider/labeled-slider/ReduxLabeledSlider";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import {submit, validate} from "./AddGraftingProgramSubmit";
import {withTranslation} from "react-i18next";
import {medicineTypes} from "../../../../../constans/medicine";

export class AddGraftingProgram extends React.Component {

    constructor(props) {
        super(props);

        const {edit, match: {params: {programID, animalKind}}, graftingPrograms} = props;

        if (edit) {
            let program = graftingPrograms.find(item => item.WordID === programID);
            this.props.initialize(program);
        } else {
            this.props.initialize({
                WData: {
                    MedicineList: [{
                        Age: 1
                    }],
                    AnimalKind: +animalKind
                }
            });
        }
    }

    renderMedicineList = ({fields, animalKind}) => (
        <>
            {
                fields.map((field, index) =>
                    <Collapse in={!!field} appear mountOnEnter unmountOnExit key={index}>
                        <div>
                            <Card>
                                <h5 className="justify-content-between align-items-center">
                                    {this.props.t("grafting")} {index + 1}
                                    <Button buttonStyle={"round"} icon={<i className={"fas fa-trash"}/>}
                                            className="text" disabled={fields.length === 1}
                                            onClick={() => fields.remove(index)}/>
                                </h5>
                                <Row>
                                    <Col md={6}>
                                        <Field
                                            name={`${field}.Age`}
                                            component={ReduxLabeledSlider}
                                            min={1}
                                            max={250}
                                            label={animalKind === AnimalTypes.SOW ? this.props.t("newSettings.breeding.graftingProgram.dayAfterInsemination") : this.props.t("newSettings.breeding.graftingProgram.animalAge")}
                                            valueFormatter={value => `${value} ${value === 1 ? this.props.t("day") : this.props.t("days")} `}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field
                                            name={`${field}.Medicine`}
                                            component={ReduxLabeledSelect}
                                            label={this.props.t("medicine")}
                                            options={this.props.medicines.filter(item => item.WData.Type === medicineTypes.VACCINE).map(med => ({
                                                name: med.WData.Name,
                                                value: med.WordID
                                            }))}
                                            clearButton={false}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </Collapse>
                )
            }
            <Card className="pointer" onClick={() => fields.push({Age: 1})}>
                <h5 className="justify-content-between align-items-center">
                    {this.props.t("newSettings.breeding.graftingProgram.addNext")}
                    <i className="fas fa-plus"/>
                </h5>
            </Card>
        </>
    );

    render() {
        const {animalKind, handleSubmit, t} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <FormSection name={"WData"}>
                    <Card>
                        <Row className="justify-content-center">
                            <Col xl={6}>
                                <Field
                                    component={ReduxLabeledInput}
                                    name={"Name"}
                                    label={t("designation")}
                                    type={"text"}
                                />
                            </Col>
                        </Row>
                    </Card>
                    <FieldArray component={this.renderMedicineList} name={"MedicineList"} animalKind={animalKind}/>
                </FormSection>
                <ButtonGroup fixed renderInPortal={false}>
                    <Button buttonColor={"success"} buttonStyle={"round"} icon={<i className="fas fa-save"/>}/>
                </ButtonGroup>
            </form>
        );
    }

}

AddGraftingProgram = reduxForm({
    form: "addGraftingProgram",
    onSubmit: submit,
    validate
})(AddGraftingProgram);

const selector = formValueSelector("addGraftingProgram");
AddGraftingProgram = connect(state => ({
    medicines: state.dictionary.medicine,
    animalKind: selector(state, "WData.AnimalKind"),
    user: state.user.user,
    farm: state.location.farm,
    graftingPrograms: state.dictionary.graftingPrograms,
}))(AddGraftingProgram);

export default withTranslation()(AddGraftingProgram);