import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, formValueSelector} from "redux-form";
import ReduxLabeledSlider from "../../../../components/basics/slider/labeled-slider/ReduxLabeledSlider";
import Button from "../../../../components/basics/button/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {isFunction} from "lodash";

function makeMapStateToProps(initialState, initialProps) {
    const {formName, id} = initialProps;
    const selector = formValueSelector(formName);
    return function mapStateToProps(state) {
        return {
            value: selector(state, id)
        }

    }
}

class SingleValue extends Component {

    onClick = () => {
        const {device, value, submit, id, submitKey} = this.props;
        if (isFunction(submit)) {
            submit(device, {[submitKey || id]: value});
        }

    }

    render() {
        const {name, id, formatter, max, min, step, submit} = this.props;
        return (
            <Row>
                <Col xs={3} sm={2} lg={1} className={"d-flex justify-content-center align-items-center"}>
                    <Button disabled={!isFunction(submit)}
                            onClick={this.onClick}
                            type={"button"}
                            buttonStyle={"bordered"}
                            icon={<i className={"fas fa-paper-plane"}/>}/>
                </Col>
                <Col xs={9} sm={10} lg={11}>
                    <Field
                        label={name}
                        name={id}
                        id={id}
                        valueFormatter={formatter}
                        component={ReduxLabeledSlider}
                        min={min}
                        max={max}
                        step={step}
                    />
                </Col>
            </Row>

        );
    }
}

export default connect(
    makeMapStateToProps,
)(SingleValue);
