import React from "react";
import Card from "../../../../../components/basics/card/Card";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../components/basics/button/Button";
import {connect} from "react-redux";
import ClimateCurveChart from "../../../../../components/charts/ClimateCurveChartSK3";
import withRoles from "../../../../../components/withRoles";
import {Roles} from "../../../../../constans/roles";
import {withTranslation} from "react-i18next";
import {getClimateCurves} from "../../../../../selectors/device-rows/climateSelector";
import {Field, FieldArray, formValueSelector, reduxForm} from "redux-form";
import ReduxLabeledInput from "../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import {Col, Row} from "react-bootstrap";
import ReduxLabeledSlider from "../../../../../components/basics/slider/labeled-slider/ReduxLabeledSlider";
import Setting from "../../../../../beans/Setting";
import {SettingTypes} from "../../../../../constans/settingTypes";
import {redirect} from "../../../../../actions/historyActions";
import {createSettingDynamoDB, updateSettingDynamoDB} from "../../../../../actions/settingsAction";
import {convertTemperatureUnitTo} from "../../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../../constans/unitTypes";

const FormName = "add-climate-sk3-curve";

const validation = {
    days: {
        min: [0, 0, 0, 0, 0, 10, 10, 10],
        max: [0, 10, 20, 30, 40, 40, 43, 50]
    },
    requestedTemperature: {
        min: [12, 16, 16, 16, 16, 16, 16, 16],
        max: [40, 36, 32, 30, 30, 30, 30, 30]
    },
    minimumVentilation: {
        min: [0, 0, 0, 0, 5, 5, 5, 0],
        max: [25, 25, 35, 40, 50, 50, 50, 50]
    },
    floor: {
        min: [0, 0, 0, 0, 0, 0, 0, 0],
        max: [100, 100, 100, 100, 100, 100, 100, 100]
    }
}

export class AddClimateCurveView extends React.Component {

    constructor(props) {
        super(props);
        const {initialize, farm, dispatch} = this.props;
        let stages = [];
        let name = "";
        let id = "";
        try {
            id = props.match.params.id;
            const curve = props.climateCurves.find(item => item.SetID === id);
            const redirectTo = `/${farm}/settings/climate-sk3/curves`
            if (!curve && id) dispatch(redirect(redirectTo))
            curve.SetData.Stages.forEach((stage, index) => {
                stages[index] = {
                    days: stage.Days,
                    requestedTemperature: stage.RequestedTemperature,
                    minimumVentilation: stage.MinimumVentilation,
                    floor: stage.Floor
                }
            });
            name = curve.SetData.Name;
        } catch (e) {
            console.error(e);
            stages = new Array(8).fill(0).map((o, index) => {
                const tmp = {};
                Object.keys(validation).forEach(key => {
                    tmp[key] = validation[key].max[index];
                })
                return tmp;
            })
        }
        initialize({
            stages,
            name,
            id
        })
    }

    temperatureFormatter = (value) => {
        return convertTemperatureUnitTo(value, {
            unit: UnitTypes.SMALL,
            fixed: 1,
            showUnit: true
        })
    }

    ventilationFormatter = (value) => `${value.toFixed(1)}%`

    renderStages = ({fields}) => {
        const {t} = this.props;
        return (
            <>
                {
                    fields.map((member, index) => (
                        <Card key={index}>
                            <h5>{index < 1 ? t("newSettings.climate.curve.start") : `${t("newSettings.climate.curve.stage")} ${index}`}</h5>
                            <Row>
                                <Col md={6}>
                                    <Field
                                        name={`${member}.days`}
                                        id={`${member}.days`}
                                        label={t("numberOfDays")}
                                        component={ReduxLabeledSlider}
                                        min={validation.days.min[index]}
                                        max={validation.days.max[index]}
                                        step={1}
                                        disabled={!index}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Field
                                        name={`${member}.requestedTemperature`}
                                        id={`${member}.requestedTemperature`}
                                        label={t("requestedTemperature")}
                                        component={ReduxLabeledSlider}
                                        min={validation.requestedTemperature.min[index]}
                                        max={validation.requestedTemperature.max[index]}
                                        valueFormatter={this.temperatureFormatter}
                                        step={0.2}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Field
                                        name={`${member}.minimumVentilation`}
                                        id={`${member}.minimumVentilation`}
                                        label={t("minimumVentilation")}
                                        component={ReduxLabeledSlider}
                                        min={validation.minimumVentilation.min[index]}
                                        max={validation.minimumVentilation.max[index]}
                                        valueFormatter={this.ventilationFormatter}
                                        step={0.2}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Field
                                        name={`${member}.floor`}
                                        id={`${member}.floor`}
                                        label={t("settings.floor")}
                                        component={ReduxLabeledSlider}
                                        min={validation.floor.min[index]}
                                        max={validation.floor.max[index]}
                                        valueFormatter={this.ventilationFormatter}
                                        step={1}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    ))
                }
            </>
        )
    }

    render() {
        const {t, handleSubmit, stages, initialized, submitting} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                {
                    initialized &&
                    <>
                        <Card>
                            <Field
                                name={"name"}
                                id={"name"}
                                label={t("designation")}
                                component={ReduxLabeledInput}
                            />
                        </Card>
                        <Card>
                            <ClimateCurveChart stages={stages}/>
                        </Card>
                        <FieldArray name={"stages"} component={this.renderStages}/>
                        <ButtonGroup fixed renderInPortal={false}>
                            <Button isLoading={submitting} buttonStyle="round" buttonColor={"success"}
                                    icon={<i className="fas fa-save"/>}/>
                        </ButtonGroup>
                    </>
                }
            </form>
        );
    }

}

const submit = (values, dispatch, props) => {
    console.log(values, props);
    const {id, name, stages} = values;
    const {climateCurves, farm} = props;
    const setting = id ? climateCurves.find(set => set.SetID === id) : Setting.createSetting(SettingTypes.CLIMATE_SK3_CURVE, {
        Stages: []
    }).clone();
    stages.forEach((stage, i) => {
        Object.keys(stage).forEach(key => {
            const newKey = key.slice(0, 1).toUpperCase() + key.slice(1);
            if (!setting.SetData.Stages[i]) setting.SetData.Stages[i] = {};
            setting.SetData.Stages[i][newKey] = stage[key];
        })
    })
    setting.SetData.Name = name || "";

    console.log(setting, "DLDLD")
    const success = (resolve) => {
        resolve();
        dispatch(redirect(`/${farm}/settings/climate-sk3/curves`));
    }
    return new Promise((resolve, reject) => {
        if (id) {
            setting.setDtaModTime();
            dispatch(updateSettingDynamoDB(setting.prepareBeanToSave(), () => success(resolve), reject));

        } else {
            dispatch(createSettingDynamoDB(setting.prepareBeanToSave(), () => success(resolve), reject));
        }
    });

}

AddClimateCurveView = reduxForm({
    form: FormName,
    onSubmit: submit
})(AddClimateCurveView);

const selector = formValueSelector(FormName);

AddClimateCurveView = connect(state => ({
    user: state.user.user,
    climateCurves: getClimateCurves(state),
    stages: selector(state, "stages"),
    farm: state.location.farm,
}))(AddClimateCurveView);


AddClimateCurveView = withRoles({
    roles: [Roles._DEVICE_CLIMATE, Roles._DEVICE_CONFIG],
    showComponent: true
})(AddClimateCurveView);

export default withTranslation()(AddClimateCurveView);
