import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import LabeledInput from "../../../../../components/basics/input/labeled-input/LabeledInput";
import Button from "../../../../../components/basics/button/Button";
import CageWeightConfiguratorModal, {ModalName as CageWeightConfiguratorModalName} from "../../../../../components/modals-new/cage-weight-configurator/CageWeightConfiguratorModal";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import PropTypes from "prop-types";
import {setScaleFactor} from "../../../../../IOT/device-functions/SeparationCageFunctions";
import _ from "lodash";
import {SeparationCageCommandTypes} from "../../../../../constans/mqttMessages";
import {LoadedDataTypes} from "../../../../../constans/devices";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class WeightSettings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.getWeightSettings(),
            changed: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.changed) {
            this.setState({
                ...this.getWeightSettings(nextProps)
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    getWeightSettings(props = this.props) {
        let device = props.cages[0];
        try {
            let shadow = props.shadows.get(device.DevID);
            return {SD: shadow.configuration.scale.SD || 0, SW: shadow.configuration.scale.SW || 0};
        } catch (e) {
            return {
                ..._.get(device, `Settings.Configuration.${SeparationCageCommandTypes.SET_SCALE_FACTOR}`, {
                    SD: 0,
                    SW: 0
                }),
                SetTime: _.get(device, `Settings.Configuration.${SeparationCageCommandTypes.SET_SCALE_FACTOR}.SetTime`, 0),
                loadedDataFrom: device && device.Settings && device.Settings.Configuration && device.Settings.Configuration[SeparationCageCommandTypes.SET_SCALE_FACTOR] ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA,
                device
            };
        }
    }

    onSWChange = value => {
        this.setState({
            SW: +value,
            changed: true
        })
    };

    onSDChange = value => {
        this.setState({
            SD: +value,
            changed: true
        })
    };

    onConfigurationButtonClick = () => {
        this.props.show(CageWeightConfiguratorModalName, {});
    };

    onClick = () => {
        if (this.state.SW && this.state.SD) {
            setScaleFactor(this.props.cages, +this.state.SW, +this.state.SD);
        }
    };

    render() {
        const {t} = this.props;
        const {SW, SD, loadedDataFrom, SetTime, device} = this.state;
        return (
            <DeviceSettingsCard title={this.props.t("weightSettings")}
                                onButtonClick={this.onClick}>
                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[SeparationCageCommandTypes.SET_SCALE_FACTOR].isSet &&
                            <InfoBox
                                boxColor="warning">{i18next.t("newSettings.devices.settingsInfo.dynamo", {date: moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                <LabeledInput label={this.props.t("newSettings.cage.manage.weight.sw")} type="number" value={SW}
                              onChange={this.onSWChange}/>
                <LabeledInput label={this.props.t("newSettings.cage.manage.weight.sd")} type="number" value={SD}
                              onChange={this.onSDChange}/>
                <Button
                    onClick={this.onConfigurationButtonClick}>{this.props.t("newSettings.cage.manage.weight.configurator")}</Button>
                <CageWeightConfiguratorModal/>
            </DeviceSettingsCard>
        );
    }

}

WeightSettings.propTypes = {
    cages: PropTypes.array.isRequired
};

WeightSettings = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(WeightSettings);

WeightSettings = connect(state => ({
    shadows: state.shadows.shadows,
}))(WeightSettings);

export default withTranslation()(WeightSettings);