import React, {Component} from 'react';
import Template from "./Template";
import {
    setClimateSensorsCalibration,
    setTemperatureSensorsCalibration1,
    setTemperatureSensorsCalibration2,
    setTemperatureSensorsCalibration3,
    setTemperatureSensorsCalibration4,
    setTemperatureSensorsCalibration5
} from "../../../../../IOT/device-functions/ClimateFunctions";
import {percentageFormatter} from "./utils";
import {withTranslation} from "react-i18next";
import {groupDevicesByGatewayID} from "../../../../../utils/DevicesUtils";
import {DevType} from "../../../../../constans/devices";

class SensorsMenu extends Component {


    getModel = () => {
        const {t} = this.props;
        return [
            {
                name: t("deviceRows.chainFeeding.chainFeedingRow.sensor", {number: 1}),
                key: 'temperatureSensorsCalibration[0]',
                shadowKey: 'service.temperatureSensorsCalibration[0]',
                submitKey: "temperatureSensorsCalibration",
                min: 75,
                max: 125,
                step: 1,
                defaultValue: 100,
                formatter: percentageFormatter,
                submit: setTemperatureSensorsCalibration1
            },
            {
                name: t("deviceRows.chainFeeding.chainFeedingRow.sensor", {number: 2}),
                key: 'temperatureSensorsCalibration[1]',
                shadowKey: 'service.temperatureSensorsCalibration[1]',
                submitKey: "temperatureSensorsCalibration",
                min: 75,
                max: 125,
                step: 1,
                defaultValue: 100,
                formatter: percentageFormatter,
                submit: setTemperatureSensorsCalibration2
            },
            {
                name: t("deviceRows.chainFeeding.chainFeedingRow.sensor", {number: 3}),
                key: 'temperatureSensorsCalibration[2]',
                submitKey: "temperatureSensorsCalibration",
                shadowKey: 'service.temperatureSensorsCalibration[2]',
                min: 75,
                max: 125,
                step: 1,
                defaultValue: 100,
                formatter: percentageFormatter,
                submit: setTemperatureSensorsCalibration3
            },
            {
                name: t("deviceRows.chainFeeding.chainFeedingRow.sensor", {number: 4}),
                key: 'temperatureSensorsCalibration[3]',
                submitKey: "temperatureSensorsCalibration",
                shadowKey: 'service.temperatureSensorsCalibration[3]',
                min: 75,
                max: 125,
                step: 1,
                defaultValue: 100,
                formatter: percentageFormatter,
                submit: setTemperatureSensorsCalibration4
            },
            {
                name: t("deviceRows.chainFeeding.chainFeedingRow.sensor", {number: 5}),
                key: 'temperatureSensorsCalibration[4]',
                submitKey: "temperatureSensorsCalibration",
                shadowKey: 'service.temperatureSensorsCalibration[4]',
                min: 75,
                max: 125,
                step: 1,
                defaultValue: 100,
                formatter: percentageFormatter,
                submit: setTemperatureSensorsCalibration5
            }
        ]
    }

    submit = (values) => {
        const {climates} = this.props;
        console.log(values, "SensorsMenu submit");
        const devices = groupDevicesByGatewayID(climates);
        if (devices) {
            for (let deviceList of devices.values()) {
                setClimateSensorsCalibration(deviceList[DevType.CLIMATE_SK3], values);
            }
        } else {
            console.error("devices not found")
        }
    }

    render() {
        const model = this.getModel();
        const {climates, t} = this.props;
        return (
            <>
                <Template
                    climates={climates}
                    name={t("newSettings.chainFeeding.sensorWorkType.title")}
                    model={model}
                    onSubmit={this.submit}
                    form={"climate-sk3-sensors-menu"}
                />
            </>
        );
    }
}

export default withTranslation()(SensorsMenu);
