import React from "react";
import LabeledInput from "../../../../../components/basics/input/labeled-input/LabeledInput";
import {parseFarmMapFromSVG} from "../../../../../utils/FarmMapUtils";
import FarmMap from "../../../../../components/new-farm-map/FarmMap";
import {withRouter} from "react-router-dom";
import LabeledSelect from "../../../../../components/basics/select/labeled-select/LabeledSelect";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {myID} from "../../../../../libs/generateID";
import ManageDataProvider from "../../../../../components/new-farm-map/ManageDataProvider";
import Button from "../../../../../components/basics/button/Button";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import {getFlatLocations, getLocationID, getLocationName} from "../../../../../utils/BuildingUtils";
import devicesDB from "../../../../../database/devicesDB";
import buildingsDB from "../../../../../database/buildingsDB";
import {DevType} from "../../../../../constans/devices";
import {SettingTypes} from "../../../../../constans/settingTypes";
import Setting from "../../../../../beans/Setting";
import {updateSettingDynamoDB} from "../../../../../actions/settingsAction";
import {Col, Row} from "react-bootstrap";
import {getFlatDevices} from "../../../../../utils/DevicesUtils";
import _ from "lodash";
import withRoles from "../../../../../components/withRoles";
import {Roles} from "../../../../../constans/roles";
import settingsDB from "../../../../../database/settingsDB";
import ReduxLabeledInput from "../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import Card from "../../../../../components/basics/card/Card";
import {withTranslation} from "react-i18next";

const FormName = "settings-buildings-map-manage";

export class ManageFarmMap extends React.Component {

    constructor(props) {
        super(props);
        const {farm} = this.props;
        this.props.initialize({
            farmMap: parseFarmMapFromSVG(_.get(settingsDB.getSettingByID(`farm_map_${farm}`), "SetData.Levels[0]")),
            level: 0,
            selectedID: null,
            showOnly: "all"
            // newID: null,
        });
        let flatLocations = getFlatLocations({Buildings: buildingsDB.getAllBuildingsForFarm(farm) || []});
        let sectors = [];
        let buildings = [];
        let chambers = [];
        flatLocations.forEach(loc => {
            let tmp = {
                name: getLocationName(loc),
                value: getLocationID(loc)
            };
            if (loc.BgID) {
                buildings.push(tmp);
            } else if (loc.CID) {
                chambers.push(tmp);
            } else if (loc.SID) {
                sectors.push(tmp);
            }
        });
        this.state = {
            options: {
                devices: getFlatDevices(devicesDB.getDevicesInPlcmnt({Buildings: (buildingsDB.getAllBuildingsForFarm(farm) || [])}).filter(dev => dev.DevType === DevType.SCALE)).map(dev => ({
                    name: dev.device.Name,
                    value: _.isNil(dev.index) ? dev.device.DevID : `${dev.device.DevID}_${dev.index}`
                })),
                sectors,
                buildings,
                chambers
            }
        }
    };

//todo: skonczyc widoczek
    readFile = (evt) => {
        let files = evt.target.files;
        if (files) {
            for (let f of files) {
                let r = new FileReader();
                r.onload = ((f) => {
                    return (e) => {
                        let contents = _.cloneDeep(e.target.result);
                        this.props.change("farmMap", null);
                        this.props.change("farmMap", parseFarmMapFromSVG(contents));
                    };
                })(f);
                r.readAsText(f);
            }
        }
    };

    renderUpload = () => {
        const {t} = this.props;
        return (
            <LabeledInput id="upload-input" onChange={this.readFile} optionalProps={{multiple: false, accept: ".svg"}}
                          label={t("newSettings.buildings.farmMap.uploadMap")}
                          frName={t("newSettings.buildings.farmMap.fileReaderButtonName")}
                          type={"file"}/>
        )
    };

    handleItemClick = ({type, id, level}) => {
        const {change} = this.props;
        change("selectedID", id);
        change("selectedType", type);
    };

    onIDChange = (newID = myID()) => {
        const {farmMap, change, selectedID} = this.props;
        let updatedFarmMap = farmMap.cloneNode(true);
        console.log(updatedFarmMap);
        try {
            updatedFarmMap.getElementById(selectedID).id = newID;
            change("farmMap", updatedFarmMap);
            change("selectedID", newID);
        } catch (e) {
            console.error(e)
        }
    };

    onShowFilterChange = (value) => {
        const {change} = this.props;
        change("showOnly", value);
        change("selectedID", null);
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {level, farm} = this.props;
        if (level !== nextProps.level) {
            nextProps.change("farmMap", parseFarmMapFromSVG(_.get(settingsDB.getSettingByID(`farm_map_${farm}`), `SetData.Levels[${nextProps.level}]`)));
            document.getElementById("upload-input").value = '';
        }
    }

    render() {
        const {farmMap, selectedID, selectedType, handleSubmit, showOnly, t} = this.props;
        const {options} = this.state;
        return (
            <Card>
                <form onSubmit={handleSubmit}>
                    <Field
                        id="level"
                        name="level"
                        component={ReduxLabeledInput}
                        type={"number"}
                        label={t("level")}
                    />
                    {this.renderUpload()}
                    {
                        farmMap &&
                        <FarmMap showOnly={showOnly === "all" ? null : showOnly} farmMap={farmMap}
                                 onElementClick={this.handleItemClick}
                                 dataProvider={ManageDataProvider}>
                            <Row>
                                <Col md={6}>
                                    <LabeledSelect options={[
                                        {name: t("newSettings.buildings.farmMap.showAll"), value: "all"},
                                        {name: t("newSettings.buildings.farmMap.showChambers"), value: "chambers"},
                                        {name: t("newSettings.buildings.farmMap.showSectors"), value: "sectors"},
                                        {name: t("newSettings.buildings.farmMap.showBuildings"), value: "buildings"},
                                        {name: t("newSettings.buildings.farmMap.showDevices"), value: "devices"},
                                    ]} onChange={this.onShowFilterChange}
                                                   label={t("newSettings.buildings.farmMap.show")}
                                                   value={showOnly}/>
                                </Col>
                                <Col md={6}>
                                    <LabeledSelect options={options[selectedType] || []} readOnly={!selectedID}
                                                   onChange={(value) => this.onIDChange(value)}
                                                   label={selectedType === "devices" ? t("newSettings.buildings.farmMap.assignedDevice") : t("newSettings.buildings.farmMap.assignedLocation")}
                                                   value={selectedID ? selectedID : null}/>
                                </Col>
                            </Row>
                        </FarmMap>
                    }
                    <ButtonGroup fixed renderInPortal={false}>
                        <Button icon={<i className="fas fa-save"/>} buttonStyle={"round"} buttonColor={"success"}
                                disabled={!farmMap}/>
                    </ButtonGroup>
                </form>
            </Card>
        );
    }

}


function submit(values, dispatch, props) {
    const {farmMap, level} = values;
    const {farm} = props;
    console.log(farmMap);
    if (farmMap) {
        let data = settingsDB.getSettingByID(`farm_map_${farm}`);
        data = _.get(data, 'SetData.Levels', {});
        if (!_.isObject(data)) {
            data = {"0": data}
        }
        data = {
            ...data,
            [level]: farmMap.outerHTML
        };
        let map = Setting.createSetting(SettingTypes.FARM_MAP, {Levels: data});
        dispatch(updateSettingDynamoDB(map, () => {
                props.history.push("/");
            }
        ))
    }
}


ManageFarmMap = reduxForm({
    form: FormName,
    onSubmit: submit,
})(ManageFarmMap);

const selector = formValueSelector(FormName);
const mapStateToProps = state => {
    return {
        farmMap: selector(state, "farmMap"),
        level: selector(state, "level"),
        selectedID: selector(state, "selectedID"),
        showOnly: selector(state, "showOnly"),
        selectedType: selector(state, "selectedType"),
        farm: state.location.farm,
    }
};
ManageFarmMap = connect(mapStateToProps)(ManageFarmMap);
export const _ManageFarmMap = ManageFarmMap;
ManageFarmMap = withTranslation()(ManageFarmMap);
ManageFarmMap = withRouter(ManageFarmMap);
export default withRoles({roles: [Roles._DEVICE_CONFIG], showComponent: true})(ManageFarmMap);
