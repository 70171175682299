import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import {Field, FormSection, reduxForm} from "redux-form";
import ReduxLabeledSlider from "../../../../../components/basics/slider/labeled-slider/ReduxLabeledSlider";
import {calculateVentilation} from "../../../../../utils/DevicesUtils";
import {Col, Row} from "react-bootstrap";
import {submit} from "./ConfigurationSubmit"
import {isNil} from "lodash"
import {withTranslation} from "react-i18next";
import {convertTemperatureUnitTo} from "../../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../../constans/unitTypes";

export class Configuration extends React.Component {

    constructor(props) {
        super(props);

        this.props.initialize({
            requestedAirTemperature: 10,
            requestedTemperatureDeviation: 0,
            bandWidth: 1,
            ventilationConfiguration: {
                minimumVentilation: 0,
                maximumVentilation: 0,
                ventilationSuspension: 0,
                minimumVentilationDeviation: 0,
                maximumVentilationDeviation: 0
            },
            floors: [0, 0, 0],
            maximumHoldFor20Percent: 10,
            manual: 0,
            alarms: {
                minimumTemperatureAlarmDeviation: 0.2,
                maximumTemperatureAlarmDeviation: 2,
                outsideAlarmDeviation: 0.2,
                absoluteAlarm: 10
            },
            additionalHeatingVentilation: 10,
            heatingDeviation: 10,
            heatingWorkTime: 10,
            heatingBreakTime: 10,
            coolingConfiguration: {
                coolingEnableTemperature: 0,
                coolingWorkTime: 30,
                coolingBreakTime: 30,
                coolingMaximumHumidity: 0,
                coolingMinimumPressure: 0,
                coolingMaximumPressure: 0
            }
        })
    }

    onClick = () => {
        this.props.submit();
    };
    /*
        todo: w tym widoku nic sie nie zgadza, np jednostka dla czasu pracy jest w st. C., zakresy wydaja sie byc copy pastem... /
        zauwazone robiąc logi /
        jesli ktos natknie się na dokumentacje to prosze zmienic równiez formatter w log-details/custom-details/UpdateClimateConfigurationDetail /
        zalozone iszu https://projekty.fetura.com.pl/issues/1503 /
     */
    render() {
        const {t} = this.props;
        const temperatureFormatter = value => convertTemperatureUnitTo(value, {
            fixed: 1,
            unit: UnitTypes.SMALL,
            acceptNil: true
        })
        const ventilationFormatter = value => isNil(value) ? "-%" : `${calculateVentilation(value).toFixed(1)}%`;
        return (
            <DeviceSettingsCard title={"Konfiguracja ogólna"} onButtonClick={this.onClick}>
                <Row>
                    <Col md={6}>
                        <h5>Temperatura</h5>
                        <Field
                            name="requestedAirTemperature"
                            component={ReduxLabeledSlider}
                            label={t("settings.desiredTemp")}
                            min={10}
                            max={50}
                            step={0.2}
                            valueFormatter={temperatureFormatter}
                        />
                        <Field
                            name="requestedTemperatureDeviation"
                            component={ReduxLabeledSlider}
                            label={t("settings.desiredTempDeviation")}
                            min={-10}
                            max={10}
                            step={0.2}
                            valueFormatter={temperatureFormatter}
                        />
                        <hr/>
                        <h5>Wentylacja</h5>
                        <Field
                            name="bandWidth"
                            component={ReduxLabeledSlider}
                            label={t("settings.bandwithVenting")}
                            min={1}
                            max={10}
                            step={0.2}
                            valueFormatter={temperatureFormatter}
                        />
                        <FormSection name="ventilationConfiguration">
                            <Field
                                name="minimumVentilation"
                                component={ReduxLabeledSlider}
                                label={t("settings.minVenting")}
                                min={0}
                                max={180}
                                valueFormatter={ventilationFormatter}
                            />
                            <Field
                                name="maximumVentilation"
                                component={ReduxLabeledSlider}
                                label={t("settings.maxVenting")}
                                min={0}
                                max={190}
                                valueFormatter={ventilationFormatter}
                            />
                            <Field
                                name="ventilationSuspension"
                                component={ReduxLabeledSlider}
                                label={t("settings.stopVenting")}
                                min={0}
                                max={30}
                                step={10}
                                valueFormatter={value => !isNil(value) && value ? `${value} s` : ""}
                            />
                            <Field
                                name="minimumVentilationDeviation"
                                component={ReduxLabeledSlider}
                                label={t("settings.minVentingDeviation")}
                                min={0}
                                max={100}
                                valueFormatter={value => !isNil(value) && value ? `${calculateVentilation(value).toFixed(1)}%` : ""}
                            />
                            <Field
                                name="maximumVentilationDeviation"
                                component={ReduxLabeledSlider}
                                label={t("settings.maxVentingDeviation")}
                                min={0}
                                max={100}
                                valueFormatter={value => !isNil(value) && value ? `${calculateVentilation(value).toFixed(1)}%` : ""}
                            />
                        </FormSection>
                        <hr/>
                        <h5>Podłogi</h5>
                        <Field
                            name="floors[0]"
                            component={ReduxLabeledSlider}
                            label={t("settings.floor") + "1"}
                            min={0}
                            max={50}
                            step={0.2}
                            valueFormatter={temperatureFormatter}
                        />
                        <Field
                            name="floors[1]"
                            component={ReduxLabeledSlider}
                            label={t("settings.floor") + "2"}
                            min={0}
                            max={50}
                            step={0.2}
                            valueFormatter={temperatureFormatter}
                        />
                        <Field
                            name="floors[2]"
                            component={ReduxLabeledSlider}
                            label={t("settings.floor") + "3"}
                            min={0}
                            max={50}
                            step={0.2}
                            valueFormatter={temperatureFormatter}
                        />
                        <hr/>
                        <h5>Inne</h5>
                        <Field
                            name="maximumHoldFor20Percent"
                            component={ReduxLabeledSlider}
                            label={t("settings.holdFor20Percent")}
                            min={10}
                            max={50}
                            step={10}
                            valueFormatter={temperatureFormatter}
                        />
                        <Field
                            name="manual"
                            component={ReduxLabeledSlider}
                            label={t("settings.manualOperation")}
                            min={0}
                            max={100}
                            step={0.4}
                            valueFormatter={value => !isNil(value) && value ? `${value.toFixed(1)}%` : ""}
                        />
                    </Col>
                    <Col md={6}>
                        <FormSection name="alarms">
                            <h5>Alarmy</h5>
                            <Field
                                name="minimumTemperatureAlarmDeviation"
                                component={ReduxLabeledSlider}
                                label={t("settings.minTempAlarmDeviation")}
                                min={0.2}
                                max={4}
                                step={0.2}
                                valueFormatter={temperatureFormatter}
                            />
                            <Field
                                name="maximumTemperatureAlarmDeviation"
                                component={ReduxLabeledSlider}
                                label={t("settings.maxTempAlarmDeviation")}
                                min={2}
                                max={20}
                                step={0.2}
                                valueFormatter={temperatureFormatter}
                            />
                            <Field
                                name="outsideAlarmDeviation"
                                component={ReduxLabeledSlider}
                                label={t("settings.alarmDeviationFromTemp")}
                                min={0.2}
                                max={2}
                                step={0.2}
                                valueFormatter={temperatureFormatter}
                            />
                            <Field
                                name="absoluteAlarm"
                                component={ReduxLabeledSlider}
                                label={t("settings.absoluteAlarm")}
                                min={10}
                                max={50}
                                valueFormatter={temperatureFormatter}
                            />
                        </FormSection>
                        <hr/>
                        <h5>Ogrzewanie</h5>
                        <Field
                            name="additionalHeatingVentilation"
                            component={ReduxLabeledSlider}
                            label="Wentylacja nagrzewnicy"
                            min={10}
                            max={50}
                            step={0.2}
                            valueFormatter={temperatureFormatter}
                        />
                        <Field
                            name="heatingDeviation"
                            component={ReduxLabeledSlider}
                            label={t("settings.heatingDeviation")}
                            min={0.2}
                            max={6}
                            step={0.2}
                            valueFormatter={temperatureFormatter}
                        />
                        <Field
                            name="heatingWorkTime"
                            component={ReduxLabeledSlider}
                            label={t("settings.heaterOpTime")}
                            min={10}
                            max={50}
                            step={0.2}
                            valueFormatter={temperatureFormatter}
                        />
                        <Field
                            name="heatingBreakTime"
                            component={ReduxLabeledSlider}
                            label={t("settings.heaterBreakTime")}
                            min={10}
                            max={50}
                            step={0.2}
                            valueFormatter={temperatureFormatter}
                        />
                        <FormSection name="coolingConfiguration">
                            <hr/>
                            <h5>Chłodzenie</h5>
                            <Field
                                name="coolingEnableTemperature"
                                component={ReduxLabeledSlider}
                                label={t("settings.switchOnTemp")}
                                min={0}
                                max={50.6}
                                step={0.2}
                                valueFormatter={temperatureFormatter}
                            />
                            <Field
                                name="coolingWorkTime"
                                component={ReduxLabeledSlider}
                                label={t("settings.operatingTime")}
                                min={30}
                                max={600}
                                step={10}
                                valueFormatter={value => !isNil(value) && value ? `${value} s` : ""}
                            />
                            <Field
                                name="coolingBreakTime"
                                component={ReduxLabeledSlider}
                                label={t("settings.breakTime")}
                                min={30}
                                max={600}
                                step={10}
                                valueFormatter={value => !isNil(value) && value ? `${value} s` : ""}
                            />
                            <Field
                                name="coolingMaximumHumidity"
                                component={ReduxLabeledSlider}
                                label={t("settings.maxHumidity")}
                                min={0}
                                max={100}
                                valueFormatter={value => !isNil(value) && value ? `${value}%` : ""}
                            />
                            <Field
                                name="coolingMinimumPressure"
                                component={ReduxLabeledSlider}
                                label={t("settings.minPressure")}
                                min={0}
                                max={80}
                                valueFormatter={value => !isNil(value) && value ? `${value} atm` : ""}
                            />
                            <Field
                                name="coolingMaximumPressure"
                                component={ReduxLabeledSlider}
                                label={t("settings.maxPressuer")}
                                min={0}
                                max={80}
                                valueFormatter={value => !isNil(value) && value ? `${value} atm` : ""}
                            />
                        </FormSection>
                    </Col>
                </Row>
            </DeviceSettingsCard>
        );
    }

}

Configuration = reduxForm({
    form: "climateConfiguration",
    onSubmit: submit
})(Configuration);

Configuration = withTranslation()(Configuration);

export default Configuration;
