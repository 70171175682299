import React from "react";
import Loading from "../../../../components/loading/LoadingComponent";
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";
import NewIOT from "../../../../IOT/NewIOT";
import AttemptsNumber from "./settings/AttemptsNumber";
import ExitTime from "./settings/ExitTime";
import AverageWeightYesterday from "./settings/AverageWeightYesterday";
import DefaultExit from "./settings/DefaultExit";
import Taring from "./settings/Taring";
import ReturnToDefaultExit from "./settings/ReturnToDefaultExit";
import WorkTypeDurations from "./settings/WorkTypeDurations";
import WeightSettings from "./settings/WeightSettings";
import NumbersToExit from "./settings/NumbersToExit";

export class ManageCageConfiguration extends React.Component {

    state = {
        selectedCages: this.props.location.state ? this.props.location.state.selectedCages : [],
        loading: true
    };

    constructor(props) {
        super(props);
        if (this.state.selectedCages.length === 0) {
            this.props.history.push(`/${this.props.farm}/settings/cage/manage`)
        }
        NewIOT.startSendingDeviceState(this.state.selectedCages, this.setLoading, this.setLoading);
    }

    setLoading = () => {
        this.setState({
            loading: false
        })
    };

    render() {
        const {selectedCages, loading} = this.state;
        if (selectedCages.length === 0) return null;
        return (
            <div className={"overflow-hidden"} style={{position: "relative"}}>
                <Loading isLoading={loading}/>
                <Row>
                    <Col xl={4}>
                        <AttemptsNumber cages={selectedCages}/>
                    </Col>
                    <Col xl={4}>
                        <ExitTime cages={selectedCages}/>
                    </Col>
                    <Col xl={4}>
                        <AverageWeightYesterday cages={selectedCages}/>
                    </Col>
                </Row>
                <Row>
                    <Col xl={4}>
                        <DefaultExit cages={selectedCages}/>
                    </Col>
                    <Col xl={4}>
                        <Taring cages={selectedCages}/>
                    </Col>
                    <Col xl={4}>
                        <ReturnToDefaultExit cages={selectedCages}/>
                    </Col>
                </Row>
                <Row>
                    <Col xl={4}>
                        <WorkTypeDurations cages={selectedCages}/>
                    </Col>
                    <Col xl={4}>
                        <WeightSettings cages={selectedCages}/>
                    </Col>
                    <Col xl={4}>
                        <NumbersToExit cages={selectedCages}/>
                    </Col>
                </Row>
            </div>
        );
    }

}

ManageCageConfiguration = connect(state => ({
    farm: state.location.farm
}))(ManageCageConfiguration);

export default ManageCageConfiguration;