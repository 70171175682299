import React from "react";
import {Col, Row} from "react-bootstrap";
import {Field, formValueSelector, initialize, reduxForm} from "redux-form";
import {submit, validate, warning} from "./ChamberSubmit"
import FormForSettings from "../../../FormForSettings";
import ReduxLabeledInput from "../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import {cloneDeep, isEqual} from "lodash";
import ReduxSwitch from "../../../../../components/basics/switch/ReduxSwitch";
import {FilterTypes} from "../../../../../constans/filter";
import TableGrid from "../../../../../components/basics/table-grid/TableGrid";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import {bindActionCreators, compose} from "redux";
import {show} from "redux-modal";
import {deleteLocation, updateLocation} from "../../../../../actions/farmsActions";
import Button from "../../../../../components/basics/button/Button";
import ReduxItemPicker from "../../../../../components/basics/item-picker/ReduxItemPicker";
import SS0 from "../../../../../resources/images/standings-sorting/0.svg";
import SS1 from "../../../../../resources/images/standings-sorting/1.svg";
import SS2 from "../../../../../resources/images/standings-sorting/2.svg";
import SS3 from "../../../../../resources/images/standings-sorting/3.svg";
import SS4 from "../../../../../resources/images/standings-sorting/4.svg";
import SS5 from "../../../../../resources/images/standings-sorting/5.svg";
import SS6 from "../../../../../resources/images/standings-sorting/6.svg";
import SS7 from "../../../../../resources/images/standings-sorting/7.svg";
import {sortAsString} from "../../../../../utils/SortUtils";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import DeleteLocationIconComponent from "../mini-components/DeleteLocationIconComponent";
import {connect} from "react-redux";
import {getAllChildrenForLocationID} from "../../../../../utils/BuildingUtils";
import {makeGetDevicesInPlacement} from "../../../../../selectors/deviceSelector";
import * as DevTypes from "validators-schema/Api/constants/devTypes";
import Tooltip from "../../../../../components/basics/tooltip/Tooltip";

const FormName = 'settings-buildings-manage-chamber';
const selector = formValueSelector(FormName);

function makeMapStateToProps(state, props) {
    const {chamber} = props;
    const getDevicesInPlacement = makeGetDevicesInPlacement();
    const params = {
        PlcmntID: getAllChildrenForLocationID(chamber.CID),
        DevType: Object.keys(DevTypes).map((key) => DevTypes[key])
    };
    return function mapStateToProps(state) {
        const devices = getDevicesInPlacement(state, params);
        return {
            farm: state.location.farm,
            individualFeeding: selector(state, 'individualFeeding'),
            boxes: selector(state, "boxes"),
            allowDelete: !devices.length
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch)
    }
}

export class Chamber extends React.Component {

    componentDidMount() {
        Chamber.initializeForm(this.props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {chamber} = this.props;
        if (!isEqual(prevProps.chamber, chamber)) {
            Chamber.initializeForm(this.props);
        }
    }

    static initializeForm(props) {
        const {dispatch, chamber} = props;
        dispatch(initialize(FormName, {
            chamberName: chamber.CName,
            chamberSize: chamber.CSize,
            individualFeeding: !!chamber.IndividualFeeding,
            standsInRow: chamber.StandsInRow,
            standsOrder: chamber.StandsOrder !== undefined ? +chamber.StandsOrder : 1,
            boxes: chamber.Boxes
        }))
    }

    onDeleteChildrenClick = (params) => {
        const {value} = params;
        const {show, dispatch, t} = this.props;
        show("confirm-modal", {
            title: t("newSettings.buildings.manage.chamber.confirmDelete"),
            text: i18next.t("newSettings.buildings.manage.chamber.confirmDeleteSingleBox", {type: value.BoxesName}),
            confirmText: t("yes"),
            onConfirmed: (props) => dispatch(deleteLocation(value, () => props.handleHide()))
        })
    };

    onDeleteAllBoxesClick() {
        const {show, dispatch, t} = this.props;
        const chamber = cloneDeep(this.props.chamber);
        chamber.Boxes = [];
        show("confirm-modal", {
            title: t("newSettings.buildings.manage.chamber.confirmDelete"),
            text: i18next.t("newSettings.buildings.manage.chamber.confirmDeleteAllBoxes", {type: chamber.CName}),
            confirmText: t("yes"),
            onConfirmed: (props) => dispatch(updateLocation(chamber, () => props.handleHide()))
        })
    }

    onAddChildrenClick = () => {
        const {chamber, show} = this.props;
        show('settings-buildings-add-location-modal', {
            parent: chamber
        });
    };

    render() {
        const {handleSubmit, individualFeeding, boxes, chamber: {IndividualFeeding}, t, allowDelete} = this.props;
        const headers = [
            {
                name: t("newSettings.buildings.manage.chamber.boxesName"),
                field: "BoxesName",
                filterType: FilterTypes.STRING,
                customSort: sortAsString
            },
            {
                name: t("rfid"),
                field: "RFID",
                filterType: FilterTypes.STRING
            },
            {
                name: ' ',
                filterType: FilterTypes.STRING,
                component: props => <DeleteLocationIconComponent onClick={this.onDeleteChildrenClick.bind(this, props)} {...props}/>
            }
        ];
        return (
            <FormForSettings handleSubmit={handleSubmit}>
                <Row className="justify-content-center">
                    <Col lg={6}>
                        < Field
                            name="chamberName"
                            id="chamberName"
                            required
                            type="text"
                            component={ReduxLabeledInput}
                            label={t("newSettings.buildings.manage.chamber.chamberName")}
                        />
                        <Field
                            name="individualFeeding"
                            id="individualFeeding"
                            component={ReduxSwitch}
                            label={
                                <>
                                    {t("newSettings.buildings.manage.chamber.individualFeeding")}
                                    {
                                        !allowDelete && IndividualFeeding &&
                                        <Tooltip placement="auto"
                                                 tooltipContent={t("newSettings.buildings.manage.chamber.individualFeedingFailureMessage")}>
                                            <i className="fas fa-fw fa-info-circle ml-1"/>
                                        </Tooltip>
                                    }
                                </>
                            }
                            disabled={!allowDelete && IndividualFeeding}
                        />
                        {
                            !individualFeeding &&
                            <React.Fragment>
                                <Field
                                    name="chamberSize"
                                    id="chamberSize"
                                    required
                                    type="number"
                                    component={ReduxLabeledInput}
                                    label={t("newSettings.buildings.manage.chamber.chamberSize")}
                                />
                                {
                                    !isEqual(IndividualFeeding, individualFeeding) &&
                                    <InfoBox
                                        boxColor={"warning"}>{t("newSettings.buildings.manage.chamber.changingFeedingTypeDeletesStandings")}</InfoBox>
                                }
                            </React.Fragment>
                        }
                        {
                            individualFeeding &&
                            <React.Fragment>
                                <Field
                                    name="standsInRow"
                                    id="standsInRow"
                                    required
                                    type="number"
                                    component={ReduxLabeledInput}
                                    label={t("standingsInRow")}
                                />
                                <Field
                                    name="standsOrder"
                                    id="standsOrder"
                                    component={ReduxItemPicker}
                                    showLabels
                                    required
                                    itemsInRow={4}
                                    options={[{value: 0, svgPath: SS0, label: 'A1'}, {
                                        value: 1,
                                        svgPath: SS1,
                                        label: 'A2'
                                    }, {value: 2, svgPath: SS2, label: 'A3'}, {
                                        value: 3,
                                        svgPath: SS3,
                                        label: 'A4'
                                    }, {value: 4, svgPath: SS4, label: 'B1'}, {
                                        value: 5,
                                        svgPath: SS5,
                                        label: 'B2'
                                    }, {value: 6, svgPath: SS6, label: 'B3'}, {value: 7, svgPath: SS7, label: 'B4'}]}
                                    label={t("newSettings.buildings.manage.chamber.standsOrder")}
                                />
                                <label>{t('newSettings.buildings.manage.chamber.availableStandings')}{' '}
                                    {
                                        IndividualFeeding &&
                                        <Button type={"button"}
                                                buttonStyle={"round"}
                                                onClick={() => this.onAddChildrenClick()}
                                                icon={<i className={"fas fa-plus"}/>}
                                        />
                                    }
                                </label>
                                <TableGrid data={boxes || []} headers={headers}/>

                            </React.Fragment>
                        }
                    </Col>
                </Row>
            </FormForSettings>
        );
    }
}

export default compose(
    withTranslation(),
    connect(makeMapStateToProps, mapDispatchToProps),
    reduxForm({form: FormName, onSubmit: submit, validate, warning})
)(Chamber);

