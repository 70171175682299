import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import LabeledInput from "../../../../../components/basics/input/labeled-input/LabeledInput";
import PropTypes from "prop-types"
import {setAverageWeightYesterday} from "../../../../../IOT/device-functions/SeparationCageFunctions";
import _ from "lodash";
import {connect} from "react-redux";
import {convertWeightToBaseUnit, convertWeightUnitTo, getUnit} from "../../../../../utils/UnitUtils";
import {SeparationCageCommandTypes} from "../../../../../constans/mqttMessages";
import {LoadedDataTypes} from "../../../../../constans/devices";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class AverageWeightYesterday extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.getAverageWeight(),
            changed: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.changed) {
            this.setState(this.getAverageWeight(nextProps))
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    getAverageWeight(props = this.props) {
        let device = props.cages[0];
        try {
            let shadow = props.shadows.get(device.DevID);
            return {
                averageWeight: convertWeightUnitTo(shadow.averageWeights.yesterday, {
                    rawValue: true,
                    fixed: 0
                }),
                loadedDataFrom: LoadedDataTypes.SHADOW,
                SetTime: shadow.metadata.averageWeights,
                device
            };
        } catch (e) {
            return {
                averageWeight: convertWeightUnitTo(_.get(device, `Settings.Configuration.${SeparationCageCommandTypes.SET_AVERAGE_WEIGHT_YESTERDAY}.averageWeight`, 0), {
                    rawValue: true,
                    fixed: 0
                }),
                SetTime: _.get(device, `Settings.Configuration.${SeparationCageCommandTypes.SET_AVERAGE_WEIGHT_YESTERDAY}.SetTime`, 0),
                loadedDataFrom: device && device.Settings && device.Settings.Configuration && device.Settings.Configuration[SeparationCageCommandTypes.SET_AVERAGE_WEIGHT_YESTERDAY] ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA,
                device
            };
        }
    }

    onAverageWeightChange = value => {
        this.setState({
            averageWeight: value,
            changed: true
        })
    };

    onClick = () => {
        if (this.state.averageWeight) {
            setAverageWeightYesterday(this.props.cages, convertWeightToBaseUnit(+this.state.averageWeight));
        }
    };

    render() {
        const {t} = this.props;
        const {averageWeight, loadedDataFrom, SetTime, device} = this.state;
        return (
            <DeviceSettingsCard title={this.props.t("newSettings.cage.manage.averageWeightYesterday.title")}
                                onButtonClick={this.onClick}>
                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[SeparationCageCommandTypes.SET_AVERAGE_WEIGHT_YESTERDAY].isSet &&
                            <InfoBox
                                boxColor="warning">{i18next.t("newSettings.devices.settingsInfo.dynamo", {date: moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                <LabeledInput label={t("averageWeight")} type="number"
                              unit={getUnit("weight")}
                              value={averageWeight}
                              onChange={this.onAverageWeightChange}/>
            </DeviceSettingsCard>
        );
    }

}

AverageWeightYesterday.propTypes = {
    cages: PropTypes.array.isRequired
};

AverageWeightYesterday = connect(state => ({
    shadows: state.shadows.shadows,
}))(AverageWeightYesterday);

export default withTranslation()(AverageWeightYesterday);