import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import {Field, FormSection, formValueSelector, reduxForm} from "redux-form";
import ReduxLabeledSelect from "../../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import ReduxLabeledSlider from "../../../../../components/basics/slider/labeled-slider/ReduxLabeledSlider";
import ReduxSwitch from "../../../../../components/basics/switch/ReduxSwitch";
import {calculateVentilation} from "../../../../../utils/DevicesUtils";
import _ from "lodash"
import {connect} from "react-redux";
import {submit} from "./ServiceSubmit";
import {withTranslation} from "react-i18next";

export class Service extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            maxHeaters: 0,
            maxMasters: 0,
            maxCooling: false,
            maxFloorHeating: 0
        };

        this.props.initialize({
            chimneyVentilatorsCount: 1,
            heatersCount: 0,
            mastersCount: 0,
            cooling: false,
            floorHeating: 0,
            language: 0,
            ventilationIntensity: 0,
            ventilationCycleTime: 30,
            temperatureSensorsCalibration: [0, 0, 0, 0, 0],
            rotation55PercentCalibration: 0,
            minimumRotation: 1,
            objectDynamics: 3,
            analogOutputs: {
                firstAnalogOutputCurve: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                additionalAnalogOutputs: [
                    {
                        analogOutputNumber: 2,
                        minimumVoltage: 0,
                        maximumVoltage: 0
                    },
                    {
                        analogOutputNumber: 3,
                        minimumVoltage: 0,
                        maximumVoltage: 0
                    },
                    {
                        analogOutputNumber: 4,
                        minimumVoltage: 0,
                        maximumVoltage: 0
                    },
                    {
                        analogOutputNumber: 5,
                        minimumVoltage: 0,
                        maximumVoltage: 0
                    },
                    {
                        analogOutputNumber: 6,
                        minimumVoltage: 0,
                        maximumVoltage: 0
                    }
                ]
            },
            memoryLock: 3,
            heatingType: 2
        })
    }

    createTemperatureCalibration() {
        const {t} = this.props;
        let array = [];
        for (let i = 0; i < 5; i++) {
            array.push((<Field
                name={`temperatureSensorsCalibration[${i}]`}
                component={ReduxLabeledSlider}
                label={`${t("settings.tempSensor")} ${i + 1}`}
                min={-25}
                max={25}
                step={0.2}
                valueFormatter={value => !_.isNil(value) ? `${(+value).toFixed(1)}%` : ""}
            />))
        }
        return array;
    }

    createAnalogCurve = () => {
        const {t} = this.props;
        let array = [];
       const text = ["0% 0M", "2% 0M", "5% 0M", "10% 0M", "15% 0M", "20% 0M", "25% 0M", "35% 0M", "45% 0M", "70% 0M", "100% 0M", "100% 1M", "100% 2M", "100% 3M", "100% 4M"];
        for (let i = 0; i < 15; i++) {
            array.push((
                <Field
                    name={`firstAnalogOutputCurve[${i}]`}
                    component={ReduxLabeledSlider}
                   label={t("settings.pointX", {number: `${i+1} ${text[i]}`})}
                    min={0}
                    max={12}
                    step={0.1}
                    valueFormatter={value => !_.isNil(value) ? `${(+value).toFixed(1)}V` : ""}
                />
            ))
        }
        return array;
    }

    createAdditionalAnalogOutputs() {
        const {t} = this.props;
        let array = [];
        for (let i = 0; i < 5; i++) {
            array.push((
                <Field
                    name={`additionalAnalogOutputs[${i}].minimumVoltage`}
                    component={ReduxLabeledSlider}
                    label={`${t("settings.minVoltage")} ${i + 2} ${t("settings.analOut")}`}
                    valueFormatter={value => !_.isNil(value) ? `${(+value).toFixed(1)}V` : ""}
                />
            ));
            array.push((
                <Field
                    name={`additionalAnalogOutputs[${i}].maximumVoltage`}
                    component={ReduxLabeledSlider}
                    label={`Max. napięcie ${i + 2} wyjścia analogowego`}
                    valueFormatter={value => !_.isNil(value) ? `${(+value).toFixed(1)}V` : ""}
                />
            ))
        }
        return array;
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        let Grup = nextProps.chimneyVentilatorsCount - 1;
        let Gor = 0;
        let Gop;
        Grup = Grup > 3 ? 3 : Grup;
        if (nextProps.channelsExtensionsInstalled) {
            Gor = 12 - Grup;
        } else {
            Gor = 4 - Grup;
        }
        Gop = Gor;
        if (Gor > 3) {
            Gor = 3;
        }
        let maxHeaters = Gor;
        //następnie przed wpisaniem ilości masterów
        Gor = Gop - nextProps.heatersCount;
        Gop = Gor;
        if (Gor > 4) {
            Gor = 4;
        }
        let maxMasters = Gor;
        //następnie przed wpisaniem obecności chłodzenia
        Gor = Gop - nextProps.mastersCount;
        Gop = Gor;
        if (Gor > 1) {
            Gor = 1;
        }
        let maxCooling = Gor === 1;
        //następnie przed wpisaniem obecności ogrzewanych podłóg
        Gor = Gop - (nextProps.cooling ? 1 : 0);
        Gor = Gor + 1;
        if (Gor > 3) {
            Gor = 3;
        }
        let maxFloorHeating = Gor;
        this.setState({
            maxHeaters,
            maxMasters,
            maxCooling,
            maxFloorHeating
        });
        if (!maxCooling && (nextProps.cooling !== maxCooling)) {
            this.props.change("cooling", false);
        }
    }

    onButtonClick = () => {
        this.props.submit();
    };

    render() {
        const {heatingType, t} = this.props;
        const {maxHeaters, maxMasters, maxFloorHeating, maxCooling} = this.state;
        return (
            <DeviceSettingsCard title="Ustawienia serwisowe" onButtonClick={this.onButtonClick}>
                <h5>{t("settings.generalInstalationSet")}</h5>
                <Field
                    name="language"
                    component={ReduxLabeledSelect}
                    label={t("settings.language")}
                    options={[
                        {
                            name: "polski",
                            value: 0
                        },
                        {
                            name: "русский",
                            value: 1
                        }
                    ]}
                />
                <Field
                    name="chimneyVentilatorsCount"
                    component={ReduxLabeledSlider}
                    label={t("settings.chimneyFansAmount")}
                    min={1}
                    max={8}
                    valueFormatter={value => !_.isNil(value) ? value : ""}
                />
                <Field
                    name="heatersCount"
                    component={ReduxLabeledSlider}
                    label={t("settings.heatersAmount")}
                    min={0}
                    max={maxHeaters}
                    valueFormatter={value => !_.isNil(value) ? value : ""}
                />
                <Field
                    name="mastersCount"
                    component={ReduxLabeledSlider}
                    label={t("settings.mastersAmount")}
                    min={0}
                    max={maxMasters}
                    valueFormatter={value => !_.isNil(value) ? value : ""}
                />
                <Field
                    name="cooling"
                    id="cooling"
                    component={ReduxSwitch}
                    label={t("settings.cooling")}
                    disabled={!maxCooling}
                />
                <Field
                    name="heatingType"
                    component={ReduxLabeledSelect}
                    label={t("settings.heatingType")}
                    options={[
                        {
                            name: t("settings.floorType"),
                            value: 1
                        },
                        {
                            name: t("settings.waterType"),
                            value: 2
                        }
                    ]}
                />
                {
                    heatingType === 1 &&
                    <Field
                        name="floorHeating"
                        component={ReduxLabeledSlider}
                        label={t("settings.floorHeatingCircuitsAmount")}
                        min={0}
                        max={maxFloorHeating}
                        valueFormatter={value => !_.isNil(value) ? value : ""}
                    />
                }
                <Field
                    name="objectDynamics"
                    component={ReduxLabeledSlider}
                    label={t("settings.objectDynamics")}
                    min={3}
                    max={60}
                    step={0.5}
                    valueFormatter={value => value ? value + " min" : ""}
                />
                <hr/>
                <h5>{t("settings.venting")}</h5>
                <Field
                    name="ventilationIntensity"
                    component={ReduxLabeledSlider}
                    label={t("settings.ventilationIntensity")}
                    min={0}
                    max={100}
                    valueFormatter={value => !_.isNil(value) ? `${calculateVentilation(value)}%` : ""}
                />
                <Field
                    name="ventilationCycleTime"
                    component={ReduxLabeledSlider}
                    label={t("settings.ventilationCycleTime")}
                    min={30}
                    max={600}
                    valueFormatter={value => value ? value + " s" : ""}
                />
                <Field
                    name="minimumRotation"
                    component={ReduxLabeledSlider}
                    label={t("settings.minRotations")}
                    min={1}
                    max={100}
                    valueFormatter={value => value ? value + "%" : ""}
                />
                <hr/>
                <h5>{t("calibration")}</h5>
                <Field
                    name="rotation55PercentCalibration"
                    component={ReduxLabeledSlider}
                    label={t("settings.calibration55")}
                    min={-30}
                    max={40}
                    valueFormatter={value => !_.isNil(value) ? value + "%" : ""}
                />
                {this.createTemperatureCalibration()}
                <FormSection name="analogOutputs">
                    <hr/>
                    <h5>{t("settings.firstAnalOutCurve")}</h5>
                    {this.createAnalogCurve()}
                    <hr/>
                    <h5>{t("settings.analOuts")}</h5>
                    {this.createAdditionalAnalogOutputs()}
                </FormSection>
            </DeviceSettingsCard>
        );
    }

}

Service = reduxForm({
    form: "service",
    onSubmit: submit
})(Service);

const selector = formValueSelector("service");
export const _Service = connect(state => ({
    chimneyVentilatorsCount: selector(state, "chimneyVentilatorsCount"),
    heatersCount: selector(state, "heatersCount"),
    mastersCount: selector(state, "mastersCount"),
    channelsExtensionsInstalled: selector(state, "channelsExtensionsInstalled"),
    cooling: selector(state, "cooling"),
    heatingType: selector(state, "heatingType")
}))(Service);

Service = withTranslation()(_Service);

export default Service;
