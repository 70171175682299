import React from "react";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../components/basics/button/Button";
import Card from "../../../../components/basics/card/Card";
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import {connect} from "react-redux";
import {DevType} from "../../../../constans/devices";
import withRoles from "../../../../components/withRoles";
import {Roles} from "../../../../constans/roles";
import {checkIfUserCanManage} from "../../../../utils/NewRolesUtils";
import {manageDeviceHeaders} from "../../../../utils/DevicesUtils";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";

export class ManageCage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedCages: []
        }
    }

    onSelectionChange = selected => {
        this.setState({
            selectedCages: selected
        })
    };

    render() {
        const {devices, t, farm} = this.props;
        const headers = manageDeviceHeaders(t);
        const {selectedCages} = this.state;
        const userCanManage = checkIfUserCanManage();
        if (userCanManage) {
            return (
                <Card>
                    <TableGrid
                        data={devices.filter(item => (item.DevType === DevType.CAGE))}
                        headers={headers}
                        shouldIndex
                        selectableRow onSelectedRowsChanged={this.onSelectionChange} name={"manageCage"}/>
                    <ButtonGroup fixed renderInPortal={false}>
                        <Link to={{
                            pathname: `/${farm}/settings/cage/manage/configuration`,
                            state: {
                                selectedCages: selectedCages
                            }
                        }} disabled={selectedCages.length === 0}>
                            <Button buttonColor={"success"} buttonStyle={"round"}
                                    icon={<i className="fas fa-arrow-right"/>} disabled={selectedCages.length === 0}/>
                        </Link>
                    </ButtonGroup>
                </Card>
            );
        } else {
            return null;
        }
    }

}

ManageCage = connect(state => ({
    devices: state.farmDevices.devices,
    farm: state.location.farm
}))(ManageCage);
ManageCage = withRoles({
    roles: [Roles._DEVICE_CAGE, Roles._DEVICE_CONFIG],
    showComponent: true
})(ManageCage);

ManageCage = withTranslation()(ManageCage);

export default ManageCage;
