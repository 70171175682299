import devicesDB from "../../../../../database/devicesDB";
import {DevType} from "../../../../../constans/devices";
import {setPlcmnts} from "../../../../../IOT/device-functions/GatewayFunctions";
import Setting from "../../../../../beans/Setting";
import {SettingTypes} from "../../../../../constans/settingTypes";
import {createSettingDynamoDB, updateSettingDynamoDB} from "../../../../../actions/settingsAction";

export function submit(values, dispatch, props) {
    let gateways = devicesDB.getDevices(props.farm).filter(item => item.DevType === DevType.GATEWAY);

    let setting = values;

    if (!setting.SetID) {
        setting = Setting.createSetting(SettingTypes.DEVICE_SETTINGS, values.SetData);
        dispatch(createSettingDynamoDB(setting));
    } else {
        dispatch(updateSettingDynamoDB(setting));
    }

    for (let gateway of gateways) {
        setPlcmnts(gateway, setting.SetData.Plcmnts);
    }
}