import React from "react";
import Card from "../../../../../components/basics/card/Card";
import buildingsDB from "../../../../../database/buildingsDB";
import {connect} from "react-redux";
import {FieldArray, formValueSelector, reduxForm} from "redux-form";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../components/basics/button/Button";
import ReduxTableGrid from "../../../../../components/basics/table-grid/redux-table-grid/ReduxTableGrid";
import ReduxInput from "../../../../../components/basics/input/ReduxInput";
import {submit} from "./AssignChambersSubmit";
import _ from "lodash"
import animalsDB from "../../../../../database/animalsDB";
import {withTranslation} from "react-i18next";

export class AssignChambers extends React.Component {

    constructor(props) {
        super(props);

        let {deviceSetting} = props;
        let chambers = buildingsDB.getAllChambers(props.farm).filter(item => !item.IndividualFeeding)
        if (!deviceSetting) {
            deviceSetting = {
                SetData: {
                    Plcmnts: chambers.map(chamber => ({
                        PlcmntID: chamber.CID
                    }))
                }
            }
        } else {
            deviceSetting.SetData.Plcmnts = chambers.map(chamber => {
                let chamberInPlcmnts = deviceSetting.SetData.Plcmnts.find(item => item.PlcmntID === chamber.CID);
                if (chamberInPlcmnts) {
                    return {
                        ...chamberInPlcmnts
                    }
                }
                return {
                    PlcmntID: chamber.CID
                }
            })
        }

        this.props.initialize(_.cloneDeep(deviceSetting));
    }

    onAutoClick = () => {
        const {chambers} = this.props;
        let copy = chambers.slice(0);
        this.props.change("SetData.Plcmnts", copy.map((c, index) => ({
            ...c,
            ID: index + 1
        })));
    };

    locationValueFormatter = value => {
        let loc = animalsDB.getAnimalLocationsByPlcmntID(value);
        if (loc.length > 0) return loc[0].name;
    };

    render() {
        const {handleSubmit, t} = this.props;
        const headers = [
            {
                name: t("newSettings.buildings.assignChambers.chamber"),
                field: "PlcmntID",
                dontShowInput: true,
                valueFormatter: this.locationValueFormatter
            },
            {
                name: t("number"),
                field: "ID",
                component: ReduxInput,
                type: "number",
                parse: value => value === null ? null : +value
            }
        ]
        return (
            <Card>
                <Button onClick={this.onAutoClick}>{t("newSettings.buildings.assignChambers.auto")}</Button>
                <form onSubmit={handleSubmit}>
                    <FieldArray name="SetData.Plcmnts" component={ReduxTableGrid} headers={headers}/>
                    <ButtonGroup fixed renderInPortal={false}>
                        <Button buttonStyle={"round"} buttonColor={"success"} icon={<i className="fas fa-save"/>}/>
                    </ButtonGroup>
                </form>
            </Card>
        );
    }

}

AssignChambers = reduxForm({
    form: "assignChambers",
    onSubmit: submit
})(AssignChambers);

const selector = formValueSelector("assignChambers");
AssignChambers = connect(state => ({
    farm: state.location.farm,
    chambers: selector(state, "SetData.Plcmnts"),
    deviceSetting: state.settings.deviceSetting,
}))(AssignChambers);

export default withTranslation()(AssignChambers);