import React from "react";
import {Col, Row} from "react-bootstrap";
import {connect} from 'react-redux'
import TableGrid from "../../../../../components/basics/table-grid/TableGrid";
import {FilterTypes} from "../../../../../constans/filter";
import {bindActionCreators, compose} from "redux";
import {show} from "redux-modal";
import {deleteLocation} from "../../../../../actions/farmsActions";
import Button from "../../../../../components/basics/button/Button";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import {getCurrentFarm} from "../../../../../selectors/farmSelector";
import DeleteLocationIconComponent from "../mini-components/DeleteLocationIconComponent";

function mapStateToProps(state, props) {
    return {
        farm: getCurrentFarm(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch)
    }
}

class Farm extends React.Component {

    onDeleteChildrenClick = (params) => {
        const {value} = params;
        const {show, dispatch, t} = this.props;
        show("confirm-modal", {
            title: t("newSettings.buildings.manage.farm.confirmDelete"),
            text: i18next.t("newSettings.buildings.manage.farm.confirmDeleteLong", {type: value.BName}),
            confirmText: t("yes"),
            onConfirmed: (props) => dispatch(deleteLocation(value, () => props.handleHide()))
        })
    };

    onAddChildrenClick = () => {
        const {farm, show} = this.props;
        show('settings-buildings-add-location-modal', {
            parent: farm
        })
    };

    render() {
        const {buildings, t} = this.props;
        const headers = [
            {
                name: t("newSettings.buildings.manage.farm.buildingName"),
                field: "BName",
                filterType: FilterTypes.STRING
            },
            {
                name: ' ',
                filterType: FilterTypes.STRING,
                component: props => <DeleteLocationIconComponent onClick={this.onDeleteChildrenClick.bind(this, props)} {...props}/>
            }
        ];

        return (
            <Row className="justify-content-center">
                <Col lg={6}>
                    <label>{t("newSettings.buildings.manage.farm.availableBuildings")}
                        <Button type={"button"} buttonStyle={"round"} onClick={this.onAddChildrenClick} icon={<i className={"fas fa-plus"}/>}/>
                    </label>
                    <TableGrid data={buildings || []} headers={headers}/>
                </Col>
            </Row>
        );
    }

}

export default compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Farm);

