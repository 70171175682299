import _ from "lodash";
import {updateLocation} from "../../../../../actions/farmsActions";

export function submit({sectorName, sectorType}, dispatch, props) {
    console.log(props);
    const sector = _.cloneDeep(props.sector);
    sector.SName = sectorName;
    sector.SType = +sectorType;
    return dispatch(updateLocation(sector));

}

export function validate(values, props) {
    const errors = {};
    const {sectorName, sectorType} = values;
    const {t} = props;
    if (!sectorName) {
        errors.sectorName = t("required");
    }
    if (!sectorType || +sectorType <= 0) {
        errors.sectorType = t("required");
    }
    return errors;
}