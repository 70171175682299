import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {withFormik} from "formik";
import LabeledSlider from "../../../../../components/basics/slider/labeled-slider/LabeledSlider";
import Card from "../../../../../components/basics/card/Card";
import FormForSettings from "../../../FormForSettings";
import {Col, Row} from "react-bootstrap";
import {submit} from "./FeedingLevelsSubmit"
import {getFeedingLevels} from "../../../../../utils/SettingsUtils";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        ClientID: state.user.user.ClientID
    };
}

class FeedingLevels extends Component {
    // todo slidery zdupione, nie da sie ustawić plusikiem i minusikiem - trzeba coś z tym zrobić @Marcin
    onChangeMin = value => {
        const {setFieldValue} = this.props;
        setFieldValue("FeedingMinimumLevel", value);
    }

    onChangeMax = value => {
        const {setFieldValue} = this.props;
        setFieldValue("FeedingMaximumLevel", value);
    }

    valueFormatter = value => {
        return (value * 100).toFixed(0) + "%";
    }

    render() {
        const {t, values, handleSubmit} = this.props;
        return (
            <Card>
                <FormForSettings handleSubmit={handleSubmit}>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <LabeledSlider label={t("newSettings.breeding.feedingLevels.minimum")}
                                           value={values.FeedingMinimumLevel} step={.01} min={.1}
                                           max={values.FeedingMaximumLevel}
                                           onChange={this.onChangeMin} valueFormatter={this.valueFormatter}/>
                            <LabeledSlider label={t("newSettings.breeding.feedingLevels.maximum")}
                                           value={values.FeedingMaximumLevel} step={.01}
                                           min={values.FeedingMinimumLevel} max={1}
                                           onChange={this.onChangeMax} valueFormatter={this.valueFormatter}/>
                        </Col>
                    </Row>
                </FormForSettings>
            </Card>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withFormik({
        displayName: "feedingLevels",
        mapPropsToValues: () => getFeedingLevels(),
        handleSubmit: submit
    }),
)(FeedingLevels);