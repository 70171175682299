import React from "react";
import {Col, Row} from "react-bootstrap";
import {Field, initialize, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {submit, validate} from "./BoxSubmit"
import FormForSettings from "../../../FormForSettings";
import ReduxLabeledInput from "../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import _ from "lodash";
import {withTranslation} from "react-i18next";

const FormName = 'settings-buildings-manage-box';

@connect(state => ({
    farm: state.location.farm
}))
export class Box extends React.Component {

    componentDidMount() {
        this.initializeForm(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {sector} = this.props;
        if (!_.isEqual(sector, nextProps.sector)) {
            this.initializeForm(nextProps);
        }
    }

    initializeForm(props) {
        const {dispatch, box} = props;
        dispatch(initialize(FormName, {boxName: box.BoxesName, boxRFID: box.RFID,}))
    }

    render() {
        const {handleSubmit, t} = this.props;
        return (
            <Row className="justify-content-center">
                <Col lg={6}>
                    <FormForSettings handleSubmit={handleSubmit}>
                        <Field
                            name="boxName"
                            id="boxName"
                            type="text"
                            required
                            component={ReduxLabeledInput}
                            label={t("newSettings.buildings.manage.box.boxName")}
                        />
                        <Field
                            name="boxRFID"
                            id="boxRFID"
                            type="text"
                            component={ReduxLabeledInput}
                            label={t("newSettings.buildings.manage.box.boxRFID")}
                        />
                    </FormForSettings>
                </Col>
            </Row>
        );
    }

}

Box = reduxForm({
    form: FormName,
    onSubmit: submit,
    validate
})(Box);
Box = connect(state => ({
}))(Box);
export default withTranslation()(Box);

