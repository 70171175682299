import React from "react";
import {connect} from 'react-redux'
import Button from "../../../../../components/basics/button/Button";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import _ from "lodash";
import {getLocationName, updateDispenserRFIDListOnGateways} from "../../../../../utils/BuildingUtils";
import ListItem from "../../../../../components/basics/list/list-item/ListItem";
import {show} from "redux-modal";
import {bindActionCreators} from "redux";
import {ModalName as ConfirmModalName} from "../../../../../components/modals-new/confirm-modal/ConfirmModal";
import {updateDeviceDynamoDB} from "../../../../../actions/devicesActions";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

class DeviceItem extends React.Component {

    /**
     * Sprawdzenie czy  podane urządzenie jest podłączone do danej lokalizacji
     * @returns {boolean}
     */
    checkIfSelected() {
        try {
            const {location, device, index} = this.props;
            let id = location.BgID || location.SID || location.CID || location.BID;
            if (index !== undefined) {
                return device.PlcmntID.filter(item => item.PlcmntID === id && item.Adr === index).length > 0;
            }
            return device.PlcmntID.filter(item => item.PlcmntID === id).length > 0;
        } catch (e) {
            return false;
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.props, nextProps);
    }

    onButtonClick = () => {
        console.log(this.props);
        const {device, location, t, show, user, index} = this.props;
        let id = location.BgID || location.SID || location.CID || location.BID;
        let clone = device.clone();
        if (!this.checkIfSelected()) {
            clone.addLocation(id, index !== undefined ? index : null);
            if (clone.getLocation(index !== undefined ? index : null).length > 1) {
                show(ConfirmModalName, {
                    title: t("newSettings.buildings.pair.pairLocationView.confirmLink"),
                    text: i18next.t("newSettings.buildings.pair.pairLocationView.deviceExistsInAnotherLoc", {location: device.getLocation(index !== undefined ? index : null).map(loc => getLocationName(loc)).join(", ")}),
                    confirmText: t("yes"),
                    onConfirmed: (props) => {
                        this.props.dispatch(updateDeviceDynamoDB(clone.prepareBeanToSave(), clone.FarmID, user.ClientID, user.LocalUserID))
                        updateDispenserRFIDListOnGateways(location);
                        props.handleHide();
                    }
                })
            } else {
                this.props.dispatch(updateDeviceDynamoDB(clone.prepareBeanToSave(), clone.FarmID, user.ClientID, user.LocalUserID))
                updateDispenserRFIDListOnGateways(location);
            }
        } else {
            clone.removeLocation(id, index !== undefined ? index : null);
            show(ConfirmModalName, {
                title: t("newSettings.buildings.pair.pairLocationView.confirmUnlink"),
                text: t("newSettings.buildings.pair.pairLocationView.confirmUnlinkLong"),
                confirmText: t("yes"),
                onConfirmed: (props) => {
                    this.props.dispatch(updateDeviceDynamoDB(clone.prepareBeanToSave(), clone.FarmID, user.ClientID, user.LocalUserID))
                    updateDispenserRFIDListOnGateways(location);
                    props.handleHide();
                }
            })
        }
    };

    render() {
        const {device, location, index, updating, fetching, t, lp} = this.props;
        const selected = this.checkIfSelected();
        return (
            <ListItem index={lp} color={"#00afff"} className={"pair-device-item"}>
                <ButtonGroup>
                    <Button disabled={!location} isLoading={updating || fetching} type={"button"} className={"round"}
                            onClick={this.onButtonClick} buttonStyle={"text"}
                            icon={<i className={selected ? "fas fa-link fa-fw" : "fas fa-unlink fa-fw"}/>}
                            buttonColor={selected ? "success" : null}/>
                </ButtonGroup>
                <span>
                    <div>
                        <strong>{device.Name}</strong>{' '}
                    </div>
                    <div>
                        <small>{i18next.t("newSettings.buildings.pair.deviceItem.devAdrIndexAndSN", {
                            address: _.isFinite(device.Address) ? `${device.Address}/0x${device.Address.toString(16)}` : "-",
                            index: _.isNil(index) ? "-" : index,
                            device: device.DevID
                        })}</small>
                    </div>
                </span>
                <span className={"hide-on-small-device md"}>
                    <div>
                        {device.getLocation(index !== undefined ? index : null).map(loc => getLocationName(loc)).join(", ") || '-'}
                    </div>
                    <div>
                        <small>{t("placement")}</small>
                    </div>
                </span>
            </ListItem>

        );
    }

}

DeviceItem = connect(state => ({
    farm: state.location.farm,
    fetching: state.farmDevices.fetching,
    user: state.user.user
}))(DeviceItem);

DeviceItem = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(DeviceItem);

export default withTranslation()(DeviceItem);

