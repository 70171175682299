import React from "react";
import {Col, Row} from "react-bootstrap";
import {Field, formValueSelector, initialize, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {submit, validate} from "./SectorSubmit"
import FormForSettings from "../../../FormForSettings";
import ReduxLabeledInput from "../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import ReduxLabeledSelect from "../../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import _ from "lodash";
import TableGrid from "../../../../../components/basics/table-grid/TableGrid";
import {FilterTypes} from "../../../../../constans/filter";
import {deleteLocation} from "../../../../../actions/farmsActions";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import Button from "../../../../../components/basics/button/Button";
import {sortAsString} from "../../../../../utils/SortUtils";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import DeleteLocationIconComponent from "../mini-components/DeleteLocationIconComponent";

const FormName = 'settings-buildings-manage-sector';

export class Sector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            STypes: i18next.t("SType", {returnObjects: true})
        }
    }

    componentDidMount() {
        this.initializeForm(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {sector} = this.props;
        if (!_.isEqual(sector, nextProps.sector)) {
            this.initializeForm(nextProps);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.setState({
                STypes: i18next.t("SType", {returnObjects: true})
            })
        }
    }

    initializeForm(props) {
        const {dispatch, sector} = props;
        dispatch(initialize(FormName, {
            sectorName: sector.SName,
            sectorType: +sector.SType,
            chambers: sector.Chambers
        }))
    }

    onDeleteChildrenClick = (params) => {
        const {value} = params;
        const {show, dispatch, t} = this.props;
        show("confirm-modal", {
            title: t("newSettings.buildings.manage.sector.confirmDelete"),
            text: i18next.t("newSettings.buildings.manage.sector.confirmDeleteLong", {type: value.CName}),
            confirmText: t("yes"),
            onConfirmed: (props) => dispatch(deleteLocation(value, () => props.handleHide()))
        })
    };

    onAddChildrenClick = () => {
        const {sector, show} = this.props;
        show('settings-buildings-add-location-modal', {
            parent: sector
        })
    };

    feedingTypeFormatter = (value) => {
        const {t} = this.props;
        return value ? t("newSettings.buildings.manage.sector.individual") : t("newSettings.buildings.manage.sector.group");
    };

    chamberSizeFormatter = (value) => {
        return value.IndividualFeeding ? value.Boxes.length : value.CSize;
    };

    render() {
        const {handleSubmit, sector: {Chambers}, t} = this.props;
        let {STypes} = this.state;
        const sectorTypeOptions = [];
        STypes.forEach((type, index) => {
            sectorTypeOptions.push({
                name: type,
                value: index + 1
            })
        });
        const headers = [
            {
                name: t("newSettings.buildings.manage.sector.chamberName"),
                field: "CName",
                filterType: FilterTypes.STRING,
                customSort: sortAsString
            },
            {
                name: t("feeding"),
                field: "IndividualFeeding",
                filterType: FilterTypes.STRING,
                valueFormatter: this.feedingTypeFormatter
            },
            {
                name: t("newSettings.buildings.manage.sector.chamberSize"),
                filterType: FilterTypes.STRING,
                valueFormatter: this.chamberSizeFormatter
            },
            {
                name: ' ',
                filterType: FilterTypes.STRING,
                component: props => <DeleteLocationIconComponent onClick={this.onDeleteChildrenClick.bind(this, props)} {...props}/>
            }
        ];
        return (
            <Row className="justify-content-center">
                <Col lg={6}>
                    <FormForSettings handleSubmit={handleSubmit}>
                        <Field
                            name="sectorName"
                            id="sectorName"
                            required
                            type="text"
                            component={ReduxLabeledInput}
                            label={t("newSettings.buildings.manage.sector.sectorName")}
                        />
                        <Field
                            name="sectorType"
                            id="sectorType"
                            required
                            component={ReduxLabeledSelect}
                            options={sectorTypeOptions}
                            label={t("sectorType")}
                        />
                        <label>{t("newSettings.buildings.manage.sector.availableChambers")} <Button type={"button"}
                                                                                                    buttonStyle={"round"}
                                                                                                    onClick={() => this.onAddChildrenClick()}
                                                                                                    icon={<i
                                                                                                        className={"fas fa-plus"}/>}/></label>
                        <TableGrid data={Chambers || []} headers={headers}/>
                    </FormForSettings>
                </Col>
            </Row>
        );
    }

}

Sector = reduxForm({
    form: FormName,
    onSubmit: submit,
    validate
})(Sector);

Sector = connect(state => ({
    farm: state.location.farm
}))(Sector);
const selector = formValueSelector(FormName);
const mapStateToProps = state => {
    return {
        chambers: selector(state, 'chambers')
    }
};
Sector = connect(mapStateToProps)(Sector);
Sector = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(Sector);
export default withTranslation()(Sector);

