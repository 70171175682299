import React, {Component} from 'react';
import Template from "./Template";
import {
    setBandwidth,
    setChimneyVentilatorsCount,
    setClimateVentilation,
    setMastersCount,
    setMinimumRotation,
    setObjectDynamics,
    setVentilationCycleTime,
    setVentilationDelay,
    setVentilationIntensity
} from "../../../../../IOT/device-functions/ClimateFunctions";
import {minutesFormatter, percentageFormatter, secondsFormatter, temperatureFormatter} from "./utils";
import {withTranslation} from "react-i18next";
import {groupDevicesByGatewayID} from "../../../../../utils/DevicesUtils";
import {DevType} from "../../../../../constans/devices";

class VentilationMenu extends Component {


    getModel = () => {
        const {t} = this.props;
        return [
            {
                name: t("settings.ventilationIntensity"),
                key: 'ventilationIntensity',
                shadowKey: 'service.ventilationIntensity',
                min: 0,
                max: 50,
                step: 0.2,
                defaultValue: 10,
                formatter: temperatureFormatter,
                submit: setVentilationIntensity
            },
            {
                name: t("settings.ventilationCycleTime"),
                key: 'ventilationCycleTime',
                shadowKey: 'service.ventilationCycleTime',
                min: 60 * 1000,
                max: 240 * 1000,
                step: 10 * 1000,
                defaultValue: 120 * 1000,
                formatter: secondsFormatter,
                submit: setVentilationCycleTime
            },
            {
                name: t("settings.minRotations"),
                key: 'minimumRotation',
                shadowKey: 'service.minimumRotation',
                min: 10,
                max: 70,
                step: 1,
                defaultValue: 15,
                formatter: percentageFormatter,
                submit: setMinimumRotation
            },
            {
                name: t("settings.ventilationDelay"),
                key: 'ventilationDelay',
                shadowKey: 'service.ventilationDelay',
                min: 60 * 1000,
                max: 240 * 1000,
                step: 1000,
                defaultValue: 120 * 1000,
                formatter: secondsFormatter,
                submit: setVentilationDelay
            },
            {
                name: t("settings.mastersAmount"),
                key: 'mastersCount',
                shadowKey: 'service.mastersCount',
                min: 0,
                max: 3,
                step: 1,
                defaultValue: 1,
                submit: setMastersCount
            },
            {
                name: t("settings.chimneyFansAmount"),
                key: 'chimneyVentilatorsCount',
                shadowKey: 'service.chimneyVentilatorsCount',
                min: 0,
                max: 2,
                step: 1,
                defaultValue: 1,
                submit: setChimneyVentilatorsCount
            },
            {
                name: t("settings.objectDynamics"),
                key: 'objectDynamics',
                shadowKey: 'service.objectDynamics',
                min: 30 * 1000,
                max: 20 * 60 * 1000,
                step: 30 * 1000,
                defaultValue: 10 * 60 * 1000,
                formatter: minutesFormatter,
                submit: setObjectDynamics
            },
            {
                name: t("settings.bandwithVenting"),
                key: 'bandwidth',
                shadowKey: 'configuration.bandwidth',
                min: 0,
                max: 10,
                step: 0.2,
                defaultValue: 4,
                formatter: temperatureFormatter,
                submit: setBandwidth
            }
        ]
    }

    submit = (values) => {
        const {climates} = this.props;
        console.log(values, "VentilationMenu submit");
        const devices = groupDevicesByGatewayID(climates);
        if (devices) {
            for (let deviceList of devices.values()) {
                setClimateVentilation(deviceList[DevType.CLIMATE_SK3], values);
            }
        } else {
            console.error("devices not found")
        }
    }

    render() {
        const model = this.getModel();
        const {climates, t} = this.props;
        return (
            <>
                <Template
                    climates={climates}
                    name={t("settings.ventilationSettings")}
                    model={model}
                    onSubmit={this.submit}
                    form={"climate-sk3-ventilation-menu"}
                />
            </>
        );
    }
}

export default withTranslation()(VentilationMenu);
