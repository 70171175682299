import React from "react";
import {getLocationID, getLocationName} from "../../../../../utils/BuildingUtils";
import {DevType} from "../../../../../constans/devices";
import {connect} from "react-redux";
import ListItem from "../../../../../components/basics/list/list-item/ListItem";
import {getNumberOfPairsInLocation} from "../../../../../utils/DevicesUtils";
import {ButtonGroup, Collapse} from "react-bootstrap";
import List from "../../../../../components/basics/list/List";
import DeviceItem from "./DeviceItem";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {ModalName as PairStandingsModal} from "../../../../../components/modals-new/standings-pair-devices/StandingsPairDeviceModal";
import devicesDB from "../../../../../database/devicesDB";
import Button from "../../../../../components/basics/button/Button";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export default class LocationItem extends React.Component {

    getDevicesSize = _.memoize((devices, location) => {
        let cages = 0,
            climates = 0,
            dispensers = 0,
            scalesObj = [],
            antennas = 0;

        devices.forEach(device => {
            switch (device.DevType) {
                case DevType.CAGE:
                    cages += 1;
                    break;
                case DevType.CLIMATE:
                    climates += 1;
                    break;
                case DevType.DISPENSER_NRF:
                    dispensers += 1;
                    break;
                case DevType.DISPENSER:
                    dispensers += 1;
                    break;
                case DevType.ANTENNA_RFID:
                    antennas += 1;
                    break;
                case DevType.SCALE:
                    scalesObj.push(device);
                    break;
                default:
                    break;
            }
        });

        let scalesAmount = getNumberOfPairsInLocation(scalesObj, location);

        return {
            climates,
            cages,
            dispensers,
            scales: scalesAmount,
            antennas
        }

    }, (...args) => JSON.stringify(args));

    constructor(props) {
        super(props);
        this.state = {
            collapse: false
        }
    }

    onItemClick = () => {
        this.setState(state => ({
            collapse: !state.collapse
        }))
    };

    renderDispensersAdd = () => {
        const {location, t} = this.props;
        if (location && location.CID && location.IndividualFeeding) {
            let linked = [];
            for (let box of location.Boxes) {
                let devices = devicesDB.getDevicesInPlcmnt(box);
                if (devices.length > 0) linked.push(devices[0]);
            }
            return (
                <div>
                    <ListItem className={"pair-device-item"} fixedWidth>
                        <ButtonGroup>
                            <Button buttonStyle={"round"} icon={<i className="fas fa-list-ol"/>}
                                    onClick={() => {
                                        const {show} = this.props;
                                        show(PairStandingsModal, {
                                            chamber: location
                                        })
                                    }}/>
                        </ButtonGroup>
                        <span>
                        <div>
                             <strong>{t("newSettings.buildings.pair.locationItem.manageDispensers")}</strong>
                        </div>
                        <div>
                             <small>{i18next.t("newSettings.buildings.pair.locationItem.linkedBoxes", {
                                 amount1: linked.length,
                                 amount2: location.Boxes.length
                             })}</small>
                        </div>
                    </span>
                    </ListItem>
                </div>
            )
        } else {
            return null
        }
    };

    render() {
        const {location, selectedLocation, t, handleOnClick, devices, devicesMap = new Map()} = this.props;
        const {collapse} = this.state;
        const _devices = devicesMap.get(getLocationID(location)) || [];
        const {climates, scales, cages, antennas, dispensers} = this.getDevicesSize(_devices, location);
        return (
            <div>
                <ListItem selected={getLocationID(location) === getLocationID(selectedLocation)} onClick={() => {
                    handleOnClick(location);
                    this.onItemClick();
                }}>
                    <div>
                        <span>
                            <div>
                                <strong>{getLocationName(location)}</strong>
                            </div>
                                <small>{t("newSettings.buildings.pair.locationItem.cages", {number: cages})}, {t("newSettings.buildings.pair.locationItem.climates", {number: climates})}, {t("newSettings.buildings.pair.locationItem.dispensers", {number: dispensers})}, {t("newSettings.buildings.pair.locationItem.scales", {number: scales})}, {t("newSettings.buildings.pair.locationItem.antennas", {number: antennas})}</small>
                        </span>
                    </div>
                    <i className={`fas fa-arrow-${collapse && devices.length ? "up" : "down"}`}/>
                </ListItem>
                {
                    _.isEqual(location, selectedLocation) &&
                    <Collapse in={collapse} mountOnEnter unmountOnExit>
                        <div>
                            <List striped>
                                {collapse && this.renderDispensersAdd()}
                                {
                                    devices.map((device, key) => (
                                        <DeviceItem lp={key} key={key} device={device.device} index={device.index}
                                                    location={location}/>
                                    ))
                                }
                            </List>
                        </div>
                    </Collapse>
                }
            </div>
        );
    }

}

export const _LocationItem = LocationItem;

LocationItem = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(LocationItem);

LocationItem = withTranslation()(LocationItem);



