import React, {Component} from 'react';
import Template from "./Template";
import {
    setAbsoluteAlarm,
    setClimateAlarms,
    setMaximumTemperatureAlarmDeviation,
    setMinimumTemperatureAlarmDeviation
} from "../../../../../IOT/device-functions/ClimateFunctions";
import {temperatureFormatter} from "./utils";
import {withTranslation} from "react-i18next";
import {groupDevicesByGatewayID} from "../../../../../utils/DevicesUtils";
import {DevType} from "../../../../../constans/devices";

class AlarmsMenu extends Component {


    getModel = () => {
        const {t} = this.props;
        return [
            {
                name: t("settings.minTempAlarmDeviation"),
                key: 'minimumTemperatureAlarmDeviation',
                shadowKey: 'configuration.alarms.minimumTemperatureAlarmDeviation',
                min: 0.2,
                max: 20,
                step: 0.2,
                defaultValue: 4,
                formatter: temperatureFormatter,
                submit: setMinimumTemperatureAlarmDeviation
            },
            {
                name: t("settings.maxTempAlarmDeviation"),
                key: 'maximumTemperatureAlarmDeviation',
                shadowKey: 'configuration.alarms.maximumTemperatureAlarmDeviation',
                min: 2,
                max: 20,
                step: 0.2,
                defaultValue: 10,
                formatter: temperatureFormatter,
                submit: setMaximumTemperatureAlarmDeviation
            },
            {
                name: t("settings.absoluteAlarm"),
                key: 'absoluteAlarm',
                shadowKey: 'configuration.alarms.absoluteAlarm',
                min: 20,
                max: 55,
                step: 1,
                defaultValue: 35,
                formatter: temperatureFormatter,
                submit: setAbsoluteAlarm
            }
        ]
    }

    submit = (values) => {
        const {climates} = this.props;
        console.log(values, "AlarmsMenu submit");
        const devices = groupDevicesByGatewayID(climates);
        if (devices) {
            for (let deviceList of devices.values()) {
                setClimateAlarms(deviceList[DevType.CLIMATE_SK3], values);
            }
        } else {
            console.error("devices not found")
        }
    }

    render() {
        const model = this.getModel();
        const {climates, t} = this.props;
        return (
            <>
                <Template
                    climates={climates}
                    name={t("deviceRows.waterFlowMeter.waterTab.alarms")}
                    model={model}
                    onSubmit={this.submit}
                    form={"climate-sk3-alarms-menu"}
                />
            </>
        );
    }
}

export default withTranslation()(AlarmsMenu);
