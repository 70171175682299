export function submit(values, dispatch, props) {
    const {firstWeight, secondWeight} = values;
    const efficiency = ((+firstWeight) + (+secondWeight)) / 2;
    props.onNextClick({weights: values, efficiency: efficiency});
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;
    const checkValue = (value) => (value > 2550) || (value < 30);
    if (!values.firstWeight) {
        errors.firstWeight = t("required");
    } else if (checkValue(+values.firstWeight)) {
        errors.firstWeight = t("errors.mustBeInRange", {start: 30, end: 2550});
    }
    if (!values.secondWeight) {
        errors.secondWeight = t("required");
    } else if (checkValue(+values.firstWeight)) {
        errors.secondWeight = t("errors.mustBeInRange", {start: 30, end: 2550});
    }
    return errors;
}
