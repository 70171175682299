import React from "react";
import {Col, Row} from "react-bootstrap";
import {Field, formValueSelector, initialize, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {submit, validate} from "./BuildingSubmit"
import FormForSettings from "../../../FormForSettings";
import ReduxLabeledInput from "../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import _ from "lodash";
import TableGrid from "../../../../../components/basics/table-grid/TableGrid";
import {FilterTypes} from "../../../../../constans/filter";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import {deleteLocation} from "../../../../../actions/farmsActions";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import Button from "../../../../../components/basics/button/Button";
import {sortAsString} from "../../../../../utils/SortUtils";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import DeleteLocationIconComponent from "../mini-components/DeleteLocationIconComponent";


const FormName = 'settings-buildings-manage-building';

@connect(state => ({
    farm: state.location.farm,
    updating: state.farms.updating
}))
export class Building extends React.Component {

    constructor(props) {
        super(props);
        this.initializeForm(this.props);
        this.state = {
            STypes: i18next.t("SType", {returnObjects: true})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.setState({
                STypes: i18next.t("SType", {returnObjects: true})
            })
        }
        const {building} = this.props;
        if (!_.isEqual(building, prevProps.building)) {
            console.log("Buildinzzzzg:",building);
            this.initializeForm(this.props);
        }
    }


    initializeForm(props) {
        const {dispatch, building} = props;
        dispatch(initialize(FormName, {buildingName: building.BName, sectors: building.Sectors}))
    }

    sectorTypeFormatter = (value) => {
        const {STypes} = this.state;
        return STypes[value - 1] || value;
    };

    onDeleteChildrenClick = (params) => {
        const {value} = params;
        const {show, dispatch, t} = this.props;
        show("confirm-modal", {
            title: t("newSettings.buildings.manage.building.deleteSector"),
            text: i18next.t("newSettings.buildings.manage.building.deleteSectorLong", {type: value.SName}),
            confirmText: t("yes"),
            onConfirmed: (props) => dispatch(deleteLocation(value, () => props.handleHide()))
        })
    };

    onAddChildrenClick = () => {
        const {building, show} = this.props;
        show('settings-buildings-add-location-modal', {
            parent: building
        })
    };

    render() {
        const {handleSubmit, sectors, building: {Sectors}, updating, t} = this.props;
        const headers = [
            {
                name: t("newSettings.buildings.manage.building.sectorName"),
                field: "SName",
                filterType: FilterTypes.STRING,
                customSort: sortAsString
            },
            {
                name: t("sectorType"),
                field: "SType",
                filterType: FilterTypes.STRING,
                valueFormatter: this.sectorTypeFormatter
            },
            {
                name: ' ',
                filterType: FilterTypes.STRING,
                component: props => <DeleteLocationIconComponent onClick={this.onDeleteChildrenClick.bind(this, props)} {...props}/>
            }
        ];
        return (
            <FormForSettings handleSubmit={handleSubmit} submitting={updating}>
                <Row className="justify-content-center">
                    <Col lg={6}>
                        <Field
                            name="buildingName"
                            id="buildingName"
                            required
                            type="text"
                            component={ReduxLabeledInput}
                            label={t("newSettings.buildings.manage.building.buildingName")}
                        />
                        <hr/>
                        {
                            !_.isEqual(Sectors, sectors) &&
                            <InfoBox
                                boxColor={"warning"}>{t("newSettings.buildings.manage.building.deleteSectorWarning")}</InfoBox>
                        }
                        <label>{t("newSettings.buildings.manage.building.availableSectors")} <Button type={"button"}
                                                                                                     buttonStyle={"round"}
                                                                                                     onClick={() => this.onAddChildrenClick()}
                                                                                                     icon={<i
                                                                                                         className={"fas fa-plus"}/>}/></label>
                        <TableGrid data={sectors || []} headers={headers}/>
                    </Col>
                </Row>
            </FormForSettings>
        );
    }

}

Building = reduxForm({
    form: FormName,
    onSubmit: submit,
    validate
})(Building);

const selector = formValueSelector(FormName);
const mapStateToProps = state => {
    return {
        sectors: selector(state, 'sectors')
    }
};
Building = connect(mapStateToProps)(Building);
Building = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(Building);
export default withTranslation()(Building);

