import React from "react";
import Loading from "../../../../components/loading/LoadingComponent";
import {connect} from "react-redux";
import NewIOT from "../../../../IOT/NewIOT";
import Configuration from "./settings/Configuration";
import ClockMenu from "./settings/ClockMenu";
import Service from "./settings/Service";
import {ClimateDriverCommandTypes} from "../../../../constans/mqttMessages";
import {initialize} from "redux-form";
import moment from "moment";
import _ from "lodash";
import {checkIfUserIsService} from "../../../../utils/NewRolesUtils";

export class ManageClimatesConfiguration extends React.Component {

    state = {
        selectedClimates: this.props.location.state ? this.props.location.state.selectedClimates : [],
        loading: true
    };

    constructor(props) {
        super(props);

        if (this.state.selectedClimates.length === 0) {
            this.props.history.push(`/${this.props.farm}/settings/climate/manage`)
        }
        NewIOT.startSendingDeviceState(this.state.selectedClimates, this.setLoading, this.onFailure);
    }

    setLoading = msg => {
        let data = null;
        try {
            data = _.values(msg.CAnsw)[0];
            this.props.dispatch(initialize("climateConfiguration", {
                ...data.configuration,
                requestedTemperatureDeviation: data.configuration.requestedTemperatureDeviation - 10
            }));
            this.props.dispatch(initialize("clockMenu", {
                ...data.climateCurve,
                curveActive: data.climateCurve.currentCurveDay !== 0,
                recorderEraseTime: moment(data.climateCurve.recorderEraseTime).format("HH:mm")
            }));
            this.props.dispatch(initialize("service", {
                ...data.service,
                channelsExtensionsInstalled: data.ambientConditions.channelsExtensionsInstalled,
                heatingType: data.service.floorHeating === 4 ? 2 : 1
            }));
        } catch (e) {
            console.error(e);
        }
        this.setState({
            loading: false,
        })
    };

    onFailure = () => {
        let climate = this.state.selectedClimates[0];
        try {
            if (climate.Settings.Configuration && climate.Settings.Configuration[ClimateDriverCommandTypes.SET_CONFIGURATION]) {
                let requestedTemperatureDeviation = climate.Settings.Configuration[ClimateDriverCommandTypes.SET_CONFIGURATION].requestedTemperatureDeviation;
                this.props.dispatch(initialize("climateConfiguration", {
                    ...climate.Settings.Configuration[ClimateDriverCommandTypes.SET_CONFIGURATION],
                    requestedTemperatureDeviation: requestedTemperatureDeviation ? (requestedTemperatureDeviation - 10) : 0
                }));
            }
            if (climate.Settings.Configuration && climate.Settings.Configuration[ClimateDriverCommandTypes.SET_CLOCK_MENU]) {
                this.props.dispatch(initialize("clockMenu", {
                    ...climate.Settings.Configuration[ClimateDriverCommandTypes.SET_CLOCK_MENU],
                    curveActive: climate.Settings.Configuration[ClimateDriverCommandTypes.SET_CLOCK_MENU].currentCurveDay !== 0,
                    recorderEraseTime: moment(climate.Settings.Configuration[ClimateDriverCommandTypes.SET_CLOCK_MENU].recorderEraseTime).format("HH:mm")
                }));
            }
            if (climate.Settings.Configuration && climate.Settings.Configuration[ClimateDriverCommandTypes.SET_SERVICE_MENU]) {
                this.props.dispatch(initialize("service", {
                    ...climate.Settings.Configuration[ClimateDriverCommandTypes.SET_SERVICE_MENU],
                    channelsExtensionsInstalled: climate.Settings.Configuration[ClimateDriverCommandTypes.SET_SERVICE_MENU].channelsExtensionsInstalled,
                    heatingType: climate.Settings.Configuration[ClimateDriverCommandTypes.SET_SERVICE_MENU].floorHeating === 4 ? 2 : 1
                }));
            }
        } catch (e) {
            console.error(e);
        }
        this.setState({
            loading: false,
        })
    };

    render() {
        const {selectedClimates, loading} = this.state;
        if (selectedClimates.length === 0) return null;
        const isService = checkIfUserIsService();
        return (
            <div style={{position: "relative"}}>
                <Loading isLoading={loading}/>
                <Configuration climates={selectedClimates}/>
                <ClockMenu climates={selectedClimates}/>
                {
                    isService && <Service climates={selectedClimates}/>
                }
            </div>
        );
    }

}

ManageClimatesConfiguration = connect(state => ({
    farm: state.location.farm
}))(ManageClimatesConfiguration);

export default ManageClimatesConfiguration;
