import React from "react";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../components/basics/button/Button";
import Card from "../../../../components/basics/card/Card";
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import {connect} from "react-redux";
import {DevType} from "../../../../constans/devices";
import withRoles from "../../../../components/withRoles";
import {Roles} from "../../../../constans/roles";
import {checkIfUserCanManage} from "../../../../utils/NewRolesUtils";
import {manageDeviceHeaders} from "../../../../utils/DevicesUtils";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {memoize} from 'lodash';
import DefaultMobileRow from "../../../../components/basics/table-grid/default-mobile-row/DefaultMobileRow";

export class ManageChainFeeding extends React.Component {

    getChainFeeding = memoize((devices = []) => {
        return devices.filter(device => device.DevType === DevType.CHAIN_FEEDING);
    });

    constructor(props) {
        super(props);
        this.state = {
            selectedDevices: []
        }
    }

    onSelectionChange = selected => {
        this.setState({
            selectedDevices: selected
        })
    };

    render() {
        const {devices, t, farm} = this.props;
        const headers = manageDeviceHeaders(t);
        const {selectedDevices} = this.state;
        const userCanManage = checkIfUserCanManage();
        if (userCanManage) {
            return (
                <Card>
                    <TableGrid
                        data={this.getChainFeeding(devices)}
                        headers={headers}
                        shouldIndex
                        mobileRow={<DefaultMobileRow/>}
                        selectableRow onSelectedRowsChanged={this.onSelectionChange} name={"manageChainFeeding"}/>
                    <ButtonGroup fixed renderInPortal={false}>
                        <Link to={{
                            pathname: `/${farm}/settings/chainFeeding/manage/configuration`,
                            state: {
                                selectedDevices: selectedDevices
                            }
                        }} disabled={selectedDevices.length === 0}>
                            <Button buttonColor={"success"} buttonStyle={"round"}
                                    icon={<i className="fas fa-arrow-right"/>} disabled={selectedDevices.length === 0}/>
                        </Link>
                    </ButtonGroup>
                </Card>
            );
        } else {
            return null;
        }
    }

}

ManageChainFeeding = connect(state => ({
    devices: state.farmDevices.devices,
    farm: state.location.farm
}))(ManageChainFeeding);
ManageChainFeeding = withRoles({
    roles: [Roles._DEVICE_CHAIN, Roles._DEVICE_CONFIG],
    showComponent: true
})(ManageChainFeeding);

ManageChainFeeding = withTranslation()(ManageChainFeeding);

export default ManageChainFeeding;
