import _ from "lodash";
import {updateLocation} from "../../../../../actions/farmsActions";
import {addZerosToRfid} from "../../../../../utils/AnimalsUtils";

export function submit({boxName, boxRFID}, dispatch, props) {
    console.log(props);
    const box = _.cloneDeep(props.box);
    box.BoxesName = boxName;
    if (boxRFID) {
        box.RFID = addZerosToRfid(boxRFID);
    } else {
        delete box.RFID;
    }
    console.log("update this box", box);
    return dispatch(updateLocation(box));

}

export function validate(values, props) {
    const errors = {};
    const {t} = props;
    const {boxName, boxRFID} = values;
    if (!boxName) {
        errors.boxName = t("required");
    }
    if (boxRFID && boxRFID.length > 16) {
        errors.boxRFID = t("required");
    }
    return errors;
}