import React from "react";
import PropTypes from "prop-types"
import ListItem from "../../../../../components/basics/list/list-item/ListItem";
import {connect} from "react-redux";
import _ from "lodash";
import GraftingProgram from "../../../../../beans/GraftingProgram";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../components/basics/button/Button";
import {Link} from "react-router-dom";
import {ModalName as ConfirmModalName} from "../../../../../components/modals-new/confirm-modal/ConfirmModal";
import {updateDictionaryDynamoDB} from "../../../../../actions/dictionaryActions";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import {Col, Row} from "react-bootstrap";

export class GraftingProgramRow extends React.Component {

    state = {
        graftingProgram: this.props.graftingPrograms.find(item => item.WData.AnimalKind === this.props.animalKindIndex),
        graftingPrograms: this.props.graftingPrograms
    };

    static getDerivedStateFromProps(props, state) {
        const {graftingPrograms, animalKindIndex} = props;
        if (!_.isEqual(graftingPrograms, state.graftingPrograms)) {
            let graftingProgram = graftingPrograms.find(item => item.WData.AnimalKind === animalKindIndex);
            return {
                graftingProgram,
                graftingPrograms
            }
        }

        return null;
    }

    onRemoveClick = () => {
        const {t} = this.props;
        const {graftingProgram} = this.state;
        this.props.show(ConfirmModalName, {
            title: t("newSettings.breeding.graftingProgram.removeTitle"),
            text: i18next.t("newSettings.breeding.graftingProgram.removeText", {type: graftingProgram.WData.Name}),
            confirmText: t("yes"),
            onConfirmed: props => {
                const {user} = this.props;
                graftingProgram.remove();
                this.props.dispatch(updateDictionaryDynamoDB(graftingProgram.prepareBeanToSave(), user.ClientID, user.LocalUserID));
                props.handleHide();
            }
        })
    }

    render() {
        const {animalKindText, farm, animalKindIndex, t} = this.props;
        const {graftingProgram} = this.state;
        return (
            <ListItem
                className={"mh-5rem d-flex align-items-center mh-5rem overflow-hidden position-relative pt-3 pb-3"}>
                <Col xs={12}>
                    <Row>
                        <Col xs={12} sm={graftingProgram ? 3 : 9} xl={graftingProgram ? 6 : 10}>
                            <div className={"font-weight-bold"}>{animalKindText}</div>
                            <div className={"opacity-75"}>{t("animalType")}</div>
                        </Col>
                        {
                            !!graftingProgram &&
                            <>
                                <Col xs={6} sm={3} xl={2} className={"d-none d-sm-block"}>
                                    <div className={"font-weight-bold"}>{graftingProgram.WData.Name}</div>
                                    <div
                                        className={"opacity-75"}>{t("designation")}</div>
                                </Col>
                                <Col xs={6} sm={3} xl={2} className={"d-none d-sm-block"}>
                                    <div
                                        className={"font-weight-bold"}>{graftingProgram.WData.MedicineList.length}</div>
                                    <div
                                        className={"opacity-75"}>{t("newSettings.breeding.graftingProgram.medicineAmount")}</div>
                                </Col>
                            </>
                        }

                        <Col xs={12} sm={3} xl={2} className={"justify-content-end align-items-center d-flex"}>
                            <ButtonGroup className={"m-0"}>
                                {
                                    !!graftingProgram &&
                                    <>
                                        <Link
                                            to={`/${farm}/settings/breeding/graftingProgram/edit/${graftingProgram.WordID}`}>
                                            <Button buttonStyle={"round"} icon={<i className="fas fa-pen"/>}/>
                                        </Link>
                                        <Button buttonStyle={"round"} buttonColor={"error"}
                                                icon={<i className="fas fa-trash"/>}
                                                onClick={this.onRemoveClick}/>
                                    </>
                                }
                                {
                                    !graftingProgram &&
                                    <>
                                        <Link to={`/${farm}/settings/breeding/graftingProgram/add/${animalKindIndex}`}>
                                            <Button buttonStyle={"round"} buttonColor={"primary"}
                                                    icon={<i className="fas fa-plus"/>}/>
                                        </Link>
                                    </>
                                }
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Col>
            </ListItem>
        );
    }

}

GraftingProgramRow.propTypes = {
    animalKindIndex: PropTypes.number.isRequired,
    animalKindText: PropTypes.string.isRequired,
    graftingPrograms: PropTypes.arrayOf(PropTypes.instanceOf(GraftingProgram)),
    farm: PropTypes.string
};

GraftingProgramRow = connect(
    state => ({
        graftingPrograms: state.dictionary.graftingPrograms,
        farm: state.location.farm,
        user: state.user.user,
    }),
    dispatch => ({dispatch, ...bindActionCreators({show}, dispatch)})
)(GraftingProgramRow);

export default withTranslation()(GraftingProgramRow);
