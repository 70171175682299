import React, {Component} from 'react';
import Template from "./Template";
import {percentageFormatter} from "./utils";
import {withTranslation} from "react-i18next";
import {flatten} from "lodash";
import {setMatMenu} from "../../../../../IOT/device-functions/ClimateFunctions";
import {DevType} from "../../../../../constans/devices";
import {groupDevicesByGatewayID} from "../../../../../utils/DevicesUtils";

class MatsMenu extends Component {


    getModel = () => {
        const {t} = this.props;
        return flatten(new Array(8).fill(0).map((o, i) => ([{
            name: t("settings.matDayX", {number: i + 1}),
            key: `mats[${i}].day`,
            shadowKey: `mats[${i}].day`,
            min: 0,
            max: 99,
            step: 1,
            defaultValue: 4
        },
            {
                name: t("settings.matDeviationX", {number: i + 1}),
                key: `mats[${i}].deviation`,
                shadowKey: `mats[${i}].deviation`,
                min: -99,
                max: 99,
                step: 1,
                defaultValue: 0,
                formatter: percentageFormatter
            }])))
    }

    submit = (values) => {
        const {climates} = this.props;
        console.log(values, "MatsMenu submit");
        const devices = groupDevicesByGatewayID(climates);
        if (devices) {
            for (let deviceList of devices.values()) {
                setMatMenu(deviceList[DevType.CLIMATE_SK3], values);
            }
        } else {
            console.error("devices not found")
        }
    }

    render() {
        const model = this.getModel();
        const {climates, t} = this.props;
        return (
            <>
                <Template
                    climates={climates}
                    name={t("farmView.climate.matSettings")}
                    model={model}
                    onSubmit={this.submit}
                    form={"climate-sk3-mats-menu"}
                />
            </>
        );
    }
}

export default withTranslation()(MatsMenu);
