import GraftingProgram from "../../../../../beans/GraftingProgram";
import {createDictionaryDynamoDB, updateDictionaryDynamoDB} from "../../../../../actions/dictionaryActions";

export function submit(values, dispatch, props) {
    const {user, farm, edit} = props;
    if (edit) {
        let program = new GraftingProgram(values).prepareBeanToSave();
        program.sortMedicines();
        program.setDtaModTime();
        dispatch(updateDictionaryDynamoDB(program, user.ClientID, user.LocalUserID));
    } else {
        const {WData: {Name, AnimalKind, MedicineList}} = values;
        let graftingProgram = GraftingProgram.createGraftingProgram(Name, AnimalKind, MedicineList);
        dispatch(createDictionaryDynamoDB(graftingProgram, user.ClientID, user.LocalUserID));
    }
    props.history.push(`/${farm}/settings/breeding/graftingProgram`);
}

export function validate(values, props) {
    const errors = {
        WData: {}
    };
    const {t} = props;

    if (values.WData) {
        if (!values.WData.Name) {
            errors.WData.Name = t("required");
        }

        if (values.WData.MedicineList) {
            errors.WData.MedicineList = values.WData.MedicineList.map(val => {
                const valErrors = {};
                if (!val.Medicine) {
                    valErrors.Medicine = t("required");
                }
                return valErrors;
            })
        }
    }

    return errors;
}