import React from "react";
import {Col, Row} from "react-bootstrap";
import buildingsDB from "../../../../../database/buildingsDB";
import TreeSelect from "../../../../../components/basics/tree-select/TreeSelect";
import Building from "./Building";
import Sector from "./Sector";
import {connect} from "react-redux";
import Chamber from "./Chamber";
import Box from "./Box";
import Farm from "./Farm";
import ConfirmModal from "../../../../../components/modals-new/confirm-modal/ConfirmModal";
import AddLocationModal from "../../../../../components/modals-new/add-location-modal/AddLocationModal";
import {isEqual} from "lodash"
import Card from "../../../../../components/basics/card/Card";
import withRoles from "../../../../../components/withRoles";
import * as RoleTypes from 'validators-schema/Api/constants/roleTypes';
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {compose} from "redux";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        buildings: state.farms.buildings
    }
}

class ManageBuildingsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            treeOptions: buildingsDB.getObjectForTreeSelect(props.farm, true),
            buildings: buildingsDB.getAllBuildingsForFarm(props.farm),
            selectedPlacement: undefined
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {buildings} = this.props;
        if (!isEqual(prevProps.buildings, buildings)) {
            this.onBuildingsUpdate(this.props);
        }
    }

    goBack = () => {
        const {history} = this.props;
        history.go(-2);
        window.removeEventListener('popstate', this.goBack)
    };

    componentDidMount() {
        const {history} = this.props;
        if (history && history.location && history.location.state && history.location.state.fromPage) {
            if (history.location.state.fromPage.pathname && history.location.state.fromPage.pathname.split("/")[2] === "eventChooser" && history.location.state.fromPage.pathname.split("/")[3] !== "events") {
                window.addEventListener('popstate', this.goBack);
            }
        }
    }

    onBuildingsUpdate = (props) => {
        const {selectedPlacement} = this.state;
        this.setState({
            treeOptions: buildingsDB.getObjectForTreeSelect(props.farm, true),
            buildings: buildingsDB.getAllBuildingsForFarm(props.farm),
            selectedPlacement: selectedPlacement ? buildingsDB.getLocationByID(selectedPlacement.BID || selectedPlacement.CID || selectedPlacement.SID || selectedPlacement.BgID || selectedPlacement.FarmID) : undefined
        });
    };

    onTreeValueChange = (placement) => {
        this.setState({
            selectedPlacement: placement || null
        })
    };

    render() {
        const {treeOptions, selectedPlacement, buildings} = this.state;
        const {t} = this.props;
        return (
            <Card className={'manage-buildings-view'}>
                <Row className="justify-content-center">
                    <Col lg={6}>
                        <label>{t("newSettings.buildings.manageBuildingsView.choosenLocation")}</label>
                        <TreeSelect options={treeOptions} expanded onChange={this.onTreeValueChange}
                                    value={selectedPlacement} clearButton/>
                    </Col>
                </Row>
                {
                    selectedPlacement &&
                    <React.Fragment>
                        {
                            selectedPlacement.object.BgID &&
                            <Building building={selectedPlacement.object}/>
                        }
                        {
                            selectedPlacement.object.SID &&
                            <Sector sector={selectedPlacement.object}/>
                        }
                        {
                            selectedPlacement.object.CID &&
                            <Chamber chamber={selectedPlacement.object}/>
                        }
                        {
                            selectedPlacement.object.BID &&
                            <Box box={selectedPlacement.object}/>
                        }
                    </React.Fragment>
                }
                {
                    !selectedPlacement &&
                    <Farm buildings={buildings}/>
                }
                <ConfirmModal/>
                <AddLocationModal/>
            </Card>
        );
    }
}

export default compose(
    withRoles({roles: [RoleTypes.BUILDING], showComponent: true}),
    withRouter,
    withTranslation(),
    connect(mapStateToProps)
)(ManageBuildingsView);
