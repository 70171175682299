import React, {Component} from 'react';
import Template from "./Template";
import {
    setClimateHeating,
    setHeatingBreakTime,
    setHeatingDeviation,
    setHeatingWorkTime
} from "../../../../../IOT/device-functions/ClimateFunctions";
import {secondsFormatter, temperatureFormatter} from "./utils";
import {withTranslation} from "react-i18next";
import {groupDevicesByGatewayID} from "../../../../../utils/DevicesUtils";
import {DevType} from "../../../../../constans/devices";

class HeaterMenu extends Component {


    getModel = () => {
        const {t} = this.props;
        return [
            {
                name: t("settings.heatingDeviation"),
                key: 'heatingDeviation',
                shadowKey: 'configuration.heatingDeviation',
                min: 0.2,
                max: 4,
                step: 0.2,
                defaultValue: 1.6,
                formatter: temperatureFormatter,
                submit: setHeatingDeviation
            },
            {
                name: t("settings.heaterOpTime"),
                key: 'heatingWorkTime',
                shadowKey: 'service.service',
                min: 10 * 1000,
                max: 600 * 1000,
                step: 10 * 1000,
                defaultValue: 120 * 1000,
                formatter: secondsFormatter,
                submit: setHeatingWorkTime
            },
            {
                name: t("settings.heaterBreakTime"),
                key: 'heatingBreakTime',
                shadowKey: 'service.heatingBreakTime',
                min: 10 * 1000,
                max: 600 * 1000,
                step: 10 * 1000,
                defaultValue: 120 * 1000,
                formatter: secondsFormatter,
                submit: setHeatingBreakTime
            }
        ]
    }

    submit = (values) => {
        const {climates} = this.props;
        console.log(values, "HeaterMenu submit");
        const devices = groupDevicesByGatewayID(climates);
        if (devices) {
            for (let deviceList of devices.values()) {
                setClimateHeating(deviceList[DevType.CLIMATE_SK3], values);
            }
        } else {
            console.error("devices not found")
        }
    }

    render() {
        const model = this.getModel();
        const {climates, t} = this.props;
        return (
            <>
                <Template
                    climates={climates}
                    name={t("farmView.climate.heatersSettings")}
                    model={model}
                    onSubmit={this.submit}
                    form={"climate-sk3-heater-menu"}
                />
            </>
        );
    }
}

export default withTranslation()(HeaterMenu);
