import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import Switch from "../../../../../components/basics/switch/Switch";
import {setReturnToDefaultExit} from "../../../../../IOT/device-functions/SeparationCageFunctions";
import PropTypes from "prop-types";
import _ from "lodash";
import {connect} from "react-redux";
import {SeparationCageCommandTypes} from "../../../../../constans/mqttMessages";
import {LoadedDataTypes} from "../../../../../constans/devices";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class ReturnToDefaultExit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.getReturnToDefaultExit(),
            changed: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.changed) {
            this.setState(this.getReturnToDefaultExit(nextProps))
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    getReturnToDefaultExit(props = this.props) {
        let device = props.cages[0];
        try {
            let shadow = props.shadows.get(device.DevID);
            return {
                returnToDefaultExit: shadow.configuration.returnToDefaultExit,
                loadedDataFrom: LoadedDataTypes.SHADOW,
                SetTime: shadow.metadata.configuration,
                device
            };
        } catch (e) {
            return {
                returnToDefaultExit: _.get(device, `Settings.Configuration.${SeparationCageCommandTypes.SET_RETURN_TO_DEFAULT_EXITL}.returnToDefaultExit`, false),
                SetTime: _.get(device, `Settings.Configuration.${SeparationCageCommandTypes.SET_RETURN_TO_DEFAULT_EXITL}.SetTime`, 0),
                loadedDataFrom: device && device.Settings && device.Settings.Configuration && device.Settings.Configuration[SeparationCageCommandTypes.SET_RETURN_TO_DEFAULT_EXITL] ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA,
                device
            };
        }
    }

    onReturnToDefaultExitChange = value => {
        this.setState({
            returnToDefaultExit: value,
            changed: true
        })
    };

    onClick = () => {
        setReturnToDefaultExit(this.props.cages, this.state.returnToDefaultExit)
    };

    render() {
        const {t} = this.props;
        const {returnToDefaultExit, loadedDataFrom, SetTime, device} = this.state;
        return (
            <DeviceSettingsCard title={this.props.t("newSettings.cage.manage.returnToDefaultExit.title")}
                                onButtonClick={this.onClick}>
                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[SeparationCageCommandTypes.SET_RETURN_TO_DEFAULT_EXITL].isSet &&
                            <InfoBox
                                boxColor="warning">{i18next.t("newSettings.devices.settingsInfo.dynamo", {date: moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                <Switch label={this.props.t("newSettings.cage.manage.returnToDefaultExit.return")}
                        value={returnToDefaultExit}
                        onChange={this.onReturnToDefaultExitChange}
                        id="returnToDefaultExit"/>
            </DeviceSettingsCard>
        );
    }

}

ReturnToDefaultExit.propTypes = {
    cages: PropTypes.array.isRequired
};

ReturnToDefaultExit = connect(state => ({
    shadows: state.shadows.shadows,
}))(ReturnToDefaultExit);

export default withTranslation()(ReturnToDefaultExit);