import React from "react";
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux"
import LocationItem from "./LocationItem";
import {isEqual, get, cloneDeep} from "lodash";
import {getFlatLocations, getLocationName} from "../../../../../utils/BuildingUtils";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {getFlatDevices, placementDevicesMap} from "../../../../../utils/DevicesUtils";
import StandingsPairDeviceModal, {ModalName as PairStandingsModal} from "../../../../../components/modals-new/standings-pair-devices/StandingsPairDeviceModal";
import {DevType} from "../../../../../constans/devices";
import Button from "../../../../../components/basics/button/Button";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import {enhancedComparer} from "../../../../../utils/TextUtils";
import List from "../../../../../components/basics/list/List";
import Card from "../../../../../components/basics/card/Card";
import ListItem from "../../../../../components/basics/list/list-item/ListItem";
import withRoles from "../../../../../components/withRoles";
import {Roles} from "../../../../../constans/roles";
import devicesDB from "../../../../../database/devicesDB";
import "./_pair-location-view.scss";
import Input from "../../../../../components/basics/input/Input";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

class PairLocationView extends React.Component {

    constructor(props) {
        super(props);
        this.prepareData = this.prepareData.bind(this);
        this.filterBuildings = this.filterBuildings.bind(this);
        this.filterDevices = this.filterDevices.bind(this);
        this.changeLocation = this.changeLocation.bind(this);
        this.renderDispensersAdd = this.renderDispensersAdd.bind(this);
        this.state = {
            filterLocation: "",
            filterDevices: "",
            devices: [],
            buildings: []
        };

    }

    componentDidMount() {
        this.prepareData(this.props);
    }

    prepareData(props = this.props) {
        this.setState({
            devices: getFlatDevices(props.devices),
            buildings: getFlatLocations({Buildings: props.buildings}),
            placementDevicesMap: placementDevicesMap()
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {devices, buildings} = this.props;
        if (!isEqual(devices, nextProps.devices) || !isEqual(buildings, nextProps.buildings)) {
            this.prepareData(nextProps)
        }
    }

    checkFilter(value = "", filter = "") {
        return `${value}`.replace(/\s/g, "").toLowerCase().includes(filter.replace(/\s/g, "").toLowerCase())
    }

    filterBuildings(buildings = []) {
        let locations = cloneDeep(buildings);
        const {filterLocation} = this.state;
        locations = locations.filter(loc => loc.CID || loc.SID || loc.BgID);
        return !filterLocation ? locations : locations.filter(loc => this.checkFilter(getLocationName(loc), filterLocation));
    }

    filterDevices(devs = []) {
        let devices = cloneDeep(devs);
        const {filterDevices} = this.state;
        const {location} = this.state;
        let allowedDevTypes = [];
        if (location) {
            if (location.CID) {
                allowedDevTypes = [DevType.CLIMATE, DevType.SCALE, DevType.CAGE, DevType.ANTENNA_RFID, DevType.WATER_FLOW_METER, DevType.ELECTRICITY_FLOW_METER, DevType.SMALL_CAGE, DevType.CHAIN_FEEDING];
                if (!location.IndividualFeeding) {
                    allowedDevTypes.push(DevType.DISPENSER_NRF)
                }
            }
            if (location.BgID) {
                allowedDevTypes = [DevType.CLIMATE, DevType.SCALE, DevType.WATER_FLOW_METER, DevType.ELECTRICITY_FLOW_METER];
            }

        }
        devices = devices.filter(device => allowedDevTypes.includes(get(device, "device.DevType")));
        devices.sort((o1,o2)=>
            enhancedComparer(get(o1,"device.Name"), get(o2,"device.Name"), {numeric: true}) ||
            enhancedComparer(get(o1,"device.Address"), get(o2,"device.Address"), {numeric: true})
        );
        return !filterDevices ? devices : devices.filter(device => this.checkFilter(get(device, "device.Name", ""), filterDevices) || this.checkFilter(`0x${get(device, "device.Address", 0).toString(16)}`, filterDevices) || this.checkFilter(get(device, "device.Address", ""), filterDevices));
    }

    changeLocation(newLocation) {
        this.setState({
            location: newLocation
        })
    }

    renderDispensersAdd() {
        const {location} = this.state;
        const {t} = this.props;
        if (location && location.CID && location.IndividualFeeding) {
            let linked = [];
            for (let box of location.Boxes) {
                let devices = devicesDB.getDevicesInPlcmnt(box);
                if (devices.length > 0) linked.push(devices[0]);
            }
            return (
                <ListItem className={"pair-device-item"} fixedWidth>
                    <ButtonGroup>
                        <Button buttonStyle={"text"} className={"round"} icon={<i className="fas fa-list-ol"/>}
                                onClick={() => {
                                    const {show} = this.props;
                                    show(PairStandingsModal, {
                                        chamber: location
                                    })
                                }}/>
                    </ButtonGroup>
                    <span>
                        <div>
                             <strong>{t("newSettings.buildings.pair.pairLocationView.manageDispensers")}</strong>
                        </div>
                        <div>
                             <small>{i18next.t("newSettings.buildings.pair.pairLocationView.linkedBoxes", {
                                 linked: linked.length,
                                 location: location.Boxes.length
                             })}</small>
                        </div>
                    </span>
                </ListItem>
            )
        } else {
            return null
        }
    }

    render() {
        const {location, placementDevicesMap, filterLocation, filterDevices} = this.state;
        const {t} = this.props;
        const devices = this.filterDevices(this.state.devices);
        const buildings = this.filterBuildings(this.state.buildings);
        return (
            <>
                <div className={"headers"}>
                    <Row className="justify-content-center fixed-width">
                        <Col md={4}>
                            <Input placeholder={t("newSettings.buildings.pair.pairLocationView.locationFiltering")}
                                   type={"text"} value={filterLocation}
                                   onChange={(newValue) => this.setState({filterLocation: newValue})}/>
                        </Col>
                        <Col md={8}>
                            <Input placeholder={t("newSettings.buildings.pair.pairLocationView.deviceFiltering")}
                                   type={"text"} value={filterDevices}
                                   onChange={(newValue) => this.setState({filterDevices: newValue})}/>
                        </Col>
                    </Row>
                </div>
                <div className="settings-profile-general overflow-hidden">
                    <Row className="justify-content-center">
                        <Card type={"container"} style={{width: '98%'}}>
                            <List>
                                {
                                    buildings.map((loc, key) => (
                                        <LocationItem devicesMap={placementDevicesMap} key={key} location={loc}
                                                      devices={devices}
                                                      selectedLocation={location}
                                                      handleOnClick={this.changeLocation}/>))
                                }
                            </List>
                        </Card>
                    </Row>
                    <StandingsPairDeviceModal/>
                </div>
            </>
        );
    }
}

PairLocationView = connect(state => ({
    devices: state.farmDevices.devices,
    buildings: state.farms.buildings,
}))(PairLocationView);

PairLocationView = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(PairLocationView);

PairLocationView = withTranslation()(PairLocationView);
export default withRoles({roles: [Roles._DEVICE_CONFIG], showComponent: true})(PairLocationView);
