import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import LabeledInput from "../../../../../components/basics/input/labeled-input/LabeledInput";
import {setWorkTypeDuration} from "../../../../../IOT/device-functions/SeparationCageFunctions";
import PropTypes from "prop-types";
import _ from "lodash";
import {connect} from "react-redux";
import {SeparationCageCommandTypes} from "../../../../../constans/mqttMessages";
import {LoadedDataTypes} from "../../../../../constans/devices";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class WorkTypeDurations extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.getWorkTypeDuration(),
            changed: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.changed) {
            this.setState({
                ...this.getWorkTypeDuration(nextProps)
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    getWorkTypeDuration(props = this.props) {
        let device = props.cages[0];
        try {
            let shadow = props.shadows.get(device.DevID);
            return {
                ...shadow.configuration.workTypeDuration,
                loadedDataFrom: LoadedDataTypes.SHADOW,
                SetTime: shadow.metadata.configuration,
                device
            };
        } catch (e) {
            return {
                ..._.get(device, `Settings.Configuration.${SeparationCageCommandTypes.SET_WORK_TYPE_DURATION}`, {
                    training1: 6,
                    training2: 6,
                    training3: 6,
                    separation: 20,
                    expedition: 20,
                }),
                SetTime: _.get(device, `Settings.Configuration.${SeparationCageCommandTypes.SET_WORK_TYPE_DURATION}.SetTime`, 0),
                loadedDataFrom: device && device.Settings && device.Settings.Configuration && device.Settings.Configuration[SeparationCageCommandTypes.SET_WORK_TYPE_DURATION] ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA,
                device
            };
        }
    }

    onTraining1Change = value => {
        this.setState({
            training1: value,
            changed: true
        })
    };

    onTraining2Change = value => {
        this.setState({
            training2: value,
            changed: true
        })
    };

    onTraining3Change = value => {
        this.setState({
            training3: value,
            changed: true
        })
    };

    onSeparationChange = value => {
        this.setState({
            separation: value,
            changed: true
        })
    };

    onExpeditionChange = value => {
        this.setState({
            expedition: value,
            changed: true
        })
    };

    onClick = () => {
        if (this.state.training1 && this.state.training2 && this.state.training3 && this.state.separation && this.state.expedition) {
            setWorkTypeDuration(this.props.cages, {
                training1: +this.state.training1,
                training2: +this.state.training2,
                training3: +this.state.training3,
                separation: +this.state.separation,
                expedition: +this.state.expedition
            })
        }
    };

    render() {
        const {t} = this.props;
        const {training1, training2, training3, separation, expedition, loadedDataFrom, SetTime, device} = this.state;
        return (
            <DeviceSettingsCard title={this.props.t("newSettings.cage.manage.workTypeDurations.title")}
                                onButtonClick={this.onClick}>
                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[SeparationCageCommandTypes.SET_WORK_TYPE_DURATION].isSet &&
                            <InfoBox
                                boxColor="warning">{i18next.t("newSettings.devices.settingsInfo.dynamo", {date: moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                <LabeledInput label={this.props.t("newSettings.cage.manage.workTypeDurations.training1")} type="number"
                              value={training1}
                              onChange={this.onTraining1Change}/>
                <LabeledInput label={this.props.t("newSettings.cage.manage.workTypeDurations.training2")} type="number"
                              value={training2}
                              onChange={this.onTraining2Change}/>
                <LabeledInput label={this.props.t("newSettings.cage.manage.workTypeDurations.training3")} type="number"
                              value={training3}
                              onChange={this.onTraining3Change}/>
                <LabeledInput label={this.props.t("newSettings.cage.manage.workTypeDurations.separation")} type="number"
                              value={separation}
                              onChange={this.onSeparationChange}/>
                <LabeledInput label={this.props.t("newSettings.cage.manage.workTypeDurations.expedition")} type="number"
                              value={expedition}
                              onChange={this.onExpeditionChange}/>
            </DeviceSettingsCard>
        );
    }

}

WorkTypeDurations.propTypes = {
    cages: PropTypes.array.isRequired
};

WorkTypeDurations = connect(state => ({
    shadows: state.shadows.shadows,
}))(WorkTypeDurations);

export default withTranslation()(WorkTypeDurations);