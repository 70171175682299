import React, {Component} from 'react';
import {connect} from 'react-redux';
import SingleValue from "../SingleValue";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import {isDirty, reduxForm} from "redux-form";
import {withTranslation} from "react-i18next";
import {makeGetShadowByDevice} from "../../../../../selectors/shadowSelectors";
import {initializeForm} from "./utils";



function makeMapStateToProps(initialState, initialProps) {
    const getShadowByDevice = makeGetShadowByDevice();
    const {climates} = initialProps;
    const params = {DevID: climates[0].DevID}
    return function mapStateToProps(state) {
        return {
            ...getShadowByDevice(state, params),
            dirty: isDirty(initialProps.form)(state),
        };
    }
}

class Template extends Component {

    componentDidMount() {
        this.initialize();
    }

    initialize = () => {
        const {initialize, shadow, model} = this.props;
        initializeForm({model, initialize, shadow});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {fetched, dirty} = this.props;
        const {fetched: prevFetched} = prevProps;
        if (fetched && (fetched !== prevFetched)) {
            if (!dirty) {
                this.initialize();
            }
        }
    }

    render() {
        const {submit: onButtonClick, initialized, climates, name, model, form} = this.props;
        return (
            <DeviceSettingsCard disabled={!initialized} title={name} onButtonClick={onButtonClick}>
                {initialized && model.map(o => (
                    <SingleValue
                        device={climates}
                        formName={form}
                        name={o.name}
                        key={o.key}
                        id={o.key}
                        min={o.min}
                        max={o.max}
                        step={o.step}
                        formatter={o.formatter}
                        submit={o.submit}
                        submitKey={o.submitKey || o.key}
                    />
                ))}
            </DeviceSettingsCard>

        );
    }
}


Template = connect(makeMapStateToProps)(Template);

Template = reduxForm()(Template);


export default withTranslation()(Template);
