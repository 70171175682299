import React from "react";
import {Col, Row} from "react-bootstrap";
import {Field, initialize, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import Card from "../../../../../components/basics/card/Card";
import FormForSettings from "../../../FormForSettings";
import _ from "lodash";
import {
    getDaysForRepeatedInsemination,
    getFirstTechnologyGroupStart,
    getMaxDelayForBirth,
    getMinAgeForFirstMating,
    getMinWeightForFirstMating,
    getNumberOfDaysToSale,
    getPreparationPeriodForMating,
    getTechnologyGroupSize,
    getTechnologyGroupWeeks,
    getTimeFromInseminationToPartuition,
    getTimeFromInseminationToPregnancy,
    getTimeOnBirthRoom,
    getTimeOnBirthRoomMommy,
    getTimeOnMatingRoom, getTimeOnSowsRoom
} from "../../../../../utils/SettingsUtils";
import ReduxLabeledSlider from "../../../../../components/basics/slider/labeled-slider/ReduxLabeledSlider";
import settingsDB from "../../../../../database/settingsDB"
import {SettingTypes} from "../../../../../constans/settingTypes";
import Setting from "../../../../../beans/Setting";
import {createSettingDynamoDB, updateSettingDynamoDB} from "../../../../../actions/settingsAction";
import withRoles from "../../../../../components/withRoles";
import {Roles} from "../../../../../constans/roles";
import {convertWeightUnitTo} from "../../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../../constans/unitTypes";
import ReduxLabeledInput from "../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import moment from "moment";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

const FormName = 'settings-general-cycle';

export class Cycle extends React.Component {

    constructor(props) {
        super(props);
        const {dispatch} = this.props;
        const initialValue = {
            firstTechnologyGroup: moment(getFirstTechnologyGroupStart()).format(moment.HTML5_FMT.DATE),
            numberOfWeeksInCycle: +getTechnologyGroupWeeks(),
            timeFromInseminationToPregnancy: +getTimeFromInseminationToPregnancy(),
            timeFromInseminationToPartuition: +getTimeFromInseminationToPartuition(),
            timeBetweenInseminations: +getDaysForRepeatedInsemination(),
            timeOnBirthRoom: +getTimeOnBirthRoom(),
            timeOnBirthRoomMommy: +getTimeOnBirthRoomMommy(),
            timeOnMatingRoom: +getTimeOnMatingRoom(),
            timeOnSowsRoom: getTimeOnSowsRoom(),
            maxPartuitionDelay: +getMaxDelayForBirth(),
            matingPreparationPeriod: +getPreparationPeriodForMating(),
            firstMatingMinWeight: +getMinWeightForFirstMating(),
            firstMatingMinAge: +getMinAgeForFirstMating(),
            numberOfDaysToSale: +getNumberOfDaysToSale(),
            technologyGroupSize: +getTechnologyGroupSize()
        };
        dispatch(initialize(FormName, initialValue))
    }


    render() {
        const {handleSubmit, t} = this.props;
        return (
            <Card className={FormName}>
                <FormForSettings {...this.props} handleSubmit={handleSubmit} icon={<i className="fas fa-save"/>}>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <Field
                                name="firstTechnologyGroup"
                                id="firstTechnologyGroup"
                                required
                                component={ReduxLabeledInput}
                                label="Data startu piewszej grupy technologicznej"
                                type="date"
                            />
                            <Field
                                name="numberOfWeeksInCycle"
                                id="numberOfWeeksInCycle"
                                required
                                min={1}
                                step={2}
                                max={3}
                                valueFormatter={(value) => t("newSettings.breeding.manage.cycle.weeks", {count: value})}
                                component={ReduxLabeledSlider}
                                label={t("newSettings.breeding.manage.cycle.numberOfWeeksInCycle")}
                            />
                            <Field
                                name="technologyGroupSize"
                                id="technologyGroupSize"
                                required
                                min={1}
                                max={500}
                                component={ReduxLabeledSlider}
                                valueFormatter={(value) => t("pcs", {count: value})}
                                label={t("newSettings.breeding.manage.cycle.technologyGroupSize")}
                            />
                            <Field
                                name="timeFromInseminationToPregnancy"
                                id="timeFromInseminationToPregnancy"
                                required
                                min={20}
                                max={40}
                                valueFormatter={(value) => t("newSettings.breeding.manage.cycle.days", {count: value})}
                                component={ReduxLabeledSlider}
                                label={t("newSettings.breeding.manage.cycle.timeFromInseminationToPregnancy")}
                            />
                            <Field
                                name="timeFromInseminationToPartuition"
                                id="timeFromInseminationToPartuition"
                                required
                                min={90}
                                max={130}
                                valueFormatter={(value) => t("newSettings.breeding.manage.cycle.days", {count: value})}
                                component={ReduxLabeledSlider}
                                label={t("newSettings.breeding.manage.cycle.timeFromInseminationToPartuition")}
                            />
                            <Field
                                name="timeBetweenInseminations"
                                id="timeBetweenInseminations"
                                required
                                min={1}
                                max={15}
                                valueFormatter={(value) => t("newSettings.breeding.manage.cycle.days", {count: value})}
                                component={ReduxLabeledSlider}
                                label={t("newSettings.breeding.manage.cycle.timeBetweenInseminations")}
                            />
                            <Field
                                name="timeOnBirthRoom"
                                id="timeOnBirthRoom"
                                required
                                min={20}
                                max={50}
                                valueFormatter={(value) => t("newSettings.breeding.manage.cycle.days", {count: value})}
                                component={ReduxLabeledSlider}
                                label={t("newSettings.breeding.manage.cycle.timeOnBirthRoom")}
                            />
                            <Field
                                name="timeOnBirthRoomMommy"
                                id="timeOnBirthRoomMommy"
                                required
                                min={20}
                                max={50}
                                valueFormatter={(value) => t("newSettings.breeding.manage.cycle.days", {count: value})}
                                component={ReduxLabeledSlider}
                                label={t("newSettings.breeding.manage.cycle.timeOnBirthRoomMommy")}
                            />
                            <Field
                                name="timeOnMatingRoom"
                                id="timeOnMatingRoom"
                                required
                                min={1}
                                max={30}
                                valueFormatter={(value) => t("newSettings.breeding.manage.cycle.days", {count: value})}
                                component={ReduxLabeledSlider}
                                label={t("newSettings.breeding.manage.cycle.timeOnMatingRoom")}
                            />
                            <Field
                                name="timeOnSowsRoom"
                                id="timeOnSowsRoom"
                                required
                                min={70}
                                max={120}
                                valueFormatter={(value) => t("newSettings.breeding.manage.cycle.days", {count: value})}
                                component={ReduxLabeledSlider}
                                label={t("newSettings.breeding.manage.cycle.timeOnSowsRoom")}
                            />
                            <Field
                                name="maxPartuitionDelay"
                                id="maxPartuitionDelay"
                                required
                                min={1}
                                max={15}
                                valueFormatter={(value) => t("newSettings.breeding.manage.cycle.days", {count: value})}
                                component={ReduxLabeledSlider}
                                label={t("newSettings.breeding.manage.cycle.maxPartuitionDelay")}
                            />

                            <Field
                                name="matingPreparationPeriod"
                                id="matingPreparationPeriod"
                                required
                                min={1}
                                max={15}
                                valueFormatter={(value) => t("newSettings.breeding.manage.cycle.weeks", {count: value})}
                                component={ReduxLabeledSlider}
                                label={t("newSettings.breeding.manage.cycle.matingPreparationPeriod")}
                            />

                            <Field
                                name="firstMatingMinWeight"
                                id="firstMatingMinWeight"
                                required
                                min={1000}
                                max={170 * 1000}
                                step={1000}
                                valueFormatter={(value) => convertWeightUnitTo(value, {
                                    showUnit: true,
                                    unit: UnitTypes.MEDIUM,
                                    fixed: 0
                                })}
                                component={ReduxLabeledSlider}
                                label={t("newSettings.breeding.manage.cycle.firstMatingMinWeight")}
                            />

                            <Field
                                name="firstMatingMinAge"
                                id="firstMatingMinAge"
                                required
                                min={30}
                                max={300}
                                valueFormatter={(value) => t("newSettings.breeding.manage.cycle.days", {count: value})}
                                component={ReduxLabeledSlider}
                                label={t("newSettings.breeding.manage.cycle.firstMatingMinAge")}
                            />

                            <Field
                                name="numberOfDaysToSale"
                                id="numberOfDaysToSale"
                                required
                                min={50}
                                max={130}
                                valueFormatter={(value) => t("newSettings.breeding.manage.cycle.days", {count: value})}
                                component={ReduxLabeledSlider}
                                label={t("newSettings.breeding.manage.cycle.numberOfDaysToSale")}
                            />
                        </Col>
                    </Row>
                </FormForSettings>
            </Card>
        );
    }
}

function submit(values, dispatch, props) {
    const {user: {ClientID}} = props;
    let general = settingsDB.getSettingByID(ClientID);
    let data = {
        NumberOfWeeksInCycle: +values.numberOfWeeksInCycle,
        TimeFromInseminationToPregnancy: +values.timeFromInseminationToPregnancy,
        TimeFromInseminationToPartuition: +values.timeFromInseminationToPartuition,
        TimeBetweenInseminations: +values.timeBetweenInseminations,
        TimeOnBirthRoom: +values.timeOnBirthRoom,
        TimeOnBirthRoomMommy: +values.timeOnBirthRoomMommy,
        TimeOnMatingRoom: +values.timeOnMatingRoom,
        TimeOnSowsRoom: +values.timeOnSowsRoom,
        MaxPartuitionDelay: +values.maxPartuitionDelay,
        MatingPreparationPeriod: +values.matingPreparationPeriod,
        FirstMatingMinWeight: +values.firstMatingMinWeight,
        FirstMatingMinAge: +values.firstMatingMinAge,
        NumberOfDaysToSale: +values.numberOfDaysToSale,
        TechnologyGroupSize: +values.technologyGroupSize,
        FirstTechnologyGroup: moment(values.firstTechnologyGroup).toDate().getTime()
    };
    if (general) {
        let clone = _.cloneDeep(general);
        if (!clone.SetData) {
            clone.SetData = {Settings: {}};
        } else {
            if (!clone.SetData.Settings) {
                clone.SetData = {...clone.SetData, Settings: {}}
            }
        }
        clone.SetData.Settings.Cycle = data;
        clone.DtaModTime = +new Date();
        dispatch(updateSettingDynamoDB(clone));
    } else {
        let setting = Setting.createSetting(SettingTypes.GENERAL, {Settings: {Cycle: data}});
        dispatch(createSettingDynamoDB(setting));
    }
}

function validate(values, props) {
    const errors = {};

    if (!values.firstTechnologyGroup) {
        errors.firstTechnologyGroup = i18next.t("required");
    } else {
        // wymagany poniedzialek
        if (moment(values.firstTechnologyGroup).isoWeekday() !== 1) {
            errors.firstTechnologyGroup = i18next.t("errors.mustBeMonday");
        }
    }

    return errors;
}

Cycle = reduxForm({
    form: FormName,
    onSubmit: submit,
    validate
})(Cycle);

export let _Cycle = connect(state => ({
    farm: state.location.farm,
    user: state.user.user,
}))(Cycle);

_Cycle = withRoles({roles: [Roles._BREEDING], showComponent: true})(_Cycle);
export default withTranslation()(_Cycle);
