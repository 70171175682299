import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import LabeledInput from "../../../../../components/basics/input/labeled-input/LabeledInput";
import PropTypes from "prop-types"
import {setAttemptsNumber} from "../../../../../IOT/device-functions/SeparationCageFunctions";
import {connect} from "react-redux";
import _ from "lodash"
import {SeparationCageCommandTypes} from "../../../../../constans/mqttMessages";
import {LoadedDataTypes} from "../../../../../constans/devices";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class AttemptsNumber extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.getAttemptsNumber(),
            changed: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.changed) {
            this.setState(this.getAttemptsNumber(nextProps))
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    getAttemptsNumber(props = this.props) {
        let device = props.cages[0];
        try {
            let shadow = props.shadows.get(device.DevID);
            return {
                attemptsNumber: shadow.configuration.attemptsNumber,
                loadedDataFrom: LoadedDataTypes.SHADOW,
                SetTime: shadow.metadata.configuration,
                device
            };
        } catch (e) {
            return {
                attemptsNumber: _.get(device, `Settings.Configuration.${SeparationCageCommandTypes.SET_ATTEMPTS_NUMBER}.attemptsNumber`, 10),
                SetTime: _.get(device, `Settings.Configuration.${SeparationCageCommandTypes.SET_ATTEMPTS_NUMBER}.SetTime`, 0),
                loadedDataFrom: device && device.Settings && device.Settings.Configuration && device.Settings.Configuration[SeparationCageCommandTypes.SET_ATTEMPTS_NUMBER] ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA,
                device
            };
        }
    }

    onAttemptsNumberChange = value => {
        this.setState({
            attemptsNumber: value,
            changed: true
        })
    };

    onClick = () => {
        if (this.state.attemptsNumber) {
            setAttemptsNumber(this.props.cages, +this.state.attemptsNumber);
        }
    };

    render() {
        const {t} = this.props;
        const {attemptsNumber, loadedDataFrom, SetTime, device} = this.state;
        console.log(this.state);
        return (
            <DeviceSettingsCard title={this.props.t("newSettings.cage.manage.attemptsNumber.title")}
                                onButtonClick={this.onClick}>
                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[SeparationCageCommandTypes.SET_ATTEMPTS_NUMBER].isSet &&
                            <InfoBox
                                boxColor="warning">{i18next.t("newSettings.devices.settingsInfo.dynamo", {date: moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                <LabeledInput type="number" label={t("amountTries")}
                              value={attemptsNumber}
                              onChange={this.onAttemptsNumberChange}/>
            </DeviceSettingsCard>
        );
    }

}

AttemptsNumber.propTypes = {
    cages: PropTypes.array.isRequired
};

AttemptsNumber = connect(state => ({
    shadows: state.shadows.shadows,
}))(AttemptsNumber);

export default withTranslation()(AttemptsNumber);

