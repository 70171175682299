import animalsDB from "../../../../../database/animalsDB";
import buildingsDB from "../../../../../database/buildingsDB";
import {addZerosToRfid} from "../../../../../utils/AnimalsUtils";
import {updateLocation} from "../../../../../actions/farmsActions";
import {isEqual} from "lodash";

export function submit(values, bag) {
    const {chambers} = values;
    const {farm, dispatch} = bag.props;
    let chambersBeforeChange = buildingsDB.getAllChambers(farm);
    for (let i = 0; i < chambers.length; i++) {
        let chamber = chambers[i];
        let beforeChange = chambersBeforeChange[i];
        if (!isEqual(chamber, beforeChange)) {
            if (chamber.Boxes) {
                chamber.Boxes = chamber.Boxes.map(box => {
                    if (box.RFID) {
                        return {
                            ...box,
                            RFID: addZerosToRfid(box.RFID + "")
                        }
                    } else {
                        return box;
                    }
                })
            } else {
                if (chamber.RFID) {
                    chamber.RFID = addZerosToRfid(chamber.RFID + "");
                } else {
                    chamber.RFID = undefined;
                }
            }
            dispatch(updateLocation(chamber));
        }
    }
}

export function validate(values, props) {
    const errors = {};
    const {t, farm} = props;

    function checkIfHaveDuplicates(value) {
        let amount = 0;
        for (let chamber of values.chambers) {
            if (chamber.Boxes) {
                for (let box of chamber.Boxes) {
                    if (box.RFID === value) amount++;
                }
            } else {
                if (chamber.RFID === value) amount++;
            }
        }
        return amount > 1;
    }

    if (values.chambers) {
        let hasAny = false;
        errors.chambers = values.chambers.map(chamber => {
            const chamberErrors = {};
            if (chamber.Boxes) {
                chamberErrors.Boxes = chamber.Boxes.map(box => {
                    let boxErrors = {};
                    if (box.RFID && (checkIfHaveDuplicates(box.RFID) || animalsDB.checkIfHaveRFID(box.RFID, farm))) {
                        boxErrors.RFID = t("errors.duplicate");
                        hasAny = true;
                    }
                    return boxErrors;
                });
            } else {
                if (chamber.RFID && (checkIfHaveDuplicates(chamber.RFID) || animalsDB.checkIfHaveRFID(chamber.RFID, farm))) {
                    chamberErrors.RFID = t("errors.duplicate");
                    hasAny = true;
                }
            }
            return chamberErrors;
        })
        if (!hasAny) {
            delete errors.chambers;
        }
    }

    return errors;
}
