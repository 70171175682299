import React from "react";
import "./_delete-location-icon-component.scss";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {makeGetDevicesInPlacement} from "../../../../../selectors/deviceSelector";
import * as DevTypes from "validators-schema/Api/constants/devTypes";
import {getAllChildrenForLocationID} from "../../../../../utils/BuildingUtils";
import Tooltip from "../../../../../components/basics/tooltip/Tooltip";
import {show} from "redux-modal";
import {withTranslation} from "react-i18next";

function makeMapStateToProps(ownState, ownProps) {
    const {value} = ownProps;
    const locationID = value.BgID || value.SID || value.CID || value.BID;
    const children = getAllChildrenForLocationID(locationID);
    const getDevicesInPlacement = makeGetDevicesInPlacement();
    const devTypes = Object.keys(DevTypes).map((key) => DevTypes[key]);
    const params = {
        PlcmntID: children,
        DevType: devTypes
    };
    return function mapStateToProps(state) {
        const devices = getDevicesInPlacement(state, params);
        return {
            allowDelete: !devices.length
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch)
    }
}

const _TooltipInfo = ({t, onClick, color, allowDelete}) => (
    <Tooltip tooltipContent={
        <div>
            {
                !allowDelete &&
                <div>
                    <i className="fas fa-fw fa-plug"/><b>{t("deleteLocationIconComponent.deletionNotAllowed")}</b>
                </div>
            }
            {
                allowDelete &&
                <div>
                    <b>{t("deleteLocationIconComponent.deleteLocation")}</b>
                </div>
            }
        </div>
    } placement="auto" type={color}>
        <i onClick={allowDelete ? onClick : undefined} className={`fas fa-fw fa-trash ${allowDelete ? "" : "disabled"}`}/>
    </Tooltip>
);

const TooltipInfo = React.memo(withTranslation()(_TooltipInfo));

class DeleteLocationIconComponent extends React.Component {

    render() {
        const {allowDelete, onClick} = this.props;
        return (
            <>
                <TooltipInfo onClick={onClick} color={allowDelete ? "" : "error"} allowDelete={allowDelete}/>
            </>
        );
    }

}

export default compose(
    connect(
        makeMapStateToProps,
        mapDispatchToProps
    )
)(DeleteLocationIconComponent);

