import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import {Field, formValueSelector, reduxForm} from "redux-form";
import ReduxSwitch from "../../../../../components/basics/switch/ReduxSwitch";
import {connect} from "react-redux";
import ReduxLabeledSelect from "../../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import ReduxLabeledSlider from "../../../../../components/basics/slider/labeled-slider/ReduxLabeledSlider";
import ReduxLabeledInput from "../../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import {submit, validate} from "./ClockSubmit"
import ClimateCurveChart from "../../../../../components/charts/ClimateCurveChart";
import {withTranslation} from "react-i18next";

export class ClockMenu extends React.Component {

    onButtonClick = () => {
        this.props.submit();
    };

    render() {
        const {curveActive, climateCurves, curve} = this.props;
        return (
            <DeviceSettingsCard title="Menu zegara" onButtonClick={this.onButtonClick}>
                <Field
                    name="curveActive"
                    component={ReduxSwitch}
                    label={this.props.t("modals.climateCurve.curveActive")}
                />
                {
                    curveActive &&
                    <>
                        <Field
                            name="curve"
                            component={ReduxLabeledSelect}
                            label={this.props.t("settings.curvesSettings")}
                            options={climateCurves.map(curve => ({
                                name: curve.SetData.Name,
                                value: curve
                            }))}
                        />
                        {
                            curve &&
                            <>
                                <ClimateCurveChart stages={curve.SetData.Stages}/>
                                <Field
                                    name="currentCurveDay"
                                    component={ReduxLabeledSlider}
                                    {...curve.getMinAndMax()}
                                    label={this.props.t("curveDay")}
                                    valueFormatter={value => value !== undefined ? value : null}
                                />
                            </>
                        }
                    </>
                }
                <Field
                    name="recorderEraseTime"
                    component={ReduxLabeledInput}
                    type="time"
                    label={this.props.t("modals.climateCurve.recorderEraseHour")}
                />
            </DeviceSettingsCard>
        );
    }
}

ClockMenu = reduxForm({
    form: "clockMenu",
    onSubmit: submit,
    validate
})(ClockMenu);

const selector = formValueSelector("clockMenu");
ClockMenu = connect(state => ({
    climateCurves: state.settings.climateCurves,
    curveActive: selector(state, "curveActive"),
    curve: selector(state, "curve")
}))(ClockMenu);

export default withTranslation()(ClockMenu);