import React from "react";
import Card from "../../../../../components/basics/card/Card";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import List from "../../../../../components/basics/list/List";
import GraftingProgramRow from "./GraftingProgramRow";
import {withTranslation} from "react-i18next";

export class GraftingProgram extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            animalKind: []
        }
    }

    componentDidMount() {
        const {t} = this.props;
        this.setState({
            animalKind: t("animalKind", {returnObjects: true})
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            const {t} = this.props;
            this.setState({
                animalKind: t("animalKind", {returnObjects: true})
            })
        }
    }

    render() {
        const {animalKind} = this.state;
        return (
            <Card type={"container"}>
                <List striped useFlexLayout={false} showNumber>
                    {
                        animalKind.map((name, index) =>
                            <GraftingProgramRow animalKindText={name} animalKindIndex={index} key={index}/>
                        )
                    }
                </List>
            </Card>
        );
    }

}

GraftingProgram = connect(
    state => ({
        farm: state.location.farm,
        graftingPrograms: state.dictionary.graftingPrograms,
        user: state.user.user
    }),
    dispatch => ({dispatch, ...bindActionCreators({show}, dispatch)})
)(GraftingProgram);

export default withTranslation()(GraftingProgram);
