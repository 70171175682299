import React from "react";
import Card from "../../../../components/basics/card/Card";
import TableGrid from "../../../../components/basics/table-grid/TableGrid";
import {DevType} from "../../../../constans/devices";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../components/basics/button/Button";
import {connect} from "react-redux";
import withRoles from "../../../../components/withRoles";
import {Roles} from "../../../../constans/roles";
import {checkIfUserCanManage} from "../../../../utils/NewRolesUtils";
import {manageDeviceHeaders} from "../../../../utils/DevicesUtils";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import DefaultMobileRow from "../../../../components/basics/table-grid/default-mobile-row/DefaultMobileRow";

export class ManageClimates extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedClimates: []
        }
    }

    onSelectionChange = selected => {
        this.setState({
            selectedClimates: selected
        })
    };

    render() {
        const {devices, t, farm} = this.props;
        const headers = manageDeviceHeaders(t);
        const {selectedClimates} = this.state;
        const userCanManage = checkIfUserCanManage();
        if (userCanManage) {
            return (
                <Card>
                    <TableGrid
                        data={devices.filter(item => (item.DevType === DevType.CLIMATE))}
                        headers={headers}
                        shouldIndex
                        mobileRow={<DefaultMobileRow/>}
                        selectableRow onSelectedRowsChanged={this.onSelectionChange} name={"manageClimates"}/>
                    <ButtonGroup fixed renderInPortal={false}>
                        <Link to={{
                            pathname: `/${farm}/settings/climate/manage/configuration`,
                            state: {
                                selectedClimates: selectedClimates
                            }
                        }} disabled={selectedClimates.length === 0}>
                            <Button buttonColor={"success"} buttonStyle={"round"}
                                    icon={<i className="fas fa-arrow-right"/>}
                                    disabled={selectedClimates.length === 0}/>
                        </Link>
                    </ButtonGroup>
                </Card>
            );
        } else {
            return null;
        }
    }

}

export let _ManageClimates = connect(state => ({
    devices: state.farmDevices.devices,
    farm: state.location.farm
}))(ManageClimates);
_ManageClimates = withRoles({
    roles: [Roles._DEVICE_CLIMATE, Roles._DEVICE_CONFIG],
    showComponent: true
})(_ManageClimates);

export default withTranslation()(_ManageClimates);
