import React from "react";
import _ from "lodash";
import InfoBox from "../../../../components/basics/info-box/InfoBox";
import Timer from "react-time-counter";
import Button from "../../../../components/basics/button/Button";
import {Field, reduxForm} from "redux-form";
import FormForSettings from "../../FormForSettings";
import {submit, validate} from "./DispenserCalibrationSubmit";
import {startMotor as startNRF, stopMotor as stopNRF} from "../../../../IOT/device-functions/DispenserNRFFunctions";
import {
    startMotors as startDTM,
    stopMotors as stopDTM
} from "../../../../IOT/device-functions/DispenserDriverFunctions";
import {getUnit} from "../../../../utils/UnitUtils";
import ReduxLabeledInput from "../../../../components/basics/input/labeled-input/ReduxLabeledInput";
import {UnitTypes} from "../../../../constans/unitTypes";
import {withTranslation} from "react-i18next";

export class DispenserCalibration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            started: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {device} = this.props;
        const {started} = this.state;
        if (!_.isEqual(device, nextProps.device)) {
            if (started) {
                this.stop(device);
            }
        }
    }

    start = device => {
        if (device) {
            const {DevID, Adr} = device;
            if (_.isNil(Adr)) {
                startNRF(DevID, {time: 60000}, () => this.setState({
                    started: true,
                    message: ""
                }))
            } else {
                startDTM(DevID, [{time: 60000, number: Adr + 1}], () => this.setState({
                    started: true,
                    message: ""
                }))
            }
        }
    };

    stop = device => {
        if (device) {
            const {DevID, Adr} = device;
            if (_.isNil(Adr)) {
                stopNRF(DevID, () => this.setState({
                    started: false,
                    message: ""
                }))
            } else {
                stopDTM(DevID, [Adr + 1], () => this.setState({
                    started: false,
                    message: ""
                }))
            }
        }
    };

    render() {
        const {started, message} = this.state;
        const {device, handleSubmit, t} = this.props;
        return (
            <div>
                {
                    started &&
                    <InfoBox boxColor={"info"}>
                        <Timer
                            backward={true}
                            seconds={60}
                            showHours={false}
                            showMinutes={false}
                            endLabel={"s"}
                            callback={() => this.setState({
                                started: false,
                                message: t("newSettings.feeding.forages.calibration.calibrationMessage")
                            })}
                        />
                    </InfoBox>
                }
                {
                    !started && !!message &&
                    <InfoBox boxColor={"success"}>
                        {message}
                    </InfoBox>
                }

                <FormForSettings handleSubmit={handleSubmit} icon={<i className={'fas fa-arrow-right'}/>}>
                    <Field
                        label={t("newSettings.feeding.forages.calibration.firstForageWeight")}
                        name={"firstWeight"}
                        id={"firstWeight"}
                        unit={getUnit("weight", UnitTypes.SMALL)}
                        type={"number"}
                        component={ReduxLabeledInput}
                    />
                    <Field
                        label={t("newSettings.feeding.forages.calibration.secondForageWeight")}
                        name={"secondWeight"}
                        id={"secondWeight"}
                        unit={getUnit("weight", UnitTypes.SMALL)}
                        type={"number"}
                        component={ReduxLabeledInput}
                    />

                </FormForSettings>
                <hr/>
                <Button
                    className={"w-100"}
                    type={"button"}
                    onClick={() => started ? this.stop(device) : this.start(device)}
                >
                    {started && t("disableDispenser")} {!started && t("newSettings.feeding.forages.calibration.startDispenser")}
                </Button>
            </div>
        )
    }
}

DispenserCalibration = reduxForm({
    form: 'settings-feeding-dispenser-calibration',
    onSubmit: submit,
    validate,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
})(DispenserCalibration);

export default withTranslation()(DispenserCalibration);
