import React, {Component} from 'react'
import {DeviceSettingsCard} from './../../../../../components/device-settings-card/DeviceSettingsCard';
import {FieldArray, formValueSelector, isDirty, reduxForm} from 'redux-form';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {ChainFeedingCommandTypes} from './../../../../../constans/mqttMessages';
import _ from "lodash";
import {LoadedDataTypes} from "../../../../../constans/devices";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import {stringBuilder} from "../../../../../utils/TextUtils";
import moment from "moment";
import {setScheduleAnalog} from './../../../../../IOT/device-functions/ChainFeedingFunctions';
import ReduxTableGrid from './../../../../../components/basics/table-grid/redux-table-grid/ReduxTableGrid';
import ReduxInput from './../../../../../components/basics/input/ReduxInput';
import {checkIfTimeRangeOverlaps, getTimeAsTimestamp} from './../../../../../utils/DateTimeUtils';

class ScheduleAnalog extends Component {


    constructor(props) {
        super(props)
        props.initialize(this.getState())
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {devices, shadows} = nextProps;
        const device = devices[0]
        const shadow = device ? shadows.get(device.DevID) : null;
        if (shadow && !nextProps.dirty) {
            nextProps.initialize(this.getState(nextProps))
        }
    }

    getState = (props = this.props) => {
        const result = {schedule: []};
        const {devices, shadows} = props;
        const device = devices[0]
        const shadow = device ? shadows.get(device.DevID) : null;
        for (let i = 0; i < 8; i++) {
            const key = shadow ? "analogSchedule" : `Settings.Configuration[${ChainFeedingCommandTypes.SET_SCHEDULE_ANALOG}]`;
            const obj = shadow ? shadow : device;
            result.schedule[i] = {
                start: _.get(obj, `${key}[${i}].start`) ? moment(_.get(obj, `${key}[${i}].start`)).format("HH:mm") : "",
                stop: _.get(obj, `${key}[${i}].stop`) ? moment(_.get(obj, `${key}[${i}].stop`)).format("HH:mm") : "",
                time: _.get(obj, `${key}[${i}].workTime`) ? _.get(obj, `${key}[${i}].workTime`, 0) / 60000 : "",
            }
        }
        result.SetTime = _.get(shadow, "metaData.analogSchedule", _.get(device, `Settings.Configuration[${ChainFeedingCommandTypes.SET_SCHEDULE_ANALOG}].SetTime`, 0))
        result.loadedDataFrom =
            _.isNil(_.get(shadow, "analogSchedule")) ?
                !!_.get(device, `Settings.Configuration[${ChainFeedingCommandTypes.SET_SCHEDULE_ANALOG}]`) ? LoadedDataTypes.DYNAMO : LoadedDataTypes.NO_DATA : LoadedDataTypes.SHADOW;

        return result;
    }

    render() {
        const {t, submit, loadedDataFrom, SetTime, devices} = this.props;
        const device = devices[0];
        const headers = [
            {
                name: t("newSettings.chainFeeding.scheduleAnalog.start"),
                field: "start",
                type: "time",
                component: ReduxInput
            },
            {
                name: t("newSettings.chainFeeding.scheduleAnalog.stop"),
                field: "stop",
                type: "time",
                component: ReduxInput
            },
            {
                name: t("newSettings.chainFeeding.scheduleAnalog.time"),
                field: "time",
                type: "number",
                unit: "min",
                component: ReduxInput
            },
        ];
        return (
            <DeviceSettingsCard title={t("newSettings.chainFeeding.scheduleAnalog.title")} onButtonClick={submit}>

                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[ChainFeedingCommandTypes.SET_SENSOR_WORK_TYPE].isSet &&
                            <InfoBox
                                boxColor="warning">{stringBuilder(t("newSettings.devices.settingsInfo.dynamo"), moment(SetTime).format("DD.MM.YYYY HH:mm"))}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }

                <FieldArray name={"schedule"} component={ReduxTableGrid} headers={headers}/>
            </DeviceSettingsCard>
        )
    }
}

const submit = (values, dispatch, props) => {
    const {devices} = props;
    const {schedule} = values;
    const data = {schedule: []};
    for (let i = 0; i < 8; i++) {
        const hasData = schedule[i].time && schedule[i].start && schedule[i].stop;
        if (hasData) {
            data.schedule[i] = {};
            data.schedule[i].start = +moment(schedule[i].start, "HH:mm");
            data.schedule[i].stop = +moment(schedule[i].stop, "HH:mm");
            data.schedule[i].workTime = +schedule[i].time * 60000;
        }

    }
    data.schedule = data.schedule.filter(o => !!o);
    data.schedule.sort((o1, o2) => o1.start - o2.start)
    console.log(data, devices)
    devices.forEach(device => {
        setScheduleAnalog(device, data);
    })
};


const validate = (values, props) => {
    const errors = {};
    const {schedule} = values;
    const {t} = props;
    if (schedule) {
        const scheduleErrors = schedule.map((schedRow, index) => {
            const schedError = {};
            if (schedRow.start && schedRow.stop && schedRow.time) {
                if (!schedRow.time || !_.isFinite(+schedRow.time) || +schedRow.time === 0) {
                    schedError.time = t("required");
                }

                if (schedRow.start && schedRow.stop) {
                    let overlaps = false;
                    schedule.filter((d, i) => d.start && d.stop && i !== index).forEach(d => {
                        if (checkIfTimeRangeOverlaps(getTimeAsTimestamp(schedRow.start), getTimeAsTimestamp(schedRow.stop), getTimeAsTimestamp(d.start), getTimeAsTimestamp(d.stop))) {
                            overlaps = true;
                        }
                    });
                    if (overlaps) {
                        schedError.start = t("errors.duplicate");
                        schedError.stop = t("errors.duplicate");
                    }
                    if (getTimeAsTimestamp(schedRow.start) > getTimeAsTimestamp(schedRow.stop)) {
                        schedError.start = t('errors.mustBeBelow') + t("newSettings.chainFeeding.scheduleAnalog.stop");
                        schedError.stop = t("errors.mustBeAbove") + t("newSettings.chainFeeding.scheduleAnalog.start");
                    }
                }
            }
            return schedError
        });
        errors.schedule = scheduleErrors;
    }
    return errors;
}

ScheduleAnalog = reduxForm({
    form: "scheduleAnalog",
    onSubmit: submit,
    validate,
    initialValues: {
        schedule: [{}, {}, {}, {}, {}, {}, {}, {}]
    }
})(ScheduleAnalog);

const selector = formValueSelector("scheduleAnalog");
ScheduleAnalog = connect(state => ({
    climateCurves: state.settings.climateCurves,
    SetTime: selector(state, "SetTime"),
    loadedDataFrom: selector(state, "loadedDataFrom"),
    dirty: isDirty("scheduleAnalog")(state),
    shadows: state.shadows.shadows
}))(ScheduleAnalog);
export default withTranslation()(ScheduleAnalog)