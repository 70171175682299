import React from "react";
import DeviceSettingsCard from "../../../../../components/device-settings-card/DeviceSettingsCard";
import LabeledSelect from "../../../../../components/basics/select/labeled-select/LabeledSelect";
import LabeledSlider from "../../../../../components/basics/slider/labeled-slider/LabeledSlider";
import LabeledInput from "../../../../../components/basics/input/labeled-input/LabeledInput";
import {setNumbersSendToExits} from "../../../../../IOT/device-functions/SeparationCageFunctions";
import PropTypes from "prop-types";
import _ from "lodash";
import {connect} from "react-redux";
import {SeparationCageCommandTypes} from "../../../../../constans/mqttMessages";
import {LoadedDataTypes} from "../../../../../constans/devices";
import InfoBox from "../../../../../components/basics/info-box/InfoBox";
import moment from "moment";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class NumbersToExit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.getNumbersToExit(),
            changed: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.changed) {
            this.setState({
                ...this.getNumbersToExit(nextProps)
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    getNumbersToExit(props = this.props) {
        let device = props.cages[0];
        try {
            let shadow = props.shadows.get(device.DevID);
            let expeditionData = shadow.expeditionData;
            let animalsNumber = expeditionData.exit1 + expeditionData.exit3;
            let bigger = expeditionData.exit3 > expeditionData.exit1 ? 3 : 1;
            let toSide = Math.floor((animalsNumber - bigger === 1 ? expeditionData.exit1 : expeditionData.exit3) / 2 - 1);
            return {
                animalsNumber,
                bigger,
                toSide
            }
        } catch (e) {
            try {
                let expeditionData = props.cages[0].Settings.Configuration[SeparationCageCommandTypes.SET_NUMBERS_SEND_TO_EXITS];
                let animalsNumber = expeditionData.exit1 + expeditionData.exit3;
                let bigger = expeditionData.exit3 > expeditionData.exit1 ? 3 : 1;
                let toSide = Math.floor((animalsNumber - bigger === 1 ? expeditionData.exit1 : expeditionData.exit3) / 2 - 1);
                return {
                    animalsNumber,
                    bigger,
                    toSide,
                    SetTime: _.get(device, `Settings.Configuration.${SeparationCageCommandTypes.SET_NUMBERS_SEND_TO_EXITS}.SetTime`, 0),
                    loadedDataFrom: LoadedDataTypes.DYNAMO,
                    device
                }
            } catch (e) {
                return {
                    bigger: 1,
                    animalsNumber: 10,
                    toSide: 0,
                    SetTime: 0,
                    loadedDataFrom: LoadedDataTypes.NO_DATA,
                    device
                };
            }
        }
    }

    onBiggerChange = value => {
        this.setState({
            bigger: value,
            changed: true
        })
    };

    onAnimalsNumberChange = value => {
        this.setState({
            animalsNumber: value,
            changed: true
        })
    };

    onToSideChange = value => {
        this.setState({
            toSide: value,
            changed: true
        })
    };

    sliderTextFormatter = value => {
        const {bigger, animalsNumber} = this.state;
        let side = bigger === 1 ? this.props.t("left") : this.props.t("right");
        let otherside = bigger === 1 ? this.props.t("right") : this.props.t("left");
        return `${side}: ${Math.ceil(+animalsNumber / 2) + value} ${otherside}: ${Math.floor(+animalsNumber / 2) - value}`;
    };

    onClick = () => {
        const {bigger, animalsNumber, toSide} = this.state;
        let biggerSideValue = Math.ceil(+animalsNumber / 2) + toSide;
        let smallerSideValue = Math.floor(+animalsNumber / 2) - toSide;
        let obj = bigger === 1 ? {
            exit1: biggerSideValue,
            exit3: smallerSideValue
        } : {
            exit1: smallerSideValue,
            exit3: biggerSideValue
        };
        //console.log(obj);
        setNumbersSendToExits(this.props.cages, obj);
    };

    render() {
        let options = [
            {
                name: this.props.t("left"),
                value: 1
            },
            {
                name: this.props.t("right"),
                value: 3
            }
        ];
        const {t} = this.props;
        const {bigger, animalsNumber, toSide, loadedDataFrom, SetTime, device} = this.state;
        return (
            <DeviceSettingsCard title={this.props.t("amountAnimalsToExit")}
                                onButtonClick={this.onClick}>
                {
                    loadedDataFrom !== LoadedDataTypes.SHADOW &&
                    <>
                        {
                            loadedDataFrom === LoadedDataTypes.DYNAMO && !device.Settings.Configuration[SeparationCageCommandTypes.SET_EXIT_TIME].isSet &&
                            <InfoBox
                                boxColor="warning">{i18next.t("newSettings.devices.settingsInfo.dynamo", {date: moment(SetTime).format("DD.MM.YYYY HH:mm")})}</InfoBox>
                        }
                        {
                            loadedDataFrom === LoadedDataTypes.NO_DATA &&
                            <InfoBox boxColor="error">{t("newSettings.devices.settingsInfo.noData")}</InfoBox>
                        }
                    </>
                }
                <LabeledSelect label={this.props.t("newSettings.cage.manage.animalsNumber.bigger")} options={options}
                               value={bigger}
                               onChange={this.onBiggerChange}/>
                <LabeledInput label={this.props.t("animalCount")} type="number"
                              value={animalsNumber}
                              onChange={this.onAnimalsNumberChange}/>
                <LabeledSlider label={this.props.t("newSettings.cage.manage.animalsNumber.proportion")} value={toSide}
                               max={Math.floor(animalsNumber / 2) - 1}
                               onChange={this.onToSideChange} valueFormatter={this.sliderTextFormatter}/>
            </DeviceSettingsCard>
        );
    }

}

NumbersToExit.propTypes = {
    cages: PropTypes.array.isRequired
};

NumbersToExit = connect(state => ({
    shadows: state.shadows.shadows,
}))(NumbersToExit);

export default withTranslation()(NumbersToExit);