import React from "react";
import ListItem from "../../../../../components/basics/list/list-item/ListItem";
import Button from "../../../../../components/basics/button/Button";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {ModalName as ConfirmModalName} from "../../../../../components/modals-new/confirm-modal/ConfirmModal";
import {updateSettingDynamoDB} from "../../../../../actions/settingsAction";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Row, Col} from "react-bootstrap";

export class ClimateCurveItem extends React.Component {

    onDeleteConfirm = props => {
        this.props.curve.deleteSetting();
        this.props.dispatch(updateSettingDynamoDB(this.props.curve.prepareBeanToSave()));
        props.handleHide();
    };

    onDeleteClick = () => {
        this.props.show(ConfirmModalName, {
            text: `${this.props.t("newSettings.climate.curveItem.deleteText")} ${this.props.curve.SetData.Name}?`,
            title: this.props.t("newSettings.climate.curveItem.deleteTitle"),
            confirmText: this.props.t("yes"),
            onConfirmed: this.onDeleteConfirm
        })
    };

    onEditClick = () => {
        this.props.history.push(`/${this.props.farm}/settings/climate-sk3/curves/edit/${this.props.curve.SetID}`);
    };

    render() {
        const {curve: {SetData: {Name}}, t} = this.props;
        return (
            <ListItem>
                <Col xs={12} className={"justify-content-center align-item-center"}>
                    <Row>
                        <Col xs={6}>
                            <h5>{Name}</h5>
                            <small>{t("designation")}</small>
                        </Col>
                        <Col xs={3}>
                            <strong>{this.props.curve.getLength()}</strong>
                            <div className={"hide-on-small-device sm"}>
                                <small>{t("numberOfDays")}</small>
                            </div>
                        </Col>
                        <Col className={"col-auto m-auto"}>
                            <ButtonGroup className={"m-0"}>
                                <Button type={"button"} icon={<i className="fas fa-pen"/>} buttonStyle={"round"}
                                        onClick={this.onEditClick}/>
                                <Button type={"button"} icon={<i className="fas fa-trash"/>} buttonStyle={"round"}
                                        onClick={this.onDeleteClick} buttonColor="error"/>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Col>
            </ListItem>
        );
    }
}

ClimateCurveItem = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(ClimateCurveItem);

ClimateCurveItem = connect(state => ({
    farm: state.location.farm
}))(ClimateCurveItem);

ClimateCurveItem = withTranslation()(ClimateCurveItem);

ClimateCurveItem = withRouter(ClimateCurveItem);

export default ClimateCurveItem;
