import {setClockMenu} from "../../../../../IOT/device-functions/ClimateDriverFunctions";
import moment from "moment";

export function submit(values, dispatch, props) {
    const {curveActive, recorderEraseTime, currentCurveDay = 1, curve} = values;
    let hours = recorderEraseTime.split(":")[0];
    let minutes = recorderEraseTime.split(":")[1];
    let recorderEraseTimeStamp = moment(0).hour(+hours).minute(+minutes).startOf("minute").toDate().getTime();
    const {climates} = props;
    if (curveActive) {
        let curveDay = currentCurveDay + Math.abs(curve.getMinAndMax().min) + 1;
        let stages = curve.getBackendStages();
        setClockMenu(climates, {
            currentCurveDay: curveDay,
            stages,
            recorderEraseTime: recorderEraseTimeStamp
        })
    } else {
        setClockMenu(climates, {
            currentCurveDay: 0,
            recorderEraseTime: recorderEraseTimeStamp
        })
    }
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;
    if (!values.recorderEraseTime) {
        errors.recorderEraseTime = t("required");
    }
    //raczej nie potrzebne
    // if (!values.currentCurveDay) {
    //     errors.currentCurveDay = t("required");
    // }
    if (!values.curve) {
        errors.curve = t("required");
    }
    return errors;
}
