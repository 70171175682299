import React, {Component} from 'react';
import Template from "./Template";
import {voltageFormatter} from "./utils";
import {withTranslation} from "react-i18next";
import {groupDevicesByGatewayID} from "../../../../../utils/DevicesUtils";
import {setClimateFlaps} from "../../../../../IOT/device-functions/ClimateFunctions";
import {DevType} from "../../../../../constans/devices";

class FlapsMenu extends Component {


    getModel = () => {
        const {t} = this.props;
        return [
            {
                name: t("settings.voltageForNumber", {number: `0%`}),
                key: 'flapsVoltage0',
                shadowKey: 'service.flapsVoltage0',
                min: 0,
                max: 10 * 1000,
                step: 100,
                defaultValue: 4 * 1000,
                formatter: voltageFormatter
            },
            {
                name: t("settings.voltageForNumber", {number: `2%`}),
                key: 'flapsVoltage2',
                shadowKey: 'service.flapsVoltage2',
                min: 0,
                max: 10 * 1000,
                step: 100,
                defaultValue: 4 * 1000,
                formatter: voltageFormatter
            },
            {
                name: t("settings.voltageForNumber", {number: `5%`}),
                key: 'flapsVoltage5',
                shadowKey: 'service.flapsVoltage5',
                min: 0,
                max: 10 * 1000,
                step: 100,
                defaultValue: 4 * 1000,
                formatter: voltageFormatter
            },
            {
                name: t("settings.voltageForNumber", {number: `10%`}),
                key: 'flapsVoltage10',
                shadowKey: 'service.flapsVoltage10',
                min: 0,
                max: 10 * 1000,
                step: 100,
                defaultValue: 4 * 1000,
                formatter: voltageFormatter
            },
            {
                name: t("settings.voltageForNumber", {number: `15%`}),
                key: 'flapsVoltage15',
                shadowKey: 'service.flapsVoltage15',
                min: 0,
                max: 10 * 1000,
                step: 100,
                defaultValue: 4 * 1000,
                formatter: voltageFormatter
            },
            {
                name: t("settings.voltageForNumber", {number: `20%`}),
                key: 'flapsVoltage20',
                shadowKey: 'service.flapsVoltage20',
                min: 0,
                max: 10 * 1000,
                step: 100,
                defaultValue: 4 * 1000,
                formatter: voltageFormatter
            },
            {
                name: t("settings.voltageForNumber", {number: `25%`}),
                key: 'flapsVoltage25',
                shadowKey: 'service.flapsVoltage25',
                min: 0,
                max: 10 * 1000,
                step: 100,
                defaultValue: 4 * 1000,
                formatter: voltageFormatter
            },
            {
                name: t("settings.voltageForNumber", {number: `35%`}),
                key: 'flapsVoltage35',
                shadowKey: 'service.flapsVoltage35',
                min: 0,
                max: 10 * 1000,
                step: 100,
                defaultValue: 4 * 1000,
                formatter: voltageFormatter
            },
            {
                name: t("settings.voltageForNumber", {number: `45%`}),
                key: 'flapsVoltage45',
                shadowKey: 'service.flapsVoltage45',
                min: 0,
                max: 10 * 1000,
                step: 100,
                defaultValue: 4 * 1000,
                formatter: voltageFormatter
            },
            {
                name: t("settings.voltageForNumber", {number: `70%`}),
                key: 'flapsVoltage70',
                shadowKey: 'service.flapsVoltage70',
                min: 0,
                max: 10 * 1000,
                step: 100,
                defaultValue: 4 * 1000,
                formatter: voltageFormatter
            },
            {
                name: t("settings.voltageForNumber", {number: `100%`}),
                key: 'flapsVoltage100',
                shadowKey: 'service.flapsVoltage100',
                min: 0,
                max: 10 * 1000,
                step: 100,
                defaultValue: 4 * 1000,
                formatter: voltageFormatter
            },
            {
                name: t("settings.voltageForNumber", {number: `100%+1M`}),
                key: 'flapsVoltage100M1',
                shadowKey: 'service.flapsVoltage100M1',
                min: 0,
                max: 10 * 1000,
                step: 100,
                defaultValue: 4 * 1000,
                formatter: voltageFormatter
            },
            {
                name: t("settings.voltageForNumber", {number: `100%+2M`}),
                key: 'flapsVoltage100M2',
                shadowKey: 'service.flapsVoltage100M2',
                min: 0,
                max: 10 * 1000,
                step: 100,
                defaultValue: 4 * 1000,
                formatter: voltageFormatter
            },
            {
                name: t("settings.voltageForNumber", {number: `100%+3M`}),
                key: 'flapsVoltage100M3',
                shadowKey: 'service.flapsVoltage100M3',
                min: 0,
                max: 10 * 1000,
                step: 100,
                defaultValue: 4 * 1000,
                formatter: voltageFormatter
            }
        ]
    }

    submit = (values) => {
        const {climates} = this.props;
        console.log(values, "FlapsMenu submit");
        const devices = groupDevicesByGatewayID(climates);
        if (devices) {
            for (let deviceList of devices.values()) {
                setClimateFlaps(deviceList[DevType.CLIMATE_SK3], values);
            }
        } else {
            console.error("devices not found")
        }
    }

    render() {
        const model = this.getModel();
        const {climates, t} = this.props;
        return (
            <>
                <Template
                    climates={climates}
                    name={t("settings.flapsSettings")}
                    model={model}
                    onSubmit={this.submit}
                    form={"climate-sk3-flaps-menu"}
                />
            </>
        );
    }
}

export default withTranslation()(FlapsMenu);
