import React from "react";
import PropTypes from "prop-types"
import Card from "../../../../../components/basics/card/Card";
import {Col, Row} from "react-bootstrap";
import LabeledSlider from "../../../../../components/basics/slider/labeled-slider/LabeledSlider";
import {calculateVentilation} from "../../../../../utils/DevicesUtils";
import {isEqual} from "lodash"
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

export class CurveStageItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            Days: props.value ? props.value.Days : 0,
            ExpectedTemperature: props.value ? props.value.ExpectedTemperature : 10,
            Ventilation: props.value ? props.value.Ventilation : [0, 250]
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !isEqual(this.state, nextState) || !isEqual(this.props.lang, nextProps.lang);
    }

    onDaysChange = value => {
        this.setState({
            Days: value
        }, () => {
            this.onChange();
        })
    };

    onExpectedTemperatureChange = value => {
        this.setState({
            ExpectedTemperature: value
        }, () => {
            this.onChange();
        })
    };

    onVentilationChange = value => {
        if (value[0] > 180) value[0] = 180;
        this.setState({
            Ventilation: value
        }, () => {
            this.onChange();
        })
    };

    daysValueFormatter = value => {
        return value + " " + this.props.t("days");
    };

    temperatureValueFormatter = value => {
        return value + "°C"
    };

    /**
     * formatter wyświetlający poprawnie wartość na sliderze wentylacji z racji iż wysyłane w rzeczywistości są inne wartości
     * @param value
     * @returns przeliczona wartość przez funkcje calculateVentilation
     */
    rangeVentilationValueFormatter = value => {
        return `${calculateVentilation(value).toFixed(1)}%`;
    };

    ventilationValueFormatter = value => {
        // TODO dostosowac do wartosci wyswietlanych na dozowniku
        if (value[1] > 190 && value[1] <= 205) return `${calculateVentilation(value[0]).toFixed(1)}% - ${calculateVentilation(value[1]).toFixed(1)}%, 1 master`;
        if (value[1] > 205 && value[1] <= 220) return `${calculateVentilation(value[0]).toFixed(1)}% - ${calculateVentilation(value[1]).toFixed(1)}%, 2 mastery`;
        if (value[1] > 220 && value[1] <= 249) return `${calculateVentilation(value[0]).toFixed(1)}% - ${calculateVentilation(value[1]).toFixed(1)}%, 3 mastery`;
        if (value[1] > 249) return `${calculateVentilation(value[0]).toFixed(1)}% - ${calculateVentilation(value[1]).toFixed(1)}%, 4 mastery`;
        return `${calculateVentilation(value[0]).toFixed(1)}% - ${calculateVentilation(value[1]).toFixed(1)}%`;
    };

    onChange() {
        this.props.onChange(this.state, this.props.index);
    }

    render() {
        const {title, disableDaysInput, t} = this.props;
        const {Days, ExpectedTemperature, Ventilation} = this.state;
        return (
            <Card>
                <h5>{title}</h5>
                <Row>
                    <Col md={4}>
                        <LabeledSlider label={t("numberOfDays")} value={Days}
                                       onChange={this.onDaysChange}
                                       valueFormatter={this.daysValueFormatter} disabled={disableDaysInput}/>
                    </Col>
                    <Col md={4}>
                        <LabeledSlider label={t("newSettings.climate.curve.expectedTemperature")}
                                       value={ExpectedTemperature}
                                       onChange={this.onExpectedTemperatureChange}
                                       valueFormatter={this.temperatureValueFormatter} min={10} max={50} step={0.2}/>
                    </Col>
                    <Col md={4}>
                        <LabeledSlider label={t("newSettings.climate.curve.ventilation")} value={Ventilation}
                                       onChange={this.onVentilationChange} tipFormatter={this.rangeVentilationValueFormatter}
                                       valueFormatter={this.ventilationValueFormatter} isRange min={0} max={250}/>
                    </Col>
                </Row>
            </Card>
        );
    }

}

CurveStageItem.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.shape({
        days: PropTypes.number.isRequired,
        expectedTemperature: PropTypes.number.isRequired,
        ventilation: PropTypes.arrayOf(PropTypes.number).isRequired
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    disableDaysInput: PropTypes.bool
};

CurveStageItem = connect(state => ({
    lang: state.language.lang.lang,
}))(CurveStageItem);

export default withTranslation()(CurveStageItem);
