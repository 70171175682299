import React from "react";
import CurveStageItem from "./CurveStageItem";
import Card from "../../../../../components/basics/card/Card";
import ButtonGroup from "../../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../../components/basics/button/Button";
import Setting from "../../../../../beans/Setting"
import {SettingTypes} from "../../../../../constans/settingTypes";
import {createSettingDynamoDB, updateSettingDynamoDB} from "../../../../../actions/settingsAction";
import {connect} from "react-redux";
import LabeledInput from "../../../../../components/basics/input/labeled-input/LabeledInput";
import ClimateCurveChart from "../../../../../components/charts/ClimateCurveChart";
import "./_add-climate-curve.scss"
import withRoles from "../../../../../components/withRoles";
import {Roles} from "../../../../../constans/roles";
import {withTranslation} from "react-i18next";
import {isEqual} from "lodash";

export class AddClimateCurveView extends React.Component {

    constructor(props) {
        super(props);
        let stages;
        let name = "";
        let id = "";
        try {
            id = props.match.params.id;
            let curve = props.climateCurves.find(item => item.SetID === id);
            stages = curve.SetData.Stages;
            name = curve.SetData.Name;
        } catch (e) {
            console.error(e);

            stages = [
                {
                    Days: 1,
                    ExpectedTemperature: 10,
                    Ventilation: [0, 190]
                },
                {
                    Days: 2,
                    ExpectedTemperature: 10,
                    Ventilation: [0, 190]
                },
                {
                    Days: 2,
                    ExpectedTemperature: 10,
                    Ventilation: [0, 190]
                },
                {
                    Days: 2,
                    ExpectedTemperature: 10,
                    Ventilation: [0, 190]
                },
                {
                    Days: 2,
                    ExpectedTemperature: 10,
                    Ventilation: [0, 190]
                },
                {
                    Days: 2,
                    ExpectedTemperature: 10,
                    Ventilation: [0, 190]
                },
                {
                    Days: 2,
                    ExpectedTemperature: 10,
                    Ventilation: [0, 190]
                },
                {
                    Days: 2,
                    ExpectedTemperature: 10,
                    Ventilation: [0, 190]
                }
            ];
        }
        this.state = {
            stages,
            name,
            nameError: "",
            id
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {lang} = this.props;
        const {name}=this.state;
        return !isEqual(lang, nextProps.lang) || !isEqual(name, nextState.name);
    }

    onChange = (value, index) => {
        let stages = this.state.stages.slice(0);
        stages[index] = value;
        this.setState({
            stages
        })
    };

    saveClimateCurve = () => {
        if (!this.state.nameError && this.state.name) {
            let stages = this.state.stages;
            if (this.state.id) {
                try {
                    let curve = this.props.climateCurves.find(item => item.SetID === this.state.id);
                    curve.SetData.Stages = stages;
                    curve.SetData.Name = this.state.name;
                    curve.setDtaModTime();
                    this.props.dispatch(updateSettingDynamoDB(curve.prepareBeanToSave()));
                } catch (error) {
                    console.error(error);
                }
            } else {
                try {
                    let curve = Setting.createSetting(SettingTypes.CLIMATE_CURVE, {
                        Stages: stages,
                        Name: this.state.name
                    });
                    this.props.dispatch(createSettingDynamoDB(curve, this.props.user.ClientID));
                } catch (error) {
                    console.error(error);
                }
            }
            this.props.history.push(`/${this.props.farm}/settings/climate/curves`)
        } else {
            this.setState({
                nameError: this.props.t("required")
            })
        }
    };

    onNameChange = (value) => {
        if (!value) {
            this.setState({
                nameError: this.props.t("required"),
                name: value
            });
        } else {
            this.setState({
                nameError: "",
                name: value
            })
        }

    };

    render() {
        const {stages, name, nameError} = this.state;
        const {t} = this.props;
        console.log("Nazwa:", name);
        return (
            <div>
                <Card>
                    <LabeledInput label={t("designation")} type="text" value={name} error={nameError}
                                  onChange={this.onNameChange}/>
                </Card>
                <Card>
                    <ClimateCurveChart stages={stages}/>
                </Card>
                <CurveStageItem title={t("newSettings.climate.curve.start")} value={stages[0]} onChange={this.onChange}
                                index={0}
                                disableDaysInput/>
                <CurveStageItem title={t("newSettings.climate.curve.before")} value={stages[1]} onChange={this.onChange}
                                index={1}/>
                <CurveStageItem title={t("settlement")} value={stages[2]} onChange={this.onChange}
                                index={2}/>
                <CurveStageItem title={`${t("newSettings.climate.curve.stage")} 1`} value={stages[3]}
                                onChange={this.onChange} index={3}/>
                <CurveStageItem title={`${t("newSettings.climate.curve.stage")} 2`} value={stages[4]}
                                onChange={this.onChange} index={4}/>
                <CurveStageItem title={`${t("newSettings.climate.curve.stage")} 3`} value={stages[5]}
                                onChange={this.onChange} index={5}/>
                <CurveStageItem title={`${t("newSettings.climate.curve.stage")} 4`} value={stages[6]}
                                onChange={this.onChange} index={6}/>
                <CurveStageItem title={`${t("newSettings.climate.curve.stage")} 5`} value={stages[7]}
                                onChange={this.onChange} index={7}/>
                <ButtonGroup fixed renderInPortal={false}>
                    <Button buttonStyle="round" buttonColor={"success"} icon={<i className="fas fa-save"/>}
                            onClick={this.saveClimateCurve}/>
                </ButtonGroup>
            </div>
        );
    }

}

export let _AddClimateCurveView = connect(state => ({
    user: state.user.user,
    climateCurves: state.settings.climateCurves,
    farm: state.location.farm,
    lang: state.language.lang.lang,
}))(AddClimateCurveView);

_AddClimateCurveView = withRoles({
    roles: [Roles._DEVICE_CLIMATE, Roles._DEVICE_CONFIG],
    showComponent: true
})(_AddClimateCurveView);

export default withTranslation()(_AddClimateCurveView);
