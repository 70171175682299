import React, {Component} from 'react';
import Template from "./Template";
import {
    setClimateThrottle,
    setThrottleVoltageMax,
    setThrottleVoltageMin
} from "../../../../../IOT/device-functions/ClimateFunctions";
import {voltageFormatter} from "./utils";
import {withTranslation} from "react-i18next";
import {groupDevicesByGatewayID} from "../../../../../utils/DevicesUtils";
import {DevType} from "../../../../../constans/devices";

class ThrottleMenu extends Component {


    getModel = () => {
        const {t} = this.props;
        return [
            {
                name: t("settings.throttleVoltageMin"),
                key: 'throttleVoltageMin',
                shadowKey: 'service.throttleVoltageMin',
                min: 0,
                max: 5 * 1000,
                step: 100,
                defaultValue: 1000,
                formatter: voltageFormatter,
                submit: setThrottleVoltageMin
            },
            {
                name: t("settings.throttleVoltageMax"),
                key: 'throttleVoltageMax',
                shadowKey: 'service.throttleVoltageMax',
                min: 5100,
                max: 10 * 1000,
                step: 100,
                defaultValue: 8 * 1000,
                formatter: voltageFormatter,
                submit: setThrottleVoltageMax
            }
        ]
    }

    submit = (values) => {
        const {climates} = this.props;
        console.log(values, "ThrottleMenu submit");
        const devices = groupDevicesByGatewayID(climates);
        if (devices) {
            for (let deviceList of devices.values()) {
                setClimateThrottle(deviceList[DevType.CLIMATE_SK3], values);
            }
        } else {
            console.error("devices not found")
        }
    }

    render() {
        const model = this.getModel();
        const {climates, t} = this.props;
        return (
            <>
                <Template
                    climates={climates}
                    name={t("settings.thorttleSettings")}
                    model={model}
                    onSubmit={this.submit}
                    form={"climate-sk3-throttle-menu"}
                />
            </>
        );
    }
}

export default withTranslation()(ThrottleMenu);
